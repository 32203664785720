import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/css/App.css";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import * as reactRouterDom from "react-router-dom";
import { AdminLayout } from "./layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";

import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import ThirdPartyPasswordless from "supertokens-auth-react/recipe/thirdpartypasswordless";
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";
import { ThirdPartyPasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartypasswordless/prebuiltui";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Invitation } from "layouts/waiter/Invitation";
import { InvitationModal } from "views/common/InvitationModal";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { WaiterLayout } from "layouts/waiter";
import { SessionClaimValidator } from "supertokens-website";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {},
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const styles = `
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

  [data-supertokens~="container"] {
    border-radius: 20px;
    box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
    font-family: "Noto Sans", sans-serif;
  }

  [data-supertokens~="inputWrapper"] {
    height: 48px;
    border-radius: 16px;
  }

  [data-supertokens~="inputWrapper"]:focus-within {
    border: 1px solid rgb(var(--palette-inputBorder));
    box-shadow: none;
  }

  [data-supertokens~="button"] {
    height: 50px;
    border-radius: 16px;
  }

  [data-supertokens~="button"]:hover {
    background-color: rgb(51, 17, 219);
  }

  [data-supertokens~="providerButton"] {
    height: 40px;
    background-color: rgb(244, 247, 254);
    border: none;
  }

  [data-supertokens~="providerButton"]:hover {
    background-color: rgb(226, 232, 240);
  }

  [data-supertokens~="divider"] {
    border-bottom-color: rgb(135, 140, 189, 0.3);
  }

  [data-supertokens~="thirdPartyPasswordlessDividerText"] {
    color: rgb(160, 174, 192);
  }
`;

SuperTokens.init({
  appInfo: {
    appName: "GoTips",
    apiDomain: process.env.REACT_APP_API_BASE_URL!,
    websiteDomain: process.env.REACT_APP_BASE_URL!,
    apiBasePath: "/api/auth",
    websiteBasePath: "/auth",
  },
  recipeList: [
    EmailVerification.init({
      mode: "REQUIRED", // or "OPTIONAL"
    }),
    ThirdPartyPasswordless.init({
      contactMethod: "EMAIL",
      signInUpFeature: {
        providers: [
          ThirdPartyPasswordless.Google.init(),
          // ThirdPartyPasswordless.Facebook.init(),
        ],
        termsOfServiceLink: "https://gotips.co/client-terms",
        privacyPolicyLink: "https://gotips.co/privacy-policy",
        thirdPartyProviderAndEmailOrPhoneFormStyle: styles,
        linkSentScreenStyle: styles,
        userInputCodeFormStyle: styles,
      },
      style: `
        [data-supertokens~=container] {
            --palette-background: 255, 255, 255;
            --palette-textInput: 27, 37, 75;
            --palette-textTitle: 27, 37, 75;
            --palette-inputBorder: 224, 229, 242;
            --palette-inputBackground: 255, 255, 255;
            --palette-textPrimary: 27, 37, 75;
            --palette-textLabel: 27, 37, 75;
            --palette-primary: 66, 42, 251;
            --palette-primaryBorder: 255, 255, 255, 0;
            --palette-buttonText: 255, 255, 255;
            --palette-errorBackground: 254, 239, 238;
            --palette-error: 26, 32, 44;
            --palette-textLink: 66, 42, 251;
        } 
      `,
    }),
    Session.init(),
  ],
});

const whitelistedClaimValidator: SessionClaimValidator & {
  onFailureRedirection?: () => string;
} = {
  id: "whitelisted",
  validate: function (accessTokenPayload, userContext) {
    return {
      isValid: accessTokenPayload.whitelisted === true,
    };
  },
  refresh: async () => {},
  shouldRefresh: () => true,
  onFailureRedirection: () => "/waiter",
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <QueryClientProvider client={new QueryClient()}>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <SuperTokensWrapper>
          <BrowserRouter>
            <Switch>
              {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
                ThirdPartyPasswordlessPreBuiltUI,
                EmailVerificationPreBuiltUI,
              ])}
              <Route path="/admin">
                <SessionAuth
                  overrideGlobalClaimValidators={(validators, ctx) => [
                    ...validators,
                    whitelistedClaimValidator,
                  ]}
                >
                  <AdminLayout />
                </SessionAuth>
              </Route>

              <Route path="/waiter">
                <Switch>
                  <Route
                    path="/waiter/invitation/:token"
                    component={Invitation}
                  />
                  <SessionAuth>
                    <InvitationModal />
                    <WaiterLayout />
                  </SessionAuth>
                </Switch>
              </Route>
              <Redirect from="/" to="/waiter" />
            </Switch>
          </BrowserRouter>
        </SuperTokensWrapper>
      </React.StrictMode>
    </ChakraProvider>
  </QueryClientProvider>
);
