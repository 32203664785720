// Chakra imports
import {
  Avatar,
  Box,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  OrgTransactionResponseDTODataItem,
  TransactionResponseDTODataItem,
  TransactionResponseDTODataItemMetadataFeedback,
} from "api";

// Custom components
import IconBox from "components/icons/IconBox";
import { useMemo } from "react";
import { IconType } from "react-icons";
import {
  MdAccountBalanceWallet,
  MdEuro,
  MdChevronRight,
  MdFeedback,
} from "react-icons/md";
import { getName } from "utils/name";
import {
  useTransactionInfo,
  UseTransactionInfoResult,
} from "./useTransactionInfo";
import { Feedback, PooledAmount } from "./WalletTransaction";

type TransactionRowProps = {
  id?: any;
  name: string;
  icon: IconType | { name: string; src?: string };
  subtitle: Date | string;
  amount: number;
  infoResult: UseTransactionInfoResult;
  feedback?: TransactionResponseDTODataItemMetadataFeedback;
  pooled: number | undefined;
  onPoolClick: () => void;
  subrows: TransactionRowProps[];
};

function TransactionRow({
  id,
  name,
  icon,
  subtitle,
  amount,
  infoResult,
  feedback,
  subrows,
  pooled,
  onPoolClick,
}: TransactionRowProps) {
  const { isOpen, onToggle } = useDisclosure();
  const { withPrecision, dateFormatter } = infoResult;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconBoxBg = useColorModeValue("secondaryGray.300", "navy.700");

  const row = (
    <Flex direction="column" gap={2} w="100%">
      <Flex justifyContent="start" alignItems="center" direction="row" w="100%">
        {typeof icon === "object" ? (
          <Avatar
            h="42px"
            w="42px"
            minW="42px"
            minH="42px"
            me="20px"
            src={icon.src}
            name={icon.name}
          />
        ) : (
          <IconBox
            h="42px"
            w="42px"
            minW="42px"
            minH="42px"
            bg={iconBoxBg}
            me="20px"
            icon={<Icon as={icon} />}
          />
        )}

        <Flex direction="column" align="start" me="6px">
          <Text color={textColor} fontSize="md" me="6px" fontWeight="700">
            {name}
          </Text>
          <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
            {typeof subtitle === "string"
              ? subtitle
              : dateFormatter.format(subtitle)}
          </Text>
        </Flex>
        <PooledAmount
          amount={withPrecision(amount)}
          pooled={Boolean(pooled) && pooled !== amount}
          onClick={onPoolClick}
          me={subrows.length > 0 ? "20px" : "60px"}
        />
        {subrows.length > 0 && (
          <IconButton
            variant="action"
            aria-label="Details"
            icon={
              <Icon
                as={MdChevronRight}
                transform={isOpen ? "rotate(90deg)" : "0"}
              />
            }
            onClick={onToggle}
          />
        )}
      </Flex>
      {feedback && (
        <Feedback
          rate={feedback.rate}
          content={feedback.comment || undefined}
        />
      )}
    </Flex>
  );

  if (subrows.length > 0) {
    return (
      <Flex direction="column">
        <Box cursor="pointer" onClick={onToggle}>
          {row}
        </Box>
        <Collapse in={isOpen} animateOpacity unmountOnExit>
          {subrows.map((sr) => (
            <Box pl="20px" pt="10px" key={sr.id}>
              <TransactionRow {...sr} />
            </Box>
          ))}
        </Collapse>
      </Flex>
    );
  } else {
    return row;
  }
}

export function OrgWalletTransaction(props: {
  transaction: OrgTransactionResponseDTODataItem;
  onPoolClick: (poolTransaction: TransactionResponseDTODataItem) => void;
}) {
  const { transaction, onPoolClick } = props;
  const infoResult = useTransactionInfo(transaction);

  const subrows = useMemo(() => {
    return transaction.items.flatMap((i) => {
      return i.transactions.map<TransactionRowProps>((t) => {
        const details = infoResult.getDetails(t);
        let name = details.name;
        let icon: TransactionRowProps["icon"] = details.icon;
        let subtitle = "";
        let feedback:
          | TransactionResponseDTODataItemMetadataFeedback
          | undefined;

        if (i.participant) {
          name = getName(i.participant.user);
          icon = { name, src: i.participant.user.avatarImage ?? undefined };
          if (i.qr) {
            subtitle = `QR-code: ${i.qr.internalCode}`;
          }
          feedback =
            t.metadata?.feedback &&
            t.metadata.feedback.participantId === i.participant.id
              ? t.metadata.feedback
              : undefined;
        } else if (i.wallet) {
          name = i.wallet.name;
          icon = MdAccountBalanceWallet;
          if (i.qr) {
            subtitle = `QR-code: ${i.qr.internalCode}`;
          }
          feedback =
            t.metadata?.feedback && !t.metadata.feedback.participantId
              ? t.metadata.feedback
              : undefined;
        } else {
          if (i.qr) {
            subtitle = `QR-code: ${i.qr.internalCode}`;
          }
          feedback = t.metadata?.feedback;
        }

        return {
          id: t.id,
          name,
          amount: details.amount,
          subtitle,
          icon,
          infoResult,
          subrows: [],
          pooled: t.metadata?.pooled,
          onPoolClick: () => onPoolClick(t),
          feedback,
        };
      });
    });
  }, [infoResult, transaction.items]);

  let icon: IconType = MdEuro;
  let name = "Transaction";

  if (transaction.amount > 0) {
    name = transaction.metadata?.isTip ? "Incoming tips" : "Top-up";
  } else if (transaction.amount < 0) {
    name = "Withdrawal";
  } else if (transaction.metadata?.isFeedback) {
    name = "Feedback";
    icon = MdFeedback;
  } else if (
    transaction.items?.[0]?.transactions?.[0]?.category === "holdtomain"
  ) {
    name = "Processing complete";
  } else {
    name = "Transfer";
  }

  return (
    <TransactionRow
      name={name}
      icon={icon}
      amount={transaction.amount}
      infoResult={infoResult}
      subtitle={new Date(transaction.date as string)}
      subrows={subrows}
      pooled={undefined}
      onPoolClick={() => {}}
    />
  );
}
