import { useSessionContext } from "supertokens-auth-react/recipe/session";

export function useSession() {
  const session = useSessionContext();
  if (session.loading) {
    return;
  }

  const accessToken = session.accessTokenPayload;

  return {
    mainUserId: accessToken.mainUserId as string,
    whitelisted: accessToken.whitelisted as boolean | undefined,
  };
}
