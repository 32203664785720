import {
  Avatar,
  Flex,
  FlexProps,
  Icon,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import {
  OrganizationDTO,
  OrganizationQRDTO,
  ParticipantDTO,
  PaymentRuletDTODataDistribution,
  TagDTO,
  WalletDTO,
} from "api";
import IconBox from "components/icons/IconBox";
import { useState } from "react";
import { MdAccountBalanceWallet, MdTag, MdWarningAmber } from "react-icons/md";
import { getName } from "utils/name";

type Rule =
  | PaymentRuletDTODataDistribution["byOrganization"][number]
  | PaymentRuletDTODataDistribution["byParticipant"][number]
  | PaymentRuletDTODataDistribution["byTag"][number];

function DeletedTooltipHandle({
  type,
}: {
  type: (Rule["from"] | Rule["to"])["type"] | "qr";
}) {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const entity = (() => {
    switch (type) {
      case "qr":
        return "QR-code";
      case "organization":
        return "restaurant";
      case "participant":
        return "waiter";
      case "tag":
        return "tag";
      case "wallet":
        return "wallet";
    }
  })();

  return (
    <Tooltip
      hasArrow
      label={`This ${entity} was deleted or unavailable currently. Please make a correction.`}
      fontSize="sm"
      borderRadius="20px"
      px="15px"
      py="10px"
      isOpen={isTooltipOpen}
    >
      <span style={{ verticalAlign: "middle" }}>
        <Icon
          as={MdWarningAmber}
          color="red"
          mx="5px"
          w="18px"
          h="18px"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          onClick={() => setTooltipOpen(true)}
        />
      </span>
    </Tooltip>
  );
}

export function RuleFromTo({
  organization,
  wallets,
  participants,
  qrs,
  tags,
  fromTo,
  ...rest
}: {
  organization: Pick<OrganizationDTO, "id" | "name" | "avatarImage">;
  wallets: WalletDTO[];
  tags: TagDTO;
  participants: ParticipantDTO[];
  qrs: OrganizationQRDTO[];
  fromTo: Rule["from"] | Rule["to"];
} & FlexProps) {
  const tagBg = useColorModeValue("brand.500", "brand.400");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  switch (fromTo.type) {
    case "organization": {
      const qr = fromTo.qrId
        ? qrs.find((w) => w.id === fromTo.qrId)
        : undefined;

      return (
        <Flex w="auto" align="center" justify="flex-start" gap={2} {...rest}>
          <Avatar
            mx="auto"
            h="40px"
            w="40px"
            m="0px"
            name={organization.name}
            src={organization.avatarImage ?? undefined}
          />
          <Flex direction="column" align="start">
            <Text
              color={textColorPrimary}
              fontSize="md"
              me="6px"
              fontWeight="700"
            >
              {organization.name}
            </Text>
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
              {fromTo.qrId
                ? `QR-Code: ${qr?.internalCode ?? "Deleted"}`
                : "Restaurant"}
            </Text>
          </Flex>
          {!!fromTo.qrId && !qr && <DeletedTooltipHandle type="qr" />}
        </Flex>
      );
    }
    case "participant": {
      const isAny = fromTo.id === "__any__";
      const { name, avatar, isDeleted } = (() => {
        if (isAny) {
          return {
            name: "Any waiter",
            avatar: undefined,
            isDeleted: false,
          };
        }

        const participant = participants.find((p) => p.id === fromTo.id);

        if (!participant) {
          return {
            name: "Deleted waiter",
            avatar: undefined,
            isDeleted: true,
          };
        }

        return {
          name: getName(participant.user),
          avatar: participant.user.avatarImage ?? undefined,
          isDeleted: false,
        };
      })();

      return (
        <Flex w="auto" align="center" justify="flex-start" gap={2} {...rest}>
          <Avatar
            mx="auto"
            h="40px"
            w="40px"
            m="0px"
            name={name}
            src={avatar}
          />
          <Flex direction="column" align="start">
            <Text
              color={textColorPrimary}
              fontSize="md"
              me="6px"
              fontWeight="700"
            >
              {name}
            </Text>
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
              {isAny ? "Any of the restaurant" : "Waiter"}
            </Text>
          </Flex>
          {isDeleted && <DeletedTooltipHandle type="participant" />}
        </Flex>
      );
    }
    case "tag": {
      const unique = new Set([...tags.private, ...tags.public]);
      const isDeleted = !unique.has(fromTo.tag);

      return (
        <Flex w="auto" align="center" justify="flex-start" gap={2} {...rest}>
          <IconBox
            h="40px"
            w="40px"
            icon={<Icon as={MdTag} color={brandColor} h="18px" w="18px" />}
            bg={box}
          />
          <Tag
            h="25px"
            borderRadius="12px"
            variant="solid"
            bgColor={tagBg}
            marginInlineStart={0}
          >
            <TagLabel w="100%">
              {isDeleted ? "Deleted tag" : fromTo.tag}
            </TagLabel>
          </Tag>
          {isDeleted && <DeletedTooltipHandle type="tag" />}
        </Flex>
      );
    }
    case "wallet":
      const wallet = wallets.find((w) => w.id === fromTo.id);

      return (
        <Flex w="auto" align="center" justify="flex-start" gap={2} {...rest}>
          <IconBox
            h="40px"
            w="40px"
            icon={
              <Icon
                as={MdAccountBalanceWallet}
                color={brandColor}
                h="18px"
                w="18px"
              />
            }
            bg={box}
          />
          <Flex direction="column" align="start">
            <Text
              color={textColorPrimary}
              fontSize="md"
              me="6px"
              fontWeight="700"
            >
              {wallet ? wallet.name ?? wallet.currency : "Deleted wallet"}
            </Text>
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
              Wallet
            </Text>
          </Flex>
          {!wallet && <DeletedTooltipHandle type="wallet" />}
        </Flex>
      );
  }
}

export function getFromToRuleText({
  organization,
  wallets,
  tags,
  qrs,
  participants,
  fromTo,
}: {
  organization: Pick<OrganizationDTO, "id" | "name" | "avatarImage">;
  wallets: WalletDTO[];
  qrs: OrganizationQRDTO[];
  tags: TagDTO;
  participants: ParticipantDTO[];
  fromTo: Rule["from"] | Rule["to"];
}) {
  switch (fromTo.type) {
    case "organization":
      if (fromTo.qrId) {
        const qr = qrs.find((qr) => qr.id === fromTo.qrId);
        return qr
          ? `${organization.name} - ${qr.internalCode}`
          : `${organization.name} - Deleted QR-code`;
      }
      return organization.name;
    case "participant":
      if (!fromTo.id || fromTo.id === "__any__") {
        return "Any waiter";
      }

      const participant = participants.find((p) => p.id === fromTo.id);
      return participant ? getName(participant.user) : "Deleted waiter";
    case "tag":
      const unique = new Set([...tags.private, ...tags.public]);
      return unique.has(fromTo.tag) ? fromTo.tag : "Deleted tag";
    case "wallet":
      const wallet = wallets.find((w) => w.id === fromTo.id);
      if (!wallet) {
        return "Deleted wallet";
      }

      return wallet.name ?? wallet.currency;
  }
}
