// Chakra imports
import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card";
// Assets
import {
  OrgTransactionResponseDTODataItem,
  TransactionResponseDTODataItem,
} from "api";
import {
  MdArrowDownward,
  MdDownload,
  MdOutlineCalendarToday,
  MdPeopleAlt,
} from "react-icons/md";
import { WalletTransaction } from "./WalletTransaction";
import { OrgWalletTransaction } from "./OrgWalletTransaction";
import { RangeCalendar } from "components/calendar/MiniCalendar";
import { useState } from "react";
import { useTransactionInfo } from "./useTransactionInfo";

function PoolModal({
  transaction,
  onClose,
  mode,
}: {
  transaction: TransactionResponseDTODataItem;
  onClose: () => void;
  mode: "organization" | "participant";
}) {
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const { withPrecision } = useTransactionInfo(transaction);
  const pool = transaction.metadata?.pooled ?? 0;

  return (
    <Modal
      isOpen={Boolean(transaction)}
      onClose={onClose}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex direction="row" align="center" gap={1}>
            <MdPeopleAlt style={{ marginTop: "2px" }} />
            Tips sharing
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={0} mb="14px">
            <Text>
              {mode === "organization"
                ? "Teammate have got from customer: "
                : "Tips you've got from customer: "}
              <b>{withPrecision(pool)}</b>
            </Text>
            <Text>
              {mode === "organization"
                ? "Teammate shared with team: "
                : "You shared with team: "}
              <b>
                {withPrecision(
                  pool - (transaction.holdAmount || transaction.amount)
                )}
              </b>
            </Text>
            <Text as="span">
              {mode === "organization" ? "Teammate cut: " : "Your cut: "}
              <Text as="span" color={brandColor}>
                <b>
                  {withPrecision(transaction.holdAmount || transaction.amount)}
                </b>
              </Text>
            </Text>
          </Flex>
          {mode === "organization" && (
            <>
              Tips sharing scheme is active for this resturant, and your
              teammate have received tips according to tips sharing settings.{" "}
              <br />
              <br />
              If you feel this is wrong, please revise rules{" "}
              <Link
                href={`/admin/organizations/${transaction.metadata?.organization?.id}?tab=2`}
              >
                <b>here</b>
              </Link>{" "}
              following in the section "Tips sharing".
              <br />
              <br />
              Should you have any questions, please feel free to contact us by
              chat or by email:{" "}
              <b>
                <Link href="mailto:contact@gotips.co">contact@gotips.co</Link>
              </b>
            </>
          )}
          {mode === "participant" && (
            <>
              You're member of{" "}
              {<b>{transaction?.metadata?.organization?.name}</b> ??
                "restaurant"}{" "}
              team and participate in tips sharing scheme. According to the
              settings of your team, you've shared incoming tips. <br />
              <br />
              If you feel this is unfair, please contact your team manager to
              revise tips sharing rules. Alternatively, feel free to contact
              GoTips by chat or by email:{" "}
              <b>
                <Link href="mailto:contact@gotips.co">contact@gotips.co</Link>
              </b>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="no-hover" bg={bgButton} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function PayoutHelpModal({
  transaction,
  onClose,
}: {
  transaction: TransactionResponseDTODataItem;
  onClose: () => void;
}) {
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const { dateFormatter } = useTransactionInfo(transaction);

  return (
    <Modal
      isOpen={Boolean(transaction)}
      onClose={onClose}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Payout duration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={4}>
            <Text>
              Payout has been made on{" "}
              {dateFormatter.format(new Date(transaction.date as string))}. You
              may expect funds to hit your bank account within 2-3 hours from{" "}
              <b>"MAYSKAYA LAB LTD"</b>. However keep in mind, that if your bank
              does not support SEPA Instant scheme, you may receive funds within
              3 business days.
            </Text>

            <Text>
              If you need help, please contact us by chat or by email{" "}
              <b>
                <Link href="mailto:contact@gotips.co">contact@gotips.co</Link>
              </b>
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="no-hover" bg={bgButton} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function TrasactionList(props: {
  data: OrgTransactionResponseDTODataItem[] | TransactionResponseDTODataItem[];
  hasMore: boolean;
  loadMore: () => void;
  isLoadingMore: boolean;
  selectedRange: [Date, Date] | null | undefined;
  onSelectedRangeChange: (dates: [Date, Date] | null | undefined) => void;
  onCsvDownload?: () => void;
  mode: "organization" | "participant";
  [x: string]: any;
}) {
  const {
    data,
    hasMore,
    loadMore,
    isLoadingMore,
    selectedRange,
    onSelectedRangeChange,
    onCsvDownload,
    mode,
    ...rest
  } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");

  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [poolModalTransaction, setOpenedPoolModal] =
    useState<TransactionResponseDTODataItem>();

  const [payoutHelpModal, setPayoutHelpModal] =
    useState<TransactionResponseDTODataItem>();

  return (
    <>
      {poolModalTransaction && (
        <PoolModal
          transaction={poolModalTransaction}
          onClose={() => setOpenedPoolModal(undefined)}
          mode={mode}
        />
      )}

      {payoutHelpModal && (
        <PayoutHelpModal
          transaction={payoutHelpModal}
          onClose={() => setPayoutHelpModal(undefined)}
        />
      )}

      <Card
        justifyContent="center"
        flexDirection="column"
        w="100%"
        mb={{ base: "20px", lg: "0px" }}
        pb="20px"
        pt="10px"
        {...rest}
      >
        <Flex direction="row" align="center" mb="16px">
          <Text
            color={textColor}
            fontSize="lg"
            fontWeight="700"
            lineHeight="100%"
          >
            Transactions
          </Text>
          <Spacer />
          <Flex direction="row" gap={2}>
            <RangePickerButton
              value={selectedRange}
              onChange={onSelectedRangeChange}
            />

            {onCsvDownload && (
              <IconButton
                icon={<Icon as={MdDownload} />}
                aria-label="Download csv"
                onClick={onCsvDownload}
              />
            )}
          </Flex>
        </Flex>

        <Flex
          justifyContent="center"
          flexDirection="column"
          w="100%"
          gap="26px"
        >
          {!data.length && <Text>No transactions yet</Text>}
          {data.map((t) =>
            "items" in t ? (
              <OrgWalletTransaction
                key={t.id}
                transaction={t}
                onPoolClick={(poolTransaction) =>
                  setOpenedPoolModal(poolTransaction)
                }
              />
            ) : (
              <WalletTransaction
                key={t.id}
                transaction={t}
                onPoolClick={() => setOpenedPoolModal(t)}
                onPayoutHelpClick={() => setPayoutHelpModal(t)}
              />
            )
          )}
          {hasMore && (
            <Button
              bg={boxBg}
              fontSize="sm"
              fontWeight="500"
              color={textColorSecondary}
              borderRadius="7px"
              onClick={loadMore}
              disabled={isLoadingMore}
            >
              <Icon as={MdArrowDownward} color={textColorSecondary} me="4px" />
              Load more
            </Button>
          )}
        </Flex>
      </Card>
    </>
  );
}

function RangePickerButton({
  value,
  onChange,
}: {
  value: [Date, Date] | null | undefined;
  onChange: (dates: [Date, Date] | null | undefined) => void;
}) {
  const [selectedRange, setSelectedRange] = useState<
    [Date, Date] | undefined | null
  >(value);

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton
              variant={value ? "brand" : undefined}
              icon={<Icon as={MdOutlineCalendarToday} />}
              aria-label="Filter by date"
            />
          </PopoverTrigger>
          <PopoverContent p="20px">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <RangeCalendar
                value={selectedRange}
                onChange={setSelectedRange as any}
              />
            </PopoverBody>
            <PopoverFooter
              border="0"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              pb={4}
            >
              <ButtonGroup size="sm">
                <Button
                  onClick={() => {
                    onChange(null);
                    setSelectedRange(null);
                    onClose();
                  }}
                >
                  Clear filter
                </Button>
                <Button
                  variant="brand"
                  onClick={() => {
                    onChange(selectedRange);
                    onClose();
                  }}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}
