import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import { useQueryClient } from "@tanstack/react-query";
import {
  ParticipantDTO,
  OrganizationDTO,
  useOrganizationParticipantsControllerUpdate,
  getOrganizationParticipantsControllerFindAllQueryKey,
  useOrganizationTagsControllerGetTags,
} from "api";
import { useFormik } from "formik";
import React from "react";

export function ParticipantEditModal({
  organization,
  participant,
  disclosure,
  myParticipant,
}: {
  organization: Pick<OrganizationDTO, "id" | "name">;
  participant: Pick<
    ParticipantDTO,
    "id" | "role" | "user" | "publicTags" | "privateTags"
  >;
  myParticipant?: Pick<
    ParticipantDTO,
    "id" | "role" | "user" | "publicTags" | "privateTags"
  >;
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLSelectElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const tagBg = useColorModeValue("brand.500", "brand.400");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError, isLoading } =
    useOrganizationParticipantsControllerUpdate();

  const { data: tagsResponse } = useOrganizationTagsControllerGetTags(
    organization.id
  );

  const formik = useFormik({
    initialValues: {
      role: participant.role,
      privateTags: participant.privateTags,
      publicTags: participant.publicTags,
    },
    onSubmit: (values, helpers) => {
      mutate({
        organizationId: organization.id,
        participantId: participant.id,
        data: values,
      });
    },
    validate: (values) => {
      if (!values.role) {
        return { role: "Role cannot be empty" };
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        getOrganizationParticipantsControllerFindAllQueryKey(organization.id)
      );
      formik.resetForm();
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError, queryClient, organization.id, onClose]);

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Edit teammate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              isRequired
              isInvalid={Boolean(formik.errors.role && formik.touched.role)}
            >
              <Flex direction="column" mb="30px">
                <FormLabel
                  display="flex"
                  ms="10px"
                  htmlFor="role"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Role
                </FormLabel>
                <Select
                  id="role"
                  ref={initialRef}
                  fontSize="sm"
                  variant="main"
                  value={formik.values.role}
                  width="unset"
                  fontWeight="700"
                  onChange={formik.handleChange}
                  disabled={myParticipant?.id === participant.id}
                >
                  <option value="USER">User</option>
                  <option value="ADMIN">Admin</option>
                  <option value="OWNER">Owner</option>
                </Select>
              </Flex>
              <FormErrorMessage>{formik.errors.role}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={Boolean(
                formik.errors.publicTags && formik.touched.publicTags
              )}
            >
              <Flex direction="column" mb="30px">
                <FormLabel
                  display="flex"
                  ms="10px"
                  htmlFor="publicTags"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Public tags
                </FormLabel>
                <AutoComplete
                  multiple
                  creatable
                  openOnFocus
                  closeOnSelect
                  closeOnBlur
                  restoreOnBlurIfEmpty={false}
                  values={formik.values.publicTags}
                  onChange={(value, item) => {
                    formik.setFieldValue("publicTags", value, true);
                  }}
                >
                  <AutoCompleteInput
                    id="publicTags"
                    variant="main"
                    borderRadius="16px"
                    placeholder="Enter tags"
                    _placeholder={{
                      color: "secondaryGray.600",
                      fontWeight: "400",
                    }}
                    minH="40px"
                    fontWeight="500"
                    fontSize="sm"
                  >
                    {({ tags }) =>
                      tags.map((tag, tid) => (
                        <AutoCompleteTag
                          key={tid}
                          label={tag.label}
                          onRemove={tag.onRemove}
                          bgColor={tagBg}
                          variant="solid"
                          borderRadius="12px"
                        />
                      ))
                    }
                  </AutoCompleteInput>
                  <AutoCompleteList>
                    <AutoCompleteCreatable>
                      {({ value }) => <span>Add {value} tag</span>}
                    </AutoCompleteCreatable>
                    <AutoCompleteItem
                      label={organization.name}
                      value={organization.name}
                      fixed
                    >
                      {organization.name}
                    </AutoCompleteItem>
                    {tagsResponse?.data.public
                      .filter((t) => t !== organization.name)
                      .map((t, index) => (
                        <AutoCompleteItem key={`option-${index}`} value={t}>
                          {t}
                        </AutoCompleteItem>
                      ))}
                  </AutoCompleteList>
                  <FormHelperText
                    ms="10px"
                    mt="5px"
                    fontSize="sm"
                    color="secondaryGray.600"
                  >
                    These tags are shown on restaurant's profile to tip givers,
                    and can be used by people for a better navigation, so tags
                    are publicly visible
                  </FormHelperText>
                </AutoComplete>
                <FormErrorMessage>{formik.errors.publicTags}</FormErrorMessage>
              </Flex>
            </FormControl>
            <FormControl
              isInvalid={Boolean(
                formik.errors.privateTags && formik.touched.privateTags
              )}
            >
              <Flex direction="column">
                <FormLabel
                  display="flex"
                  ms="10px"
                  htmlFor="privateTags"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Private Tags
                </FormLabel>
                <AutoComplete
                  multiple
                  creatable
                  openOnFocus
                  closeOnSelect
                  closeOnBlur
                  restoreOnBlurIfEmpty={false}
                  values={formik.values.privateTags}
                  onChange={(value, item) => {
                    formik.setFieldValue("privateTags", value, true);
                  }}
                >
                  <AutoCompleteInput
                    id="privateTags"
                    variant="main"
                    borderRadius="16px"
                    placeholder="Enter tags"
                    _placeholder={{
                      color: "secondaryGray.600",
                      fontWeight: "400",
                    }}
                    minH="40px"
                    fontWeight="500"
                    fontSize="sm"
                  >
                    {({ tags }) =>
                      tags.map((tag, tid) => (
                        <AutoCompleteTag
                          key={tid}
                          label={tag.label}
                          onRemove={tag.onRemove}
                          bgColor={tagBg}
                          variant="solid"
                          borderRadius="12px"
                        />
                      ))
                    }
                  </AutoCompleteInput>
                  <AutoCompleteList>
                    <AutoCompleteCreatable>
                      {({ value }) => <span>Add {value} tag</span>}
                    </AutoCompleteCreatable>
                    {tagsResponse?.data.private.map((t, index) => (
                      <AutoCompleteItem key={`option-${index}`} value={t}>
                        {t}
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteList>
                  <FormHelperText
                    ms="10px"
                    mt="5px"
                    fontSize="sm"
                    color="secondaryGray.600"
                  >
                    These tags can be used to setup tips sharing, and visible
                    only for admins
                  </FormHelperText>
                </AutoComplete>
                <FormErrorMessage>{formik.errors.privateTags}</FormErrorMessage>
              </Flex>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={isLoading}
              type="submit"
              mr={3}
              variant="brand"
              me="14px"
            >
              Save
            </Button>
            <Button onClick={onClose} variant="no-hover" bg={bgButton}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
