import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Text,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Link,
} from "@chakra-ui/react";
import { PropsOf, useColorModeValue } from "@chakra-ui/system";
import { WalletDTO } from "api";
import { useState, useMemo } from "react";
import { getI18n } from "react-i18next";
import { getCurrency } from "utils/currency";
import { CurrencyInput } from "./CurrencyInput";

export function BalanceInsufficient(
  props: PropsOf<typeof Alert> & {
    sumFormatter: Intl.NumberFormat;
  }
) {
  const { sumFormatter, ...rest } = props;

  return (
    <Alert borderRadius="8px" status="warning" variant="subtle" {...rest}>
      <Flex>
        <AlertIcon />
        <Flex direction="column">
          <AlertTitle mr="12px">Your balance is too low</AlertTitle>
          <AlertDescription>
            Unfortunately, we cannot process payouts for amounts less than{" "}
            {sumFormatter.format(1)}
          </AlertDescription>
        </Flex>
      </Flex>
    </Alert>
  );
}

export function EarlyPayoutAlert(
  props: PropsOf<typeof Alert> & {
    onClickAvailable: () => void;
    availableAmount: number;
    formatter: Intl.NumberFormat;
  }
) {
  const { onClickAvailable, availableAmount, formatter, ...rest } = props;

  return (
    <Alert
      borderRadius="8px"
      status="info"
      colorScheme="brand"
      variant="subtle"
      {...rest}
    >
      <Flex>
        <AlertIcon />
        <Flex direction="column">
          <AlertTitle mr="12px">Early Payout</AlertTitle>
          <AlertDescription>
            You're requesting more funds, than are currently available for
            payout (
            <b>
              <Link onClick={onClickAvailable}>
                {formatter.format(availableAmount / 100)}
              </Link>
            </b>
            ). GoTips will still try to fulfill your request, the final amount
            will be confirmed on the next step.
          </AlertDescription>
        </Flex>
      </Flex>
    </Alert>
  );
}

export function PayoutPage1({
  wallet,
  onClose,
  onContinue,
}: {
  wallet: WalletDTO;
  onClose: () => void;
  onContinue: (amount: number) => void;
}) {
  const availableBalance = wallet.balance + wallet.holdBalance;

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [payoutValue, setPayoutValue] = useState(Math.max(wallet.balance, 100));

  const { code: currencyCode, precision } = getCurrency(wallet.currency);

  const locale = getI18n().language;
  const { format: sumFormatter, currencySymbol } = useMemo(() => {
    const format = new Intl.NumberFormat(locale, {
      currency: currencyCode,
      style: "currency",
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
    const currencySymbol =
      format.formatToParts(0).find((p) => p.type === "currency")?.value ??
      currencyCode;

    return { format, currencySymbol };
  }, [currencyCode, precision, locale]);

  const isValid = payoutValue <= availableBalance && payoutValue >= 100;

  return (
    <>
      <ModalHeader>Payout funds</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex direction="column" gap={4}>
          <Flex direction="column" gap={2}>
            <CurrencyInput
              ledgerCurrency={wallet.currency}
              initialValue={wallet.balance}
              value={payoutValue}
              minValue={100}
              maxValue={availableBalance}
              onChange={setPayoutValue}
            />
            <Text>
              You may request payout up to:{" "}
              {sumFormatter.format(availableBalance / 100)}
            </Text>
          </Flex>
          {!isValid && payoutValue >= 100 && (
            <Text color="red">
              Please check amount. You may request payout up to{" "}
              {sumFormatter.format(availableBalance / 100)}
            </Text>
          )}
          {!isValid && payoutValue < 100 && (
            <BalanceInsufficient sumFormatter={sumFormatter} />
          )}
          {isValid && payoutValue > wallet.balance && (
            <EarlyPayoutAlert
              availableAmount={Math.max(wallet.balance, 0)}
              formatter={sumFormatter}
              onClickAvailable={() => {
                setPayoutValue(Math.max(wallet.balance, 0));
              }}
            />
          )}
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Button
          disabled={!isValid || payoutValue === 0}
          onClick={() => {
            onContinue(payoutValue);
          }}
          mr={3}
          variant="brand"
          me="14px"
        >
          Continue
        </Button>
        <Button onClick={onClose} variant="no-hover" bg={bgButton}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
}
