import { UserDTO } from "api";

type ShortUser = Pick<UserDTO, "firstName" | "lastName">;
type FullUser = ShortUser & Pick<UserDTO, "email">;

const defaultName = "Someone without a name";

export function getName(user: ShortUser | FullUser | undefined | null) {
  let name = defaultName;

  if (!user) {
    return name;
  }

  name = `${user.firstName ?? ""} ${user.lastName ?? ""}`;

  if (name.length <= 1) {
    if ("email" in user) {
      name = user.email;
    } else {
      name = defaultName;
    }
  }

  return name;
}

export function userHasName(user?: ShortUser | FullUser | undefined) {
  return user && (user.firstName || user.lastName)
} 
