import { Redirect, Route, Switch } from "react-router-dom";

import DashboardsDefault from "views/admin/dashboards/default/AdminDashboard";
import OrganizationList from "views/admin/main/organizations/list";
import OrganizationDetails from "views/admin/main/organizations/details";
import { CommonLayout } from "layouts/common/CommonLayout";
import AccountSettings from "views/admin/main/account/settings";

// Custom Chakra theme
export function AdminLayout(props: { [x: string]: any }) {
  return (
    <CommonLayout {...props}>
      <Switch>
        <Route path="/admin/profile" component={AccountSettings} />
        <Route path="/admin" exact={true} component={DashboardsDefault} />
        <Route
          path="/admin/organizations"
          exact={true}
          component={OrganizationList}
        />
        <Route
          path="/admin/organizations/:organizationId"
          exact={true}
          component={OrganizationDetails}
        />
      </Switch>
    </CommonLayout>
  );
}
