// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  getUserControllerMeQueryKey,
  UserDTOPayoutFrequency,
  useUserControllerUpdate,
} from "api";
import Card from "components/card/Card";
import InputField from "components/fields/InputField";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useUserContext } from "utils/userContext";

export default function Information(props: { [x: string]: any }) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const queryClient = useQueryClient();
  const user = useUserContext();
  const toast = useToast();

  const { mutate, isLoading, isSuccess } = useUserControllerUpdate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      collectingGoal: user?.collectingGoal ?? "",
      displayName: user?.displayName ?? "",
      payoutFrequency: user?.payoutFrequency ?? UserDTOPayoutFrequency.WEEKLY,
    },
    onSubmit: (values, helpers) => {
      mutate({
        data: values,
      });
    },
    validate: (values) => {
      if (!values.firstName) {
        return { name: "First name cannot be empty" };
      }

      if (!values.lastName) {
        return { name: "Last name cannot be empty" };
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(getUserControllerMeQueryKey());
      formik.resetForm();

      toast({
        title: `Profile has been updated!`,
        position: "top",
        status: "success",
        isClosable: true,
      });
    }
  }, [isSuccess]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card mb="20px" {...rest}>
        <Flex direction="column" mb="30px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Profile
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Here you can change your profile
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <FormControl
            isRequired
            isInvalid={Boolean(
              formik.errors.firstName && formik.touched.firstName
            )}
          >
            <InputField
              mb="20px"
              me="30px"
              id="firstName"
              label="First Name"
              placeholder="First name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
            <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={Boolean(
              formik.errors.lastName && formik.touched.lastName
            )}
          >
            <InputField
              mb="20px"
              id="lastName"
              label="Last Name"
              placeholder="Last name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
            <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <FormControl
          isInvalid={Boolean(
            formik.errors.displayName && formik.touched.displayName
          )}
        >
          <InputField
            id="displayName"
            label="Display name"
            placeholder="Publicly available name"
            value={formik.values.displayName}
            onChange={formik.handleChange}
          />
          <FormErrorMessage>{formik.errors.displayName}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={Boolean(
            formik.errors.collectingGoal && formik.touched.collectingGoal
          )}
        >
          <InputField
            id="collectingGoal"
            label="Goal"
            placeholder="Goal of collecting money"
            value={formik.values.collectingGoal}
            onChange={formik.handleChange}
          />
          <FormErrorMessage>{formik.errors.collectingGoal}</FormErrorMessage>
        </FormControl>
        <Button
          borderRadius="16px"
          minW="183px"
          h="44px"
          ms="auto"
          mt="33px"
          variant="brand"
          color="white"
          fontSize="sm"
          fontWeight="500"
          _hover={{ bg: "brand.600" }}
          _active={{ bg: "brand.500" }}
          _focus={{ bg: "brand.500" }}
          isDisabled={isLoading}
          type="submit"
        >
          Save
        </Button>
      </Card>
    </form>
  );
}
