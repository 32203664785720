// Chakra imports
import {
  Flex,
  Text,
  Avatar,
  useColorModeValue,
  Button,
  Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { MdUpload } from "react-icons/md";
import { useUserContext } from "utils/userContext";
import { getName } from "utils/name";
import { ImageUploaderModal } from "views/admin/main/organizations/details/components/ImageUploaderModal";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { getUserControllerMeQueryKey } from "api";

export default function Profile(props: { [x: string]: any }) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const user = useUserContext();
  const [openedUploader, openUploader] = useState(false);
  const queryClient = useQueryClient();

  const invalidateUser = () => {
    queryClient.invalidateQueries(getUserControllerMeQueryKey());
  };

  // Chakra Color Mode
  return (
    <>
      <Card mb="20px" {...rest}>
        <Flex align="flex-start">
          <Avatar
            src={user?.avatarImage ?? ""}
            name={getName(user)}
            h="87px"
            w="87px"
            me="20px"
          />
          <Flex direction="column">
            <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
              {getName({
                firstName: user?.firstName,
                lastName: user?.lastName,
              })}
            </Text>
            <Text mt="1px" mb="10px" color={textColorSecondary} fontSize="md">
              {user?.email}
            </Text>
            <Button
              id="upload_avatar"
              alignItems="center"
              justifyContent="center"
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              h="37px"
              lineHeight="100%"
              onClick={() => openUploader(true)}
              borderRadius="10px"
              leftIcon={
                <Icon as={MdUpload} color={iconColor} w="20px" h="20px" />
              }
            >
              Upload avatar
            </Button>
          </Flex>
        </Flex>
      </Card>
      <ImageUploaderModal
        endpoint={`${process.env.REACT_APP_API_BASE_URL}/user/upload`}
        uppyUniqueToken="userAvatar"
        open={openedUploader}
        onRequestClose={() => openUploader(false)}
        onComplete={invalidateUser}
      />
    </>
  );
}
