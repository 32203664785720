import Uppy, { Restrictions } from "@uppy/core";
import ImageEditor from "@uppy/image-editor";
import XHR from "@uppy/xhr-upload";

export interface UppyInstanceOptions {
  id: string;
  endpoint: string;
  meta: Record<string, unknown>;
  restrictions?: Restrictions;
}
export function createUppy(options: UppyInstanceOptions) {
  return new Uppy({
    id: options.id,
    allowMultipleUploads: false,
    allowMultipleUploadBatches: false,
    restrictions: {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: [".jpg", ".jpeg", ".png"],
      ...options.restrictions,
    },
    meta: options.meta,
  })
    .use(ImageEditor, {
      actions: {
        revert: true,
        rotate: true,
        granularRotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: true,
        cropWidescreen: true,
        cropWidescreenVertical: true,
      },
    })
    .use(XHR, { endpoint: options.endpoint, replaceTargetContent: true });
}
