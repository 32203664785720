import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useColorModeValue,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteList,
  AutoCompleteItem,
  AutoCompleteRefMethods,
} from "@choc-ui/chakra-autocomplete";
import { useQueryClient } from "@tanstack/react-query";
import {
  useOrganizationControllerCreate,
  getOrganizationControllerListQueryKey,
  useOrganizationControllerUpdate,
  getOrganizationControllerFindOneQueryKey,
  UserDTOBeneficiaryStatus,
  UpdateBeneficiaryDTO,
  useUserBeneficiaryControllerUpdateBeneficiary,
  getUserControllerMeQueryKey,
  UserDTOPayoutFrequency,
} from "api";
import InputField from "components/fields/InputField";
import { useFormik } from "formik";
import {
  extractIBAN,
  getCountrySpecifications,
  isSEPACountry,
} from "ibantools";
import React, { useRef } from "react";
import { useUserContext } from "utils/userContext";
import { countryList, countryListAlpha2, currencyList } from "./country.list";

export function EditBeneficiaryModal({
  disclosure,
}: {
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLInputElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();
  const user = useUserContext();
  const toast = useToast();
  const initialValues = {
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    bankCountry: user?.beneficiary?.bankCountry ?? "",
    currency: user?.beneficiary?.currency ?? "EUR",
    iban: user?.beneficiary?.iban ?? "",
    streetLine1: user?.beneficiary?.streetLine1 ?? "",
    streetLine2: user?.beneficiary?.streetLine2 ?? "",
    city: user?.beneficiary?.city ?? "",
    country: user?.beneficiary?.country ?? "",
    postCode: user?.beneficiary?.postCode ?? "",
    agree: false,
  };
  const autocompleteRef = useRef<AutoCompleteRefMethods>(null);
  const { mutate, isSuccess, isError, isLoading } =
    useUserBeneficiaryControllerUpdateBeneficiary();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, helpers) => {
      mutate({
        data: {
          ...values,
          bankCountry: extractIBAN(values.iban).countryCode ?? "",
        },
      });
    },
    validate: async (values) => {
      if (!values.firstName) {
        return { firstName: "This field should not be empty" };
      }
      if (!values.lastName) {
        return { lastName: "This field should not be empty" };
      }

      const ibanSpec = extractIBAN(values.iban);

      if (!ibanSpec.valid || !ibanSpec.countryCode) {
        return { iban: "Please check IBAN validity" };
      }

      if (!isSEPACountry(ibanSpec.countryCode)) {
        return { iban: "This IBAN does not belong to SEPA country" };
      }

      if (!values.currency) {
        return { currency: "This field should not be empty" };
      }

      if (values.currency !== "EUR") {
        return {
          currency:
            "Unfortunately, we support only EUR at the moment. Please contact support",
        };
      }

      if (!values.streetLine1) {
        return { streetLine1: "This field should not be empty" };
      }

      if (!values.postCode) {
        return { postCode: "This field should not be empty" };
      }

      if (!values.country) {
        return { country: "This field should not be empty" };
      }

      const country = values.country as keyof typeof countryListAlpha2;
      if (!countryListAlpha2[country]) {
        return { country: "Please select country from dropdown list" };
      }

      if (!values.agree) {
        return { agree: "You have to agree with this statement" };
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(getUserControllerMeQueryKey());
      onClose();
    }

    if (isError) {
      toast({
        title: `Something went wrong, please check provided information and try again`,
        position: "top",
        status: "error",
        isClosable: true,
      });
    }
  }, [isSuccess, isError, queryClient, onClose]);

  // autocompleteRef.current

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Update details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.firstName && formik.touched.firstName
              )}
              mb="20px"
            >
              <InputField
                mb="0px"
                label="First name of bank account"
                name="firstName"
                innerRef={initialRef}
                placeholder="Bank account's first name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.lastName && formik.touched.lastName
              )}
              mb="20px"
            >
              <InputField
                mb="0px"
                label="Last name of bank account"
                name="lastName"
                placeholder="Bank account's last name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={Boolean(formik.errors.iban && formik.touched.iban)}
              mb="20px"
            >
              <InputField
                mb="0px"
                label="IBAN"
                name="iban"
                placeholder="CY12345678901234567890123456"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.iban}
              />
              <FormErrorMessage>{formik.errors.iban}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.currency && formik.touched.currency
              )}
              mb="20px"
            >
              <Flex direction="column" mb="20px">
                <FormLabel
                  display="flex"
                  ms="10px"
                  htmlFor="country"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Currency
                </FormLabel>
                <Select
                  fontSize="sm"
                  id="currency"
                  variant="main"
                  h="44px"
                  maxH="44px"
                  fontWeight="500"
                  textColor={textColorPrimary}
                  me="20px"
                  value={formik.values.currency}
                  onChange={formik.handleChange}
                >
                  {currencyList.map((c) => (
                    <option value={c} key={c}>
                      {c}
                    </option>
                  ))}
                </Select>
              </Flex>
              <FormErrorMessage>{formik.errors.currency}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.streetLine1 && formik.touched.streetLine1
              )}
              mb="20px"
            >
              <InputField
                mb="0px"
                label="Street line 1"
                name="streetLine1"
                placeholder="Street address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.streetLine1}
              />
              <FormErrorMessage>{formik.errors.streetLine1}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={Boolean(
                formik.errors.streetLine2 && formik.touched.streetLine2
              )}
              mb="20px"
            >
              <InputField
                mb="0px"
                label="Street line 2"
                name="streetLine2"
                placeholder="Apartment, suite, or space number (if applicable)"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.streetLine2}
              />
              <FormErrorMessage>{formik.errors.streetLine2}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={Boolean(formik.errors.city && formik.touched.city)}
              mb="20px"
            >
              <InputField
                mb="0px"
                label="City"
                name="city"
                placeholder="City"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
              />
              <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.postCode && formik.touched.postCode
              )}
              mb="20px"
            >
              <InputField
                mb="0px"
                label="Postal code"
                name="postCode"
                placeholder="Postal code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postCode}
              />
              <FormErrorMessage>{formik.errors.postCode}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.country && formik.touched.country
              )}
            >
              <Flex direction="column" mb="20px">
                <FormLabel
                  display="flex"
                  ms="10px"
                  htmlFor="country"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Country
                </FormLabel>
                <AutoComplete
                  ref={autocompleteRef}
                  openOnFocus
                  closeOnSelect
                  closeOnBlur
                  restoreOnBlurIfEmpty={false}
                  freeSolo={false}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                  onChange={(value, _) => {
                    formik.setFieldValue("country", value, true);
                  }}
                  // Bug https://github.com/anubra266/choc-autocomplete/issues/235
                  defaultValue={
                    (countryListAlpha2 as any)[formik.values.country]
                  }
                >
                  <AutoCompleteInput
                    id="country"
                    name="country"
                    variant="main"
                    borderRadius="16px"
                    placeholder="Country"
                    _placeholder={{
                      color: "secondaryGray.600",
                      fontWeight: "400",
                    }}
                    minH="40px"
                    fontWeight="500"
                    fontSize="sm"
                  />
                  <AutoCompleteList>
                    {Object.entries(countryListAlpha2).map(([code, name]) => (
                      <AutoCompleteItem value={code} key={code} label={name}>
                        {name}
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteList>
                </AutoComplete>
              </Flex>
              <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
            </FormControl>
            <FormControl
              display="flex"
              alignItems="start"
              isInvalid={Boolean(formik.errors.agree && formik.touched.agree)}
            >
              <Checkbox
                id="agree"
                colorScheme="brandScheme"
                me="10px"
                mt="3px"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormLabel
                htmlFor="agree"
                mb="0"
                fontWeight="normal"
                color={textColorPrimary}
                fontSize="sm"
              >
                I certify that the information provided is accurate. I
                understand that any inaccuracies or mistakes in the information
                provided may result to additional bank fees, I take full
                responsibility of covering them.
              </FormLabel>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              mr={3}
              variant="brand"
              me="14px"
              isDisabled={isLoading}
            >
              Save
            </Button>
            <Button onClick={onClose} variant="no-hover" bg={bgButton}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
