import { chakra, useMultiStyleConfig, IconButton } from "@chakra-ui/react";
import {
  CurrencyDisplay,
  NumberInput as IntlNumberInput,
  NumberFormatStyle,
} from "intl-number-input";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClear } from "react-icons/md";
import { getCurrency } from "utils/currency";

export function CurrencyInput({
  ledgerCurrency,
  value,
  initialValue,
  minValue,
  maxValue,
  onChange,
  onFocus,
  onBlur,
}: {
  ledgerCurrency: string;
  initialValue: number;
  value: number;
  minValue: number;
  maxValue: number;
  onChange: (number: number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}) {
  const ref = useRef<HTMLInputElement>(null);
  const [refSet, setRefSet] = useState(false);
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { precision, code: currency } = getCurrency(ledgerCurrency);

  const handleChange = (newValue: number) => {
    onChange(newValue);
  };

  const sumFormatter = useMemo(() => {
    return new Intl.NumberFormat(locale, {
      currency,
      style: "currency",
      minimumFractionDigits: 0,
    });
  }, [currency, locale]);

  useEffect(() => {
    setRefSet(Boolean(ref.current));
  }, []);

  const intlInput = useMemo(() => {
    if (!ref.current) {
      return;
    }

    let initialized = false;

    const input = new IntlNumberInput({
      el: ref.current,
      options: {
        locale,
        currency,
        precision,
        formatStyle: NumberFormatStyle.Currency,
        currencyDisplay: CurrencyDisplay.Symbol,
        hidePrefixOrSuffixOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        exportValueAsInteger: true,
        useGrouping: false,
        valueRange: {
          min: minValue / 100,
          max: maxValue / 100,
        },
      },
      onInput(value) {
        if (initialized) {
          const newValue = value.number ?? 0;

          if (newValue >= minValue || newValue === 0) {
            handleChange(newValue);
          }
        }
      },
      onChange: (value) => {
        if (initialized) {
          handleChange(value.number ?? 0);
        }
      },
    });

    input.setValue(initialValue);
    initialized = true;

    return input;
  }, [refSet, locale, currency, precision]);

  useEffect(() => {
    if (!intlInput) {
      return;
    }

    if (value === 0) {
      intlInput.setValue(null);
    } else if (value === initialValue) {
      intlInput.setValue(value);
    }
  }, [value]);

  const styles = useMultiStyleConfig("Input", { variant: "auth" });

  return (
    <chakra.div
      __css={styles.field}
      border="none"
      borderBottom="1px solid"
      flexDirection="row"
      display="flex"
      borderRadius={0}
      alignItems="center"
      padding={0}
    >
      <chakra.input
        ref={ref}
        type="text"
        id="tipInput"
        autoComplete="off"
        __css={styles.field}
        fontSize="2xl"
        border="none"
        textAlign="center"
        placeholder={sumFormatter.format(minValue / 100)}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {value !== 0 && (
        <IconButton
          position="absolute"
          right="0"
          aria-label="Clear"
          icon={<MdClear />}
          variant="action"
          size="sm"
          onMouseDown={(e) => {
            intlInput?.setValue(null);
            e.preventDefault();
          }}
        />
      )}
    </chakra.div>
  );
}
