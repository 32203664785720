import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Text,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Icon,
  IconButton,
  useToast,
  CircularProgress,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import { PropsOf, useColorModeValue } from "@chakra-ui/system";
import { useQueryClient } from "@tanstack/react-query";
import {
  getUserPayoutControllerGetPayoutQueryKey,
  getUserWalletControllerGetTransactionsQueryKey,
  getUserWalletControllerGetWalletQueryKey,
  userPayoutControllerCancelPayout,
  useUserPayoutControllerCancelPayout,
  useUserPayoutControllerConfirmPayout,
  useUserPayoutControllerCreatePayout,
  useUserPayoutControllerGetPayout,
  WalletDTO,
} from "api";
import { isAxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { getI18n } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";
import { getCurrency } from "utils/currency";
import { getName } from "utils/name";
import { generateUid } from "utils/uid";
import { useUserContext } from "utils/userContext";

export function NonZeroFeeAlert(
  props: PropsOf<typeof Alert> & {
    sumFormatter: Intl.NumberFormat;
  }
) {
  const { sumFormatter, ...rest } = props;

  return (
    <Alert borderRadius="8px" status="warning" variant="subtle" {...rest}>
      <Flex>
        <AlertIcon />
        <Flex direction="column">
          <AlertTitle mr="12px">Payout Fee</AlertTitle>
          <AlertDescription>
            For payouts less than {sumFormatter.format(50)} we charge a fixed{" "}
            {sumFormatter.format(0.2)} fee to cover bank transfer costs. Please
            note, that your bank may also incur additional charges for incoming
            payments.
          </AlertDescription>
        </Flex>
      </Flex>
    </Alert>
  );
}

export function ZeroFeeAlert(
  props: PropsOf<typeof Alert> & {
    sumFormatter: Intl.NumberFormat;
  }
) {
  const { sumFormatter, ...rest } = props;

  return (
    <Alert
      borderRadius="8px"
      status="info"
      colorScheme="brand"
      variant="subtle"
      {...rest}
    >
      <Flex>
        <AlertIcon />
        <Flex direction="column">
          <AlertTitle mr="12px">Zero payout fee</AlertTitle>
          <AlertDescription>
            GoTips do not charge any fees for payout amounts greater than{" "}
            {sumFormatter.format(50)}, however please note, that your bank still
            may incur additional charges for incoming payments.
          </AlertDescription>
        </Flex>
      </Flex>
    </Alert>
  );
}

export function AmountChangedAlert(
  props: PropsOf<typeof Alert> & {
    sumFormatter: Intl.NumberFormat;
  }
) {
  const { sumFormatter, ...rest } = props;

  return (
    <Alert borderRadius="8px" status="warning" variant="subtle" {...rest}>
      <Flex>
        <AlertIcon />
        <Flex direction="column">
          <AlertTitle mr="12px">Amount has been changed</AlertTitle>
          <AlertDescription>
            Unfortunately, we are able to process your early payout request only
            with partial amount at the moment.
          </AlertDescription>
        </Flex>
      </Flex>
    </Alert>
  );
}

export function LoadingState() {
  return (
    <Flex direction="column" gap={2}>
      <Flex w="100%" justify="center">
        <CircularProgress isIndeterminate color="brand.500" mb="10px" />
      </Flex>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Flex>
  );
}

export function ZeroAmountAlert(props: PropsOf<typeof Alert>) {
  return (
    <Alert borderRadius="8px" status="error" variant="subtle" {...props}>
      <Flex>
        <AlertIcon />
        <Flex direction="column">
          <AlertTitle mr="12px">Early payout is unavailable</AlertTitle>
          <AlertDescription>
            Unfortunately, we are unable to process your early payout request
            due to temporary inavailability of this feature. Please try again
            later.
          </AlertDescription>
        </Flex>
      </Flex>
    </Alert>
  );
}

export function PayoutPage2({
  wallet,
  amount,
  onClose,
  onBack,
}: {
  wallet: WalletDTO;
  amount: number;
  onClose: () => void;
  onBack: () => void;
}) {
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { code: currencyCode, precision } = getCurrency(wallet.currency);
  const user = useUserContext();

  const locale = getI18n().language;
  const { format: sumFormatter, currencySymbol } = useMemo(() => {
    const format = new Intl.NumberFormat(locale, {
      currency: currencyCode,
      style: "currency",
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
    const currencySymbol =
      format.formatToParts(0).find((p) => p.type === "currency")?.value ??
      currencyCode;

    return { format, currencySymbol };
  }, [currencyCode, precision, locale]);

  const recipientName = user?.beneficiary && getName(user.beneficiary);
  const recipientIBAN = user?.beneficiary && user.beneficiary.iban;

  const [idempotencyKey] = useState(generateUid());
  const queryClient = useQueryClient();
  const {
    mutate,
    error: createError,
    data: createResponse,
  } = useUserPayoutControllerCreatePayout();
  const { data, error: getError } = useUserPayoutControllerGetPayout(
    idempotencyKey,
    {
      query: {
        enabled: !!createResponse?.data,
        retry: (failureCount, error) => {
          const status = error.response?.status;

          if (status && status >= 400 && status < 500) {
            return false;
          }

          return failureCount < 3;
        },
        refetchInterval: (data, query) => {
          if (
            data?.data.status === "EXECUTING" ||
            data?.data.status === "PENDING"
          ) {
            return 3000;
          }

          return false;
        },
      },
    }
  );

  const {
    mutate: mutateConfirmPayout,
    isLoading: confirmIsLoading,
    isSuccess: confirmIsSuccess,
    data: confirmResponse,
  } = useUserPayoutControllerConfirmPayout();

  const { mutate: mutateCancelPayout } = useUserPayoutControllerCancelPayout();

  useEffect(() => {
    mutate({
      data: {
        amount,
        currency: wallet.currency,
        reference: idempotencyKey,
      },
    });
  }, []);

  const [seconds, setSeconds] = useState<number | undefined>();

  useEffect(() => {
    if (createResponse || confirmResponse) {
      queryClient.invalidateQueries({
        queryKey: getUserPayoutControllerGetPayoutQueryKey(idempotencyKey),
        refetchType: "all",
      });
    }
  }, [createResponse, confirmResponse, queryClient, idempotencyKey]);

  useEffect(() => {
    if (!data?.data.validUntil) {
      return;
    }

    const validUntil = new Date(data.data.validUntil as string);

    const interval = setInterval(() => {
      const secondsLeft = Math.floor(
        (validUntil.getTime() - Date.now()) / 1000
      );

      const safeSecondsLeft = Math.max(secondsLeft, 0);
      setSeconds(safeSecondsLeft);

      if (secondsLeft < 1 && !confirmIsLoading && !confirmIsSuccess) {
        toast({
          title: `Time is out for payout request. Please try again.`,
          position: "top",
          status: "warning",
          isClosable: true,
        });

        clearInterval(interval);
        handleBack();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [data]);

  const toast = useToast();

  useEffect(() => {
    if (createError) {
      toast({
        title: `Something went wrong. Please reload page and try again.`,
        position: "top",
        status: "error",
        isClosable: true,
      });

      return handleBack();
    }

    if (getError && isAxiosError(getError) && getError.response) {
      if (
        getError.response &&
        getError.response.status >= 400 &&
        getError.response.status < 500
      ) {
        toast({
          title: `Something went wrong. Please reload page and try again.`,
          position: "top",
          status: "error",
          isClosable: true,
        });

        return handleBack();
      }
    }
  }, [createError, getError]);

  useEffect(() => {
    if (data && data.data.status === "EXECUTED") {
      queryClient.invalidateQueries({
        queryKey: getUserWalletControllerGetWalletQueryKey(),
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: ["waiter_transactions"],
        refetchType: "all",
      });

      toast({
        title: `Payout succeeded`,
        position: "top",
        status: "success",
        isClosable: true,
      });
      onClose();
    }
  }, [data]);

  const handleConfirm = () => {
    if (data) {
      mutateConfirmPayout({
        payoutId: data.data.reference,
      });
    }
  };

  const handleClose = () => {
    if (data?.data.status) {
      mutateCancelPayout({
        payoutId: idempotencyKey,
      });
    }
    onClose();
  };

  const handleBack = () => {
    if (data?.data.status) {
      mutateCancelPayout({
        payoutId: idempotencyKey,
      });
    }
    onBack();
  };

  const isZeroAmountOrLess =
    data &&
    data.data.amount !== undefined &&
    data.data.fee !== undefined &&
    data.data.amount - data.data.fee <= 0;

  return (
    <>
      <ModalHeader>
        <Flex direction="row" gap={2}>
          <IconButton
            size="sm"
            aria-label="Back"
            ml="-5px"
            onClick={handleBack}
            icon={<Icon as={BsArrowLeft} w="20px" h="20px" />}
          />
          <Text>Review information</Text>
        </Flex>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {isZeroAmountOrLess && <ZeroAmountAlert />}
        {data?.data.status !== "WAITING" && <LoadingState />}
        {!isZeroAmountOrLess && data?.data.status === "WAITING" && (
          <Flex direction="column" gap={1}>
            {data.data.amount !== undefined &&
              data.data.requestedAmount !== data.data.amount && (
                <AmountChangedAlert sumFormatter={sumFormatter} />
              )}
            <Text>
              Payout amount:{" "}
              <b>{sumFormatter.format((data.data.amount ?? 0) / 100)}</b>
            </Text>
            <Text>
              Recipient name: <b>{recipientName}</b>
            </Text>
            <Text>
              Recipient IBAN: <b>{recipientIBAN}</b>
            </Text>
            <Text>
              Recipient gets:{" "}
              <b>
                {sumFormatter.format(
                  ((data.data.amount ?? 0) - (data.data.fee ?? 0)) / 100
                )}
              </b>
            </Text>
            <Text>
              Fee: <b>{sumFormatter.format((data.data.fee ?? 0) / 100)}</b>
            </Text>
            {data.data.fee !== undefined && data.data.fee > 0 ? (
              <NonZeroFeeAlert sumFormatter={sumFormatter} />
            ) : (
              <ZeroFeeAlert sumFormatter={sumFormatter} />
            )}
          </Flex>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          disabled={
            data?.data.status !== "WAITING" ||
            confirmIsLoading ||
            isZeroAmountOrLess
          }
          onClick={handleConfirm}
          mr={3}
          variant="brand"
          me="14px"
        >
          {seconds !== undefined && seconds <= 60
            ? `Make payout (${seconds})`
            : `Make payout`}
        </Button>
        <Button onClick={handleClose} variant="no-hover" bg={bgButton}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
}
