import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { getOrgQRControllerListQueryKey, useOrgQRControllerDelete } from "api";
import React from "react";

export function QRDeleteAlert({
  organization,
  qr,
  disclosure,
}: {
  organization: {
    id: string;
  };
  qr: {
    id: string;
    internalCode: string;
  };
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError } = useOrgQRControllerDelete();

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        getOrgQRControllerListQueryKey(organization.id)
      );
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError, queryClient, organization.id, onClose]);

  return (
    <AlertDialog
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg={modalBg}>
          <AlertDialogHeader>Delete {qr.internalCode}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to delete this QR-code?
            <br />
            Please, make sure you don't use it in your tips sharing rules.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              variant="no-hover"
              bg={bgButton}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                mutate({ qrId: qr.id, organizationId: organization.id })
              }
              colorScheme="red"
              me="14px"
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
