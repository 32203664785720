import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  getOrganizationControllerListQueryKey,
  getOrgWalletControllerListQueryKey,
  useOrganizationControllerDelete,
  useOrgWalletControllerDelete,
} from "api";
import React from "react";

export function OrgWalletDeleteAlert({
  organizationId,
  wallet,
  disclosure,
}: {
  organizationId: string;
  wallet: {
    id: string;
    name?: string;
  };
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError } = useOrgWalletControllerDelete();

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        getOrgWalletControllerListQueryKey(organizationId)
      );
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError, queryClient, organizationId, onClose]);

  return (
    <AlertDialog
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg={modalBg}>
          <AlertDialogHeader>
            Delete {wallet.name ?? "Wallet"}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to delete wallet?<br />
            Make sure you don't use this wallet for tips distribution.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              variant="no-hover"
              bg={bgButton}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                mutate({ organizationId: organizationId, walletId: wallet.id })
              }
              colorScheme="red"
              me="14px"
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
