import Card from "components/card/Card";
import { Flex, Select, Text, useColorModeValue } from "@chakra-ui/react";
import {
  OrganizationDTO,
  useOrganizationControllerList,
  useOrgQRControllerList,
  useUserPartControllerMyParticipants,
} from "api";
import { QrCodeDownloader } from "../participants/components/QrCodeDownloader";
import { useEffect, useState } from "react";
import { useUserContext } from "utils/userContext";
import QRTable from "./QRTable";

export function OrganizationQrCode(props: {
  organization: Pick<OrganizationDTO, "id" | "name" | "number">;
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { data: response } = useOrgQRControllerList(props.organization.id);

  return (
    <Flex direction="column" gap={6}>
      <Card px="26px" py="30px" w="100%">
        <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">
          Main QR code
        </Text>
        <Text
          color="secondaryGray.600"
          fontSize="md"
          fontWeight="400"
          mb="30px"
        >
          Share it with your guests to let them leave tips
        </Text>

        <QrCodeDownloader organization={props.organization} size={256} />
      </Card>
      <Card>
        <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">
          Dynamic QR codes
        </Text>
        <Text
          color="secondaryGray.600"
          fontSize="md"
          fontWeight="400"
          mb="30px"
        >
          Manage QR-codes on your own, setup as many code as you want and use it
          to setup tips sharing
        </Text>
        <QRTable
          tableData={response?.data ?? []}
          organization={props.organization}
        />
      </Card>
    </Flex>
  );
}

export function WaiterQrCode() {
  const { data: pData } = useUserPartControllerMyParticipants();
  const [partId, setPartId] = useState<string>();
  const selectedPart = pData?.data.find((p) => p.id === partId);
  const user = useUserContext();

  useEffect(() => {
    if (!partId && pData?.data.length) {
      setPartId(pData.data[0].id);
    }
  }, [partId, pData]);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card w="100%">
      <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">
        Your QR codes
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="30px">
        Share it with your guests to let them leave tips to you
      </Text>
      <Select
        value={partId}
        placeholder={
          pData?.data?.length ? "Select restaurant" : "No restaurants available"
        }
        onChange={(e) => setPartId(e.target.value)}
        mb="20px"
      >
        {pData?.data.map((o) => (
          <option key={o.id} value={o.id}>
            {o.organization.name}
          </option>
        ))}
      </Select>
      {selectedPart && user && (
        <QrCodeDownloader
          participant={{
            ...selectedPart,
            user,
          }}
          size={256}
        />
      )}
    </Card>
  );
}
