import { Box, Flex, Icon, Tab, Text, useColorModeValue } from "@chakra-ui/react";
import { IconType } from "react-icons";

export function DetailsTab(props: {
  title: string;
  isActive?: boolean;
  // onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: IconType;
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Tab
      pb="0px"
      px={{ base: "8px", md: "16px" }}
      flexDirection="column"
      // onClick={props.onClick}
      // me="50px"
      bg="unset"
      _selected={{
        bg: "none",
      }}
      _focus={{ border: "none" }}
      minW="max-content"
    >
      <Flex align="center">
        <Icon color={textColor} as={props.icon} w="20px" h="20px" me="8px" />
        <Text color={textColor} fontSize="lg" fontWeight="500" me="12px">
          {props.title}
        </Text>
      </Flex>
      <Box
        height="4px"
        w="100%"
        transition="0.1s linear"
        bg={props.isActive ? "brand.500" : "transparent"}
        mt="15px"
        borderRadius="30px"
      />
    </Tab>
  );
}
