/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  UserDTO,
  UpdateUserDTO,
  SuccessDTO,
  WalletDTO,
  TransactionResponseDTO,
  UserWalletControllerGetTransactionsParams,
  UserParticipantDTO,
  Kycdto,
  BeneficiaryDTO,
  UpdateBeneficiaryDTO,
  UserPayoutDTO,
  UserCreatePayoutDTO,
  OrganizationDTO,
  CreateOrganizationDTO,
  UpdateOrganizationDTO,
  LinkDTO,
  OrganizationLinksControllerGetInvitationLinkParams,
  SendInvitationDTO,
  ParticipantDTO,
  UpdateParticipantDTO,
  TagDTO,
  OrganizationByInvitationDTO,
  GetMagicLinkDTO,
  TransactionPostingDTO,
  CreateOrganizationWalletDTO,
  UpdateOrganizationWalletDTO,
  OrgWalletControllerGetTransactionsCSVParams,
  OrgTransactionResponseDTO,
  OrgWalletControllerGetTransactionsParams,
  OrganizationQRDTO,
  CreateOrganizationQRDTO,
  UpdateOrganizationQRDTO,
  PaymentRuletDTO,
  SetPaymentRuleDTO,
  PageQRDTO,
  PageOrganizationDTO,
  PageParticipantDTO,
  SendReceiptDTO,
  IntentDTO,
  CreateIntentDTO,
  FeedbackDTO,
  CreateFeedbackDTO
} from './models'



export const userControllerMe = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserDTO>> => {
    return axios.get(
      `/user/me`,options
    );
  }


export const getUserControllerMeQueryKey = () => [`/user/me`] as const;
  

    
export const getUserControllerMeQueryOptions = <TData = Awaited<ReturnType<typeof userControllerMe>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerMe>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof userControllerMe>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerMeQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerMe>>> = ({ signal }) => userControllerMe({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type UserControllerMeQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerMe>>>
export type UserControllerMeQueryError = AxiosError<unknown>

export const useUserControllerMe = <TData = Awaited<ReturnType<typeof userControllerMe>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerMe>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const userControllerUpdate = (
    updateUserDTO: UpdateUserDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UpdateUserDTO>> => {
    return axios.patch(
      `/user`,
      updateUserDTO,options
    );
  }



export const getUserControllerUpdateMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdate>>, TError,{data: UpdateUserDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdate>>, TError,{data: UpdateUserDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerUpdate>>, {data: UpdateUserDTO}> = (props) => {
          const {data} = props ?? {};

          return  userControllerUpdate(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerUpdate>>>
    export type UserControllerUpdateMutationBody = UpdateUserDTO
    export type UserControllerUpdateMutationError = AxiosError<unknown>

    export const useUserControllerUpdate = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdate>>, TError,{data: UpdateUserDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUserControllerUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const userControllerUploadAvatar = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.post(
      `/user/upload`,undefined,options
    );
  }



export const getUserControllerUploadAvatarMutationOptions = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUploadAvatar>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerUploadAvatar>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerUploadAvatar>>, TVariables> = () => {
          

          return  userControllerUploadAvatar(axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserControllerUploadAvatarMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerUploadAvatar>>>
    
    export type UserControllerUploadAvatarMutationError = AxiosError<unknown>

    export const useUserControllerUploadAvatar = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUploadAvatar>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUserControllerUploadAvatarMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const userWalletControllerGetWallet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WalletDTO[]>> => {
    return axios.get(
      `/wallet`,options
    );
  }


export const getUserWalletControllerGetWalletQueryKey = () => [`/wallet`] as const;
  

    
export const getUserWalletControllerGetWalletQueryOptions = <TData = Awaited<ReturnType<typeof userWalletControllerGetWallet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userWalletControllerGetWallet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof userWalletControllerGetWallet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserWalletControllerGetWalletQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userWalletControllerGetWallet>>> = ({ signal }) => userWalletControllerGetWallet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type UserWalletControllerGetWalletQueryResult = NonNullable<Awaited<ReturnType<typeof userWalletControllerGetWallet>>>
export type UserWalletControllerGetWalletQueryError = AxiosError<unknown>

export const useUserWalletControllerGetWallet = <TData = Awaited<ReturnType<typeof userWalletControllerGetWallet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userWalletControllerGetWallet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserWalletControllerGetWalletQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const userWalletControllerGetTransactions = (
    params: UserWalletControllerGetTransactionsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TransactionResponseDTO>> => {
    return axios.get(
      `/wallet/transactions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getUserWalletControllerGetTransactionsQueryKey = (params: UserWalletControllerGetTransactionsParams,) => [`/wallet/transactions`, ...(params ? [params]: [])] as const;
  

    
export const getUserWalletControllerGetTransactionsQueryOptions = <TData = Awaited<ReturnType<typeof userWalletControllerGetTransactions>>, TError = AxiosError<unknown>>(params: UserWalletControllerGetTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userWalletControllerGetTransactions>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof userWalletControllerGetTransactions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserWalletControllerGetTransactionsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userWalletControllerGetTransactions>>> = ({ signal }) => userWalletControllerGetTransactions(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type UserWalletControllerGetTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof userWalletControllerGetTransactions>>>
export type UserWalletControllerGetTransactionsQueryError = AxiosError<unknown>

export const useUserWalletControllerGetTransactions = <TData = Awaited<ReturnType<typeof userWalletControllerGetTransactions>>, TError = AxiosError<unknown>>(
 params: UserWalletControllerGetTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userWalletControllerGetTransactions>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserWalletControllerGetTransactionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const userPartControllerMyParticipants = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserParticipantDTO[]>> => {
    return axios.get(
      `/user/participant`,options
    );
  }


export const getUserPartControllerMyParticipantsQueryKey = () => [`/user/participant`] as const;
  

    
export const getUserPartControllerMyParticipantsQueryOptions = <TData = Awaited<ReturnType<typeof userPartControllerMyParticipants>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userPartControllerMyParticipants>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof userPartControllerMyParticipants>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserPartControllerMyParticipantsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userPartControllerMyParticipants>>> = ({ signal }) => userPartControllerMyParticipants({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type UserPartControllerMyParticipantsQueryResult = NonNullable<Awaited<ReturnType<typeof userPartControllerMyParticipants>>>
export type UserPartControllerMyParticipantsQueryError = AxiosError<unknown>

export const useUserPartControllerMyParticipants = <TData = Awaited<ReturnType<typeof userPartControllerMyParticipants>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userPartControllerMyParticipants>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserPartControllerMyParticipantsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const userKYCControllerGetChallenges = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Kycdto[]>> => {
    return axios.get(
      `/user/kyc`,options
    );
  }


export const getUserKYCControllerGetChallengesQueryKey = () => [`/user/kyc`] as const;
  

    
export const getUserKYCControllerGetChallengesQueryOptions = <TData = Awaited<ReturnType<typeof userKYCControllerGetChallenges>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userKYCControllerGetChallenges>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof userKYCControllerGetChallenges>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserKYCControllerGetChallengesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userKYCControllerGetChallenges>>> = ({ signal }) => userKYCControllerGetChallenges({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type UserKYCControllerGetChallengesQueryResult = NonNullable<Awaited<ReturnType<typeof userKYCControllerGetChallenges>>>
export type UserKYCControllerGetChallengesQueryError = AxiosError<unknown>

export const useUserKYCControllerGetChallenges = <TData = Awaited<ReturnType<typeof userKYCControllerGetChallenges>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userKYCControllerGetChallenges>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserKYCControllerGetChallengesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const userKYCControllerChallengeKyc = (
    challengeId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Kycdto>> => {
    return axios.post(
      `/user/kyc/${challengeId}`,undefined,options
    );
  }



export const getUserKYCControllerChallengeKycMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userKYCControllerChallengeKyc>>, TError,{challengeId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof userKYCControllerChallengeKyc>>, TError,{challengeId: number}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userKYCControllerChallengeKyc>>, {challengeId: number}> = (props) => {
          const {challengeId} = props ?? {};

          return  userKYCControllerChallengeKyc(challengeId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserKYCControllerChallengeKycMutationResult = NonNullable<Awaited<ReturnType<typeof userKYCControllerChallengeKyc>>>
    
    export type UserKYCControllerChallengeKycMutationError = AxiosError<unknown>

    export const useUserKYCControllerChallengeKyc = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userKYCControllerChallengeKyc>>, TError,{challengeId: number}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUserKYCControllerChallengeKycMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const userBeneficiaryControllerUpdateBeneficiary = (
    updateBeneficiaryDTO: UpdateBeneficiaryDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BeneficiaryDTO>> => {
    return axios.patch(
      `/user/beneficiary`,
      updateBeneficiaryDTO,options
    );
  }



export const getUserBeneficiaryControllerUpdateBeneficiaryMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBeneficiaryControllerUpdateBeneficiary>>, TError,{data: UpdateBeneficiaryDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof userBeneficiaryControllerUpdateBeneficiary>>, TError,{data: UpdateBeneficiaryDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userBeneficiaryControllerUpdateBeneficiary>>, {data: UpdateBeneficiaryDTO}> = (props) => {
          const {data} = props ?? {};

          return  userBeneficiaryControllerUpdateBeneficiary(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserBeneficiaryControllerUpdateBeneficiaryMutationResult = NonNullable<Awaited<ReturnType<typeof userBeneficiaryControllerUpdateBeneficiary>>>
    export type UserBeneficiaryControllerUpdateBeneficiaryMutationBody = UpdateBeneficiaryDTO
    export type UserBeneficiaryControllerUpdateBeneficiaryMutationError = AxiosError<unknown>

    export const useUserBeneficiaryControllerUpdateBeneficiary = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBeneficiaryControllerUpdateBeneficiary>>, TError,{data: UpdateBeneficiaryDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUserBeneficiaryControllerUpdateBeneficiaryMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const userBeneficiaryControllerUploadDocument = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.post(
      `/user/beneficiary/upload`,undefined,options
    );
  }



export const getUserBeneficiaryControllerUploadDocumentMutationOptions = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBeneficiaryControllerUploadDocument>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof userBeneficiaryControllerUploadDocument>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userBeneficiaryControllerUploadDocument>>, TVariables> = () => {
          

          return  userBeneficiaryControllerUploadDocument(axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserBeneficiaryControllerUploadDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof userBeneficiaryControllerUploadDocument>>>
    
    export type UserBeneficiaryControllerUploadDocumentMutationError = AxiosError<unknown>

    export const useUserBeneficiaryControllerUploadDocument = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBeneficiaryControllerUploadDocument>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUserBeneficiaryControllerUploadDocumentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const userPayoutControllerGetPayout = (
    payoutId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserPayoutDTO>> => {
    return axios.get(
      `/user/payout/${payoutId}`,options
    );
  }


export const getUserPayoutControllerGetPayoutQueryKey = (payoutId: string,) => [`/user/payout/${payoutId}`] as const;
  

    
export const getUserPayoutControllerGetPayoutQueryOptions = <TData = Awaited<ReturnType<typeof userPayoutControllerGetPayout>>, TError = AxiosError<unknown>>(payoutId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userPayoutControllerGetPayout>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof userPayoutControllerGetPayout>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserPayoutControllerGetPayoutQueryKey(payoutId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userPayoutControllerGetPayout>>> = ({ signal }) => userPayoutControllerGetPayout(payoutId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(payoutId), ...queryOptions}}

export type UserPayoutControllerGetPayoutQueryResult = NonNullable<Awaited<ReturnType<typeof userPayoutControllerGetPayout>>>
export type UserPayoutControllerGetPayoutQueryError = AxiosError<unknown>

export const useUserPayoutControllerGetPayout = <TData = Awaited<ReturnType<typeof userPayoutControllerGetPayout>>, TError = AxiosError<unknown>>(
 payoutId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userPayoutControllerGetPayout>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserPayoutControllerGetPayoutQueryOptions(payoutId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const userPayoutControllerCreatePayout = (
    userCreatePayoutDTO: UserCreatePayoutDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserPayoutDTO>> => {
    return axios.post(
      `/user/payout`,
      userCreatePayoutDTO,options
    );
  }



export const getUserPayoutControllerCreatePayoutMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userPayoutControllerCreatePayout>>, TError,{data: UserCreatePayoutDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof userPayoutControllerCreatePayout>>, TError,{data: UserCreatePayoutDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userPayoutControllerCreatePayout>>, {data: UserCreatePayoutDTO}> = (props) => {
          const {data} = props ?? {};

          return  userPayoutControllerCreatePayout(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserPayoutControllerCreatePayoutMutationResult = NonNullable<Awaited<ReturnType<typeof userPayoutControllerCreatePayout>>>
    export type UserPayoutControllerCreatePayoutMutationBody = UserCreatePayoutDTO
    export type UserPayoutControllerCreatePayoutMutationError = AxiosError<unknown>

    export const useUserPayoutControllerCreatePayout = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userPayoutControllerCreatePayout>>, TError,{data: UserCreatePayoutDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUserPayoutControllerCreatePayoutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const userPayoutControllerConfirmPayout = (
    payoutId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserPayoutDTO>> => {
    return axios.patch(
      `/user/payout/${payoutId}/confirm`,undefined,options
    );
  }



export const getUserPayoutControllerConfirmPayoutMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userPayoutControllerConfirmPayout>>, TError,{payoutId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof userPayoutControllerConfirmPayout>>, TError,{payoutId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userPayoutControllerConfirmPayout>>, {payoutId: string}> = (props) => {
          const {payoutId} = props ?? {};

          return  userPayoutControllerConfirmPayout(payoutId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserPayoutControllerConfirmPayoutMutationResult = NonNullable<Awaited<ReturnType<typeof userPayoutControllerConfirmPayout>>>
    
    export type UserPayoutControllerConfirmPayoutMutationError = AxiosError<unknown>

    export const useUserPayoutControllerConfirmPayout = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userPayoutControllerConfirmPayout>>, TError,{payoutId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUserPayoutControllerConfirmPayoutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const userPayoutControllerCancelPayout = (
    payoutId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.patch(
      `/user/payout/${payoutId}/cancel`,undefined,options
    );
  }



export const getUserPayoutControllerCancelPayoutMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userPayoutControllerCancelPayout>>, TError,{payoutId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof userPayoutControllerCancelPayout>>, TError,{payoutId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userPayoutControllerCancelPayout>>, {payoutId: string}> = (props) => {
          const {payoutId} = props ?? {};

          return  userPayoutControllerCancelPayout(payoutId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserPayoutControllerCancelPayoutMutationResult = NonNullable<Awaited<ReturnType<typeof userPayoutControllerCancelPayout>>>
    
    export type UserPayoutControllerCancelPayoutMutationError = AxiosError<unknown>

    export const useUserPayoutControllerCancelPayout = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userPayoutControllerCancelPayout>>, TError,{payoutId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUserPayoutControllerCancelPayoutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const organizationControllerList = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrganizationDTO[]>> => {
    return axios.get(
      `/organization`,options
    );
  }


export const getOrganizationControllerListQueryKey = () => [`/organization`] as const;
  

    
export const getOrganizationControllerListQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerList>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerList>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof organizationControllerList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerList>>> = ({ signal }) => organizationControllerList({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type OrganizationControllerListQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerList>>>
export type OrganizationControllerListQueryError = AxiosError<unknown>

export const useOrganizationControllerList = <TData = Awaited<ReturnType<typeof organizationControllerList>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const organizationControllerCreate = (
    createOrganizationDTO: CreateOrganizationDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrganizationDTO>> => {
    return axios.post(
      `/organization`,
      createOrganizationDTO,options
    );
  }



export const getOrganizationControllerCreateMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerCreate>>, TError,{data: CreateOrganizationDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof organizationControllerCreate>>, TError,{data: CreateOrganizationDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerCreate>>, {data: CreateOrganizationDTO}> = (props) => {
          const {data} = props ?? {};

          return  organizationControllerCreate(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrganizationControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerCreate>>>
    export type OrganizationControllerCreateMutationBody = CreateOrganizationDTO
    export type OrganizationControllerCreateMutationError = AxiosError<unknown>

    export const useOrganizationControllerCreate = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerCreate>>, TError,{data: CreateOrganizationDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrganizationControllerCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const organizationControllerFindOne = (
    organizationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrganizationDTO>> => {
    return axios.get(
      `/organization/${organizationId}`,options
    );
  }


export const getOrganizationControllerFindOneQueryKey = (organizationId: string,) => [`/organization/${organizationId}`] as const;
  

    
export const getOrganizationControllerFindOneQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerFindOne>>, TError = AxiosError<unknown>>(organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerFindOne>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof organizationControllerFindOne>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerFindOneQueryKey(organizationId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerFindOne>>> = ({ signal }) => organizationControllerFindOne(organizationId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions}}

export type OrganizationControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerFindOne>>>
export type OrganizationControllerFindOneQueryError = AxiosError<unknown>

export const useOrganizationControllerFindOne = <TData = Awaited<ReturnType<typeof organizationControllerFindOne>>, TError = AxiosError<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerFindOne>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerFindOneQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const organizationControllerUpdate = (
    organizationId: string,
    updateOrganizationDTO: UpdateOrganizationDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrganizationDTO>> => {
    return axios.patch(
      `/organization/${organizationId}`,
      updateOrganizationDTO,options
    );
  }



export const getOrganizationControllerUpdateMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdate>>, TError,{organizationId: string;data: UpdateOrganizationDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdate>>, TError,{organizationId: string;data: UpdateOrganizationDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerUpdate>>, {organizationId: string;data: UpdateOrganizationDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  organizationControllerUpdate(organizationId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrganizationControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerUpdate>>>
    export type OrganizationControllerUpdateMutationBody = UpdateOrganizationDTO
    export type OrganizationControllerUpdateMutationError = AxiosError<unknown>

    export const useOrganizationControllerUpdate = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdate>>, TError,{organizationId: string;data: UpdateOrganizationDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrganizationControllerUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const organizationControllerDelete = (
    organizationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.delete(
      `/organization/${organizationId}`,options
    );
  }



export const getOrganizationControllerDeleteMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerDelete>>, TError,{organizationId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof organizationControllerDelete>>, TError,{organizationId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerDelete>>, {organizationId: string}> = (props) => {
          const {organizationId} = props ?? {};

          return  organizationControllerDelete(organizationId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrganizationControllerDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerDelete>>>
    
    export type OrganizationControllerDeleteMutationError = AxiosError<unknown>

    export const useOrganizationControllerDelete = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerDelete>>, TError,{organizationId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrganizationControllerDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const organizationControllerUploadAvatar = (
    organizationId: string,
    type: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.post(
      `/organization/${organizationId}/upload/${type}`,undefined,options
    );
  }



export const getOrganizationControllerUploadAvatarMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUploadAvatar>>, TError,{organizationId: string;type: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUploadAvatar>>, TError,{organizationId: string;type: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerUploadAvatar>>, {organizationId: string;type: string}> = (props) => {
          const {organizationId,type} = props ?? {};

          return  organizationControllerUploadAvatar(organizationId,type,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrganizationControllerUploadAvatarMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerUploadAvatar>>>
    
    export type OrganizationControllerUploadAvatarMutationError = AxiosError<unknown>

    export const useOrganizationControllerUploadAvatar = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUploadAvatar>>, TError,{organizationId: string;type: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrganizationControllerUploadAvatarMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const organizationLinksControllerGetInvitationLink = (
    organizationId: string,
    params: OrganizationLinksControllerGetInvitationLinkParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LinkDTO>> => {
    return axios.get(
      `/organization/${organizationId}/link`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getOrganizationLinksControllerGetInvitationLinkQueryKey = (organizationId: string,
    params: OrganizationLinksControllerGetInvitationLinkParams,) => [`/organization/${organizationId}/link`, ...(params ? [params]: [])] as const;
  

    
export const getOrganizationLinksControllerGetInvitationLinkQueryOptions = <TData = Awaited<ReturnType<typeof organizationLinksControllerGetInvitationLink>>, TError = AxiosError<unknown>>(organizationId: string,
    params: OrganizationLinksControllerGetInvitationLinkParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationLinksControllerGetInvitationLink>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof organizationLinksControllerGetInvitationLink>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationLinksControllerGetInvitationLinkQueryKey(organizationId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationLinksControllerGetInvitationLink>>> = ({ signal }) => organizationLinksControllerGetInvitationLink(organizationId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions}}

export type OrganizationLinksControllerGetInvitationLinkQueryResult = NonNullable<Awaited<ReturnType<typeof organizationLinksControllerGetInvitationLink>>>
export type OrganizationLinksControllerGetInvitationLinkQueryError = AxiosError<unknown>

export const useOrganizationLinksControllerGetInvitationLink = <TData = Awaited<ReturnType<typeof organizationLinksControllerGetInvitationLink>>, TError = AxiosError<unknown>>(
 organizationId: string,
    params: OrganizationLinksControllerGetInvitationLinkParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationLinksControllerGetInvitationLink>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationLinksControllerGetInvitationLinkQueryOptions(organizationId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const organizationLinksControllerSendInvitations = (
    organizationId: string,
    sendInvitationDTO: SendInvitationDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.post(
      `/organization/${organizationId}/link`,
      sendInvitationDTO,options
    );
  }



export const getOrganizationLinksControllerSendInvitationsMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationLinksControllerSendInvitations>>, TError,{organizationId: string;data: SendInvitationDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof organizationLinksControllerSendInvitations>>, TError,{organizationId: string;data: SendInvitationDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationLinksControllerSendInvitations>>, {organizationId: string;data: SendInvitationDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  organizationLinksControllerSendInvitations(organizationId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrganizationLinksControllerSendInvitationsMutationResult = NonNullable<Awaited<ReturnType<typeof organizationLinksControllerSendInvitations>>>
    export type OrganizationLinksControllerSendInvitationsMutationBody = SendInvitationDTO
    export type OrganizationLinksControllerSendInvitationsMutationError = AxiosError<unknown>

    export const useOrganizationLinksControllerSendInvitations = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationLinksControllerSendInvitations>>, TError,{organizationId: string;data: SendInvitationDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrganizationLinksControllerSendInvitationsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const organizationParticipantsControllerFindAll = (
    organizationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ParticipantDTO[]>> => {
    return axios.get(
      `/organization/${organizationId}/participant`,options
    );
  }


export const getOrganizationParticipantsControllerFindAllQueryKey = (organizationId: string,) => [`/organization/${organizationId}/participant`] as const;
  

    
export const getOrganizationParticipantsControllerFindAllQueryOptions = <TData = Awaited<ReturnType<typeof organizationParticipantsControllerFindAll>>, TError = AxiosError<unknown>>(organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationParticipantsControllerFindAll>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof organizationParticipantsControllerFindAll>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationParticipantsControllerFindAllQueryKey(organizationId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationParticipantsControllerFindAll>>> = ({ signal }) => organizationParticipantsControllerFindAll(organizationId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions}}

export type OrganizationParticipantsControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof organizationParticipantsControllerFindAll>>>
export type OrganizationParticipantsControllerFindAllQueryError = AxiosError<unknown>

export const useOrganizationParticipantsControllerFindAll = <TData = Awaited<ReturnType<typeof organizationParticipantsControllerFindAll>>, TError = AxiosError<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationParticipantsControllerFindAll>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationParticipantsControllerFindAllQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const organizationParticipantsControllerUpdate = (
    organizationId: string,
    participantId: string,
    updateParticipantDTO: UpdateParticipantDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ParticipantDTO>> => {
    return axios.patch(
      `/organization/${organizationId}/participant/${participantId}`,
      updateParticipantDTO,options
    );
  }



export const getOrganizationParticipantsControllerUpdateMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationParticipantsControllerUpdate>>, TError,{organizationId: string;participantId: string;data: UpdateParticipantDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof organizationParticipantsControllerUpdate>>, TError,{organizationId: string;participantId: string;data: UpdateParticipantDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationParticipantsControllerUpdate>>, {organizationId: string;participantId: string;data: UpdateParticipantDTO}> = (props) => {
          const {organizationId,participantId,data} = props ?? {};

          return  organizationParticipantsControllerUpdate(organizationId,participantId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrganizationParticipantsControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof organizationParticipantsControllerUpdate>>>
    export type OrganizationParticipantsControllerUpdateMutationBody = UpdateParticipantDTO
    export type OrganizationParticipantsControllerUpdateMutationError = AxiosError<unknown>

    export const useOrganizationParticipantsControllerUpdate = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationParticipantsControllerUpdate>>, TError,{organizationId: string;participantId: string;data: UpdateParticipantDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrganizationParticipantsControllerUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const organizationParticipantsControllerDelete = (
    organizationId: string,
    participantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.delete(
      `/organization/${organizationId}/participant/${participantId}`,options
    );
  }



export const getOrganizationParticipantsControllerDeleteMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationParticipantsControllerDelete>>, TError,{organizationId: string;participantId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof organizationParticipantsControllerDelete>>, TError,{organizationId: string;participantId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationParticipantsControllerDelete>>, {organizationId: string;participantId: string}> = (props) => {
          const {organizationId,participantId} = props ?? {};

          return  organizationParticipantsControllerDelete(organizationId,participantId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrganizationParticipantsControllerDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof organizationParticipantsControllerDelete>>>
    
    export type OrganizationParticipantsControllerDeleteMutationError = AxiosError<unknown>

    export const useOrganizationParticipantsControllerDelete = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationParticipantsControllerDelete>>, TError,{organizationId: string;participantId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrganizationParticipantsControllerDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const organizationTagsControllerGetTags = (
    organizationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TagDTO>> => {
    return axios.get(
      `/organization/${organizationId}/tag`,options
    );
  }


export const getOrganizationTagsControllerGetTagsQueryKey = (organizationId: string,) => [`/organization/${organizationId}/tag`] as const;
  

    
export const getOrganizationTagsControllerGetTagsQueryOptions = <TData = Awaited<ReturnType<typeof organizationTagsControllerGetTags>>, TError = AxiosError<unknown>>(organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationTagsControllerGetTags>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof organizationTagsControllerGetTags>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationTagsControllerGetTagsQueryKey(organizationId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationTagsControllerGetTags>>> = ({ signal }) => organizationTagsControllerGetTags(organizationId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions}}

export type OrganizationTagsControllerGetTagsQueryResult = NonNullable<Awaited<ReturnType<typeof organizationTagsControllerGetTags>>>
export type OrganizationTagsControllerGetTagsQueryError = AxiosError<unknown>

export const useOrganizationTagsControllerGetTags = <TData = Awaited<ReturnType<typeof organizationTagsControllerGetTags>>, TError = AxiosError<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationTagsControllerGetTags>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationTagsControllerGetTagsQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const organizationInviteControllerFindOrganization = (
    token: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrganizationByInvitationDTO>> => {
    return axios.get(
      `/invitation/${token}`,options
    );
  }


export const getOrganizationInviteControllerFindOrganizationQueryKey = (token: string,) => [`/invitation/${token}`] as const;
  

    
export const getOrganizationInviteControllerFindOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof organizationInviteControllerFindOrganization>>, TError = AxiosError<unknown>>(token: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationInviteControllerFindOrganization>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof organizationInviteControllerFindOrganization>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationInviteControllerFindOrganizationQueryKey(token);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationInviteControllerFindOrganization>>> = ({ signal }) => organizationInviteControllerFindOrganization(token, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(token), ...queryOptions}}

export type OrganizationInviteControllerFindOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof organizationInviteControllerFindOrganization>>>
export type OrganizationInviteControllerFindOrganizationQueryError = AxiosError<unknown>

export const useOrganizationInviteControllerFindOrganization = <TData = Awaited<ReturnType<typeof organizationInviteControllerFindOrganization>>, TError = AxiosError<unknown>>(
 token: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationInviteControllerFindOrganization>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationInviteControllerFindOrganizationQueryOptions(token,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const organizationInviteControllerAcceptInvitation = (
    token: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ParticipantDTO>> => {
    return axios.put(
      `/invitation/${token}`,undefined,options
    );
  }



export const getOrganizationInviteControllerAcceptInvitationMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationInviteControllerAcceptInvitation>>, TError,{token: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof organizationInviteControllerAcceptInvitation>>, TError,{token: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationInviteControllerAcceptInvitation>>, {token: string}> = (props) => {
          const {token} = props ?? {};

          return  organizationInviteControllerAcceptInvitation(token,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrganizationInviteControllerAcceptInvitationMutationResult = NonNullable<Awaited<ReturnType<typeof organizationInviteControllerAcceptInvitation>>>
    
    export type OrganizationInviteControllerAcceptInvitationMutationError = AxiosError<unknown>

    export const useOrganizationInviteControllerAcceptInvitation = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationInviteControllerAcceptInvitation>>, TError,{token: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrganizationInviteControllerAcceptInvitationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const organizationInviteControllerGenerateMagicLink = (
    token: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetMagicLinkDTO>> => {
    return axios.get(
      `/invitation/${token}/magic-link`,options
    );
  }


export const getOrganizationInviteControllerGenerateMagicLinkQueryKey = (token: string,) => [`/invitation/${token}/magic-link`] as const;
  

    
export const getOrganizationInviteControllerGenerateMagicLinkQueryOptions = <TData = Awaited<ReturnType<typeof organizationInviteControllerGenerateMagicLink>>, TError = AxiosError<unknown>>(token: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationInviteControllerGenerateMagicLink>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof organizationInviteControllerGenerateMagicLink>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationInviteControllerGenerateMagicLinkQueryKey(token);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationInviteControllerGenerateMagicLink>>> = ({ signal }) => organizationInviteControllerGenerateMagicLink(token, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(token), ...queryOptions}}

export type OrganizationInviteControllerGenerateMagicLinkQueryResult = NonNullable<Awaited<ReturnType<typeof organizationInviteControllerGenerateMagicLink>>>
export type OrganizationInviteControllerGenerateMagicLinkQueryError = AxiosError<unknown>

export const useOrganizationInviteControllerGenerateMagicLink = <TData = Awaited<ReturnType<typeof organizationInviteControllerGenerateMagicLink>>, TError = AxiosError<unknown>>(
 token: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationInviteControllerGenerateMagicLink>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationInviteControllerGenerateMagicLinkQueryOptions(token,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const orgWalletControllerTransfer = (
    organizationId: string,
    transactionPostingDTO: TransactionPostingDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.post(
      `/organization/${organizationId}/wallet/transfer`,
      transactionPostingDTO,options
    );
  }



export const getOrgWalletControllerTransferMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerTransfer>>, TError,{organizationId: string;data: TransactionPostingDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerTransfer>>, TError,{organizationId: string;data: TransactionPostingDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgWalletControllerTransfer>>, {organizationId: string;data: TransactionPostingDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  orgWalletControllerTransfer(organizationId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrgWalletControllerTransferMutationResult = NonNullable<Awaited<ReturnType<typeof orgWalletControllerTransfer>>>
    export type OrgWalletControllerTransferMutationBody = TransactionPostingDTO
    export type OrgWalletControllerTransferMutationError = AxiosError<unknown>

    export const useOrgWalletControllerTransfer = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerTransfer>>, TError,{organizationId: string;data: TransactionPostingDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrgWalletControllerTransferMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const orgWalletControllerList = (
    organizationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WalletDTO[]>> => {
    return axios.get(
      `/organization/${organizationId}/wallet`,options
    );
  }


export const getOrgWalletControllerListQueryKey = (organizationId: string,) => [`/organization/${organizationId}/wallet`] as const;
  

    
export const getOrgWalletControllerListQueryOptions = <TData = Awaited<ReturnType<typeof orgWalletControllerList>>, TError = AxiosError<unknown>>(organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgWalletControllerList>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof orgWalletControllerList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgWalletControllerListQueryKey(organizationId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof orgWalletControllerList>>> = ({ signal }) => orgWalletControllerList(organizationId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions}}

export type OrgWalletControllerListQueryResult = NonNullable<Awaited<ReturnType<typeof orgWalletControllerList>>>
export type OrgWalletControllerListQueryError = AxiosError<unknown>

export const useOrgWalletControllerList = <TData = Awaited<ReturnType<typeof orgWalletControllerList>>, TError = AxiosError<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgWalletControllerList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrgWalletControllerListQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const orgWalletControllerCreate = (
    organizationId: string,
    createOrganizationWalletDTO: CreateOrganizationWalletDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WalletDTO[]>> => {
    return axios.post(
      `/organization/${organizationId}/wallet`,
      createOrganizationWalletDTO,options
    );
  }



export const getOrgWalletControllerCreateMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerCreate>>, TError,{organizationId: string;data: CreateOrganizationWalletDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerCreate>>, TError,{organizationId: string;data: CreateOrganizationWalletDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgWalletControllerCreate>>, {organizationId: string;data: CreateOrganizationWalletDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  orgWalletControllerCreate(organizationId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrgWalletControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgWalletControllerCreate>>>
    export type OrgWalletControllerCreateMutationBody = CreateOrganizationWalletDTO
    export type OrgWalletControllerCreateMutationError = AxiosError<unknown>

    export const useOrgWalletControllerCreate = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerCreate>>, TError,{organizationId: string;data: CreateOrganizationWalletDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrgWalletControllerCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const orgWalletControllerUpdate = (
    organizationId: string,
    walletId: string,
    updateOrganizationWalletDTO: UpdateOrganizationWalletDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WalletDTO[]>> => {
    return axios.patch(
      `/organization/${organizationId}/wallet/${walletId}`,
      updateOrganizationWalletDTO,options
    );
  }



export const getOrgWalletControllerUpdateMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerUpdate>>, TError,{organizationId: string;walletId: string;data: UpdateOrganizationWalletDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerUpdate>>, TError,{organizationId: string;walletId: string;data: UpdateOrganizationWalletDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgWalletControllerUpdate>>, {organizationId: string;walletId: string;data: UpdateOrganizationWalletDTO}> = (props) => {
          const {organizationId,walletId,data} = props ?? {};

          return  orgWalletControllerUpdate(organizationId,walletId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrgWalletControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgWalletControllerUpdate>>>
    export type OrgWalletControllerUpdateMutationBody = UpdateOrganizationWalletDTO
    export type OrgWalletControllerUpdateMutationError = AxiosError<unknown>

    export const useOrgWalletControllerUpdate = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerUpdate>>, TError,{organizationId: string;walletId: string;data: UpdateOrganizationWalletDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrgWalletControllerUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const orgWalletControllerDelete = (
    organizationId: string,
    walletId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.delete(
      `/organization/${organizationId}/wallet/${walletId}`,options
    );
  }



export const getOrgWalletControllerDeleteMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerDelete>>, TError,{organizationId: string;walletId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerDelete>>, TError,{organizationId: string;walletId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgWalletControllerDelete>>, {organizationId: string;walletId: string}> = (props) => {
          const {organizationId,walletId} = props ?? {};

          return  orgWalletControllerDelete(organizationId,walletId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrgWalletControllerDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof orgWalletControllerDelete>>>
    
    export type OrgWalletControllerDeleteMutationError = AxiosError<unknown>

    export const useOrgWalletControllerDelete = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgWalletControllerDelete>>, TError,{organizationId: string;walletId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrgWalletControllerDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const orgWalletControllerGetTransactionsCSV = (
    organizationId: string,
    params?: OrgWalletControllerGetTransactionsCSVParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.get(
      `/organization/${organizationId}/wallet/transactions/csv`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getOrgWalletControllerGetTransactionsCSVQueryKey = (organizationId: string,
    params?: OrgWalletControllerGetTransactionsCSVParams,) => [`/organization/${organizationId}/wallet/transactions/csv`, ...(params ? [params]: [])] as const;
  

    
export const getOrgWalletControllerGetTransactionsCSVQueryOptions = <TData = Awaited<ReturnType<typeof orgWalletControllerGetTransactionsCSV>>, TError = AxiosError<unknown>>(organizationId: string,
    params?: OrgWalletControllerGetTransactionsCSVParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgWalletControllerGetTransactionsCSV>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof orgWalletControllerGetTransactionsCSV>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgWalletControllerGetTransactionsCSVQueryKey(organizationId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof orgWalletControllerGetTransactionsCSV>>> = ({ signal }) => orgWalletControllerGetTransactionsCSV(organizationId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions}}

export type OrgWalletControllerGetTransactionsCSVQueryResult = NonNullable<Awaited<ReturnType<typeof orgWalletControllerGetTransactionsCSV>>>
export type OrgWalletControllerGetTransactionsCSVQueryError = AxiosError<unknown>

export const useOrgWalletControllerGetTransactionsCSV = <TData = Awaited<ReturnType<typeof orgWalletControllerGetTransactionsCSV>>, TError = AxiosError<unknown>>(
 organizationId: string,
    params?: OrgWalletControllerGetTransactionsCSVParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgWalletControllerGetTransactionsCSV>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrgWalletControllerGetTransactionsCSVQueryOptions(organizationId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const orgWalletControllerGetTransactions = (
    organizationId: string,
    params: OrgWalletControllerGetTransactionsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrgTransactionResponseDTO>> => {
    return axios.get(
      `/organization/${organizationId}/wallet/transactions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getOrgWalletControllerGetTransactionsQueryKey = (organizationId: string,
    params: OrgWalletControllerGetTransactionsParams,) => [`/organization/${organizationId}/wallet/transactions`, ...(params ? [params]: [])] as const;
  

    
export const getOrgWalletControllerGetTransactionsQueryOptions = <TData = Awaited<ReturnType<typeof orgWalletControllerGetTransactions>>, TError = AxiosError<unknown>>(organizationId: string,
    params: OrgWalletControllerGetTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgWalletControllerGetTransactions>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof orgWalletControllerGetTransactions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgWalletControllerGetTransactionsQueryKey(organizationId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof orgWalletControllerGetTransactions>>> = ({ signal }) => orgWalletControllerGetTransactions(organizationId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions}}

export type OrgWalletControllerGetTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof orgWalletControllerGetTransactions>>>
export type OrgWalletControllerGetTransactionsQueryError = AxiosError<unknown>

export const useOrgWalletControllerGetTransactions = <TData = Awaited<ReturnType<typeof orgWalletControllerGetTransactions>>, TError = AxiosError<unknown>>(
 organizationId: string,
    params: OrgWalletControllerGetTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgWalletControllerGetTransactions>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrgWalletControllerGetTransactionsQueryOptions(organizationId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const orgQRControllerList = (
    organizationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrganizationQRDTO[]>> => {
    return axios.get(
      `/organization/${organizationId}/qr`,options
    );
  }


export const getOrgQRControllerListQueryKey = (organizationId: string,) => [`/organization/${organizationId}/qr`] as const;
  

    
export const getOrgQRControllerListQueryOptions = <TData = Awaited<ReturnType<typeof orgQRControllerList>>, TError = AxiosError<unknown>>(organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgQRControllerList>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof orgQRControllerList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgQRControllerListQueryKey(organizationId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof orgQRControllerList>>> = ({ signal }) => orgQRControllerList(organizationId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions}}

export type OrgQRControllerListQueryResult = NonNullable<Awaited<ReturnType<typeof orgQRControllerList>>>
export type OrgQRControllerListQueryError = AxiosError<unknown>

export const useOrgQRControllerList = <TData = Awaited<ReturnType<typeof orgQRControllerList>>, TError = AxiosError<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgQRControllerList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrgQRControllerListQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const orgQRControllerCreate = (
    organizationId: string,
    createOrganizationQRDTO: CreateOrganizationQRDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrganizationQRDTO[]>> => {
    return axios.post(
      `/organization/${organizationId}/qr`,
      createOrganizationQRDTO,options
    );
  }



export const getOrgQRControllerCreateMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgQRControllerCreate>>, TError,{organizationId: string;data: CreateOrganizationQRDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof orgQRControllerCreate>>, TError,{organizationId: string;data: CreateOrganizationQRDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgQRControllerCreate>>, {organizationId: string;data: CreateOrganizationQRDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  orgQRControllerCreate(organizationId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrgQRControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgQRControllerCreate>>>
    export type OrgQRControllerCreateMutationBody = CreateOrganizationQRDTO
    export type OrgQRControllerCreateMutationError = AxiosError<unknown>

    export const useOrgQRControllerCreate = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgQRControllerCreate>>, TError,{organizationId: string;data: CreateOrganizationQRDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrgQRControllerCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const orgQRControllerUpdate = (
    organizationId: string,
    qrId: string,
    updateOrganizationQRDTO: UpdateOrganizationQRDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrganizationQRDTO[]>> => {
    return axios.patch(
      `/organization/${organizationId}/qr/${qrId}`,
      updateOrganizationQRDTO,options
    );
  }



export const getOrgQRControllerUpdateMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgQRControllerUpdate>>, TError,{organizationId: string;qrId: string;data: UpdateOrganizationQRDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof orgQRControllerUpdate>>, TError,{organizationId: string;qrId: string;data: UpdateOrganizationQRDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgQRControllerUpdate>>, {organizationId: string;qrId: string;data: UpdateOrganizationQRDTO}> = (props) => {
          const {organizationId,qrId,data} = props ?? {};

          return  orgQRControllerUpdate(organizationId,qrId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrgQRControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgQRControllerUpdate>>>
    export type OrgQRControllerUpdateMutationBody = UpdateOrganizationQRDTO
    export type OrgQRControllerUpdateMutationError = AxiosError<unknown>

    export const useOrgQRControllerUpdate = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgQRControllerUpdate>>, TError,{organizationId: string;qrId: string;data: UpdateOrganizationQRDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrgQRControllerUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const orgQRControllerDelete = (
    organizationId: string,
    qrId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SuccessDTO>> => {
    return axios.delete(
      `/organization/${organizationId}/qr/${qrId}`,options
    );
  }



export const getOrgQRControllerDeleteMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgQRControllerDelete>>, TError,{organizationId: string;qrId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof orgQRControllerDelete>>, TError,{organizationId: string;qrId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgQRControllerDelete>>, {organizationId: string;qrId: string}> = (props) => {
          const {organizationId,qrId} = props ?? {};

          return  orgQRControllerDelete(organizationId,qrId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrgQRControllerDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof orgQRControllerDelete>>>
    
    export type OrgQRControllerDeleteMutationError = AxiosError<unknown>

    export const useOrgQRControllerDelete = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgQRControllerDelete>>, TError,{organizationId: string;qrId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrgQRControllerDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const orgPaymentRuleControllerFindOne = (
    organizationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaymentRuletDTO>> => {
    return axios.get(
      `/organization/${organizationId}/rule`,options
    );
  }


export const getOrgPaymentRuleControllerFindOneQueryKey = (organizationId: string,) => [`/organization/${organizationId}/rule`] as const;
  

    
export const getOrgPaymentRuleControllerFindOneQueryOptions = <TData = Awaited<ReturnType<typeof orgPaymentRuleControllerFindOne>>, TError = AxiosError<unknown>>(organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgPaymentRuleControllerFindOne>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof orgPaymentRuleControllerFindOne>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgPaymentRuleControllerFindOneQueryKey(organizationId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof orgPaymentRuleControllerFindOne>>> = ({ signal }) => orgPaymentRuleControllerFindOne(organizationId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions}}

export type OrgPaymentRuleControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof orgPaymentRuleControllerFindOne>>>
export type OrgPaymentRuleControllerFindOneQueryError = AxiosError<unknown>

export const useOrgPaymentRuleControllerFindOne = <TData = Awaited<ReturnType<typeof orgPaymentRuleControllerFindOne>>, TError = AxiosError<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgPaymentRuleControllerFindOne>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrgPaymentRuleControllerFindOneQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const orgPaymentRuleControllerUpdate = (
    organizationId: string,
    setPaymentRuleDTO: SetPaymentRuleDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaymentRuletDTO>> => {
    return axios.patch(
      `/organization/${organizationId}/rule`,
      setPaymentRuleDTO,options
    );
  }



export const getOrgPaymentRuleControllerUpdateMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgPaymentRuleControllerUpdate>>, TError,{organizationId: string;data: SetPaymentRuleDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof orgPaymentRuleControllerUpdate>>, TError,{organizationId: string;data: SetPaymentRuleDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgPaymentRuleControllerUpdate>>, {organizationId: string;data: SetPaymentRuleDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  orgPaymentRuleControllerUpdate(organizationId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OrgPaymentRuleControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgPaymentRuleControllerUpdate>>>
    export type OrgPaymentRuleControllerUpdateMutationBody = SetPaymentRuleDTO
    export type OrgPaymentRuleControllerUpdateMutationError = AxiosError<unknown>

    export const useOrgPaymentRuleControllerUpdate = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgPaymentRuleControllerUpdate>>, TError,{organizationId: string;data: SetPaymentRuleDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getOrgPaymentRuleControllerUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const pageControllerFindQR = (
    qrNumber: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PageQRDTO>> => {
    return axios.get(
      `/page/qr/${qrNumber}`,options
    );
  }


export const getPageControllerFindQRQueryKey = (qrNumber: number,) => [`/page/qr/${qrNumber}`] as const;
  

    
export const getPageControllerFindQRQueryOptions = <TData = Awaited<ReturnType<typeof pageControllerFindQR>>, TError = AxiosError<unknown>>(qrNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof pageControllerFindQR>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof pageControllerFindQR>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPageControllerFindQRQueryKey(qrNumber);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof pageControllerFindQR>>> = ({ signal }) => pageControllerFindQR(qrNumber, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(qrNumber), ...queryOptions}}

export type PageControllerFindQRQueryResult = NonNullable<Awaited<ReturnType<typeof pageControllerFindQR>>>
export type PageControllerFindQRQueryError = AxiosError<unknown>

export const usePageControllerFindQR = <TData = Awaited<ReturnType<typeof pageControllerFindQR>>, TError = AxiosError<unknown>>(
 qrNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof pageControllerFindQR>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPageControllerFindQRQueryOptions(qrNumber,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const pageControllerFindOrganization = (
    organizationNumber: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PageOrganizationDTO>> => {
    return axios.get(
      `/page/organization/${organizationNumber}`,options
    );
  }


export const getPageControllerFindOrganizationQueryKey = (organizationNumber: number,) => [`/page/organization/${organizationNumber}`] as const;
  

    
export const getPageControllerFindOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof pageControllerFindOrganization>>, TError = AxiosError<unknown>>(organizationNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof pageControllerFindOrganization>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof pageControllerFindOrganization>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPageControllerFindOrganizationQueryKey(organizationNumber);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof pageControllerFindOrganization>>> = ({ signal }) => pageControllerFindOrganization(organizationNumber, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(organizationNumber), ...queryOptions}}

export type PageControllerFindOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof pageControllerFindOrganization>>>
export type PageControllerFindOrganizationQueryError = AxiosError<unknown>

export const usePageControllerFindOrganization = <TData = Awaited<ReturnType<typeof pageControllerFindOrganization>>, TError = AxiosError<unknown>>(
 organizationNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof pageControllerFindOrganization>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPageControllerFindOrganizationQueryOptions(organizationNumber,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const pageControllerFindParticipant = (
    participantNumber: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PageParticipantDTO>> => {
    return axios.get(
      `/page/participant/${participantNumber}`,options
    );
  }


export const getPageControllerFindParticipantQueryKey = (participantNumber: number,) => [`/page/participant/${participantNumber}`] as const;
  

    
export const getPageControllerFindParticipantQueryOptions = <TData = Awaited<ReturnType<typeof pageControllerFindParticipant>>, TError = AxiosError<unknown>>(participantNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof pageControllerFindParticipant>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof pageControllerFindParticipant>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPageControllerFindParticipantQueryKey(participantNumber);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof pageControllerFindParticipant>>> = ({ signal }) => pageControllerFindParticipant(participantNumber, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(participantNumber), ...queryOptions}}

export type PageControllerFindParticipantQueryResult = NonNullable<Awaited<ReturnType<typeof pageControllerFindParticipant>>>
export type PageControllerFindParticipantQueryError = AxiosError<unknown>

export const usePageControllerFindParticipant = <TData = Awaited<ReturnType<typeof pageControllerFindParticipant>>, TError = AxiosError<unknown>>(
 participantNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof pageControllerFindParticipant>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPageControllerFindParticipantQueryOptions(participantNumber,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const paymentControllerGetReceipt = (
    paymentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.get(
      `/payment/${paymentId}/receipt`,options
    );
  }


export const getPaymentControllerGetReceiptQueryKey = (paymentId: string,) => [`/payment/${paymentId}/receipt`] as const;
  

    
export const getPaymentControllerGetReceiptQueryOptions = <TData = Awaited<ReturnType<typeof paymentControllerGetReceipt>>, TError = AxiosError<unknown>>(paymentId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof paymentControllerGetReceipt>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof paymentControllerGetReceipt>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPaymentControllerGetReceiptQueryKey(paymentId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentControllerGetReceipt>>> = ({ signal }) => paymentControllerGetReceipt(paymentId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(paymentId), ...queryOptions}}

export type PaymentControllerGetReceiptQueryResult = NonNullable<Awaited<ReturnType<typeof paymentControllerGetReceipt>>>
export type PaymentControllerGetReceiptQueryError = AxiosError<unknown>

export const usePaymentControllerGetReceipt = <TData = Awaited<ReturnType<typeof paymentControllerGetReceipt>>, TError = AxiosError<unknown>>(
 paymentId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof paymentControllerGetReceipt>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPaymentControllerGetReceiptQueryOptions(paymentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const paymentControllerSendReceipt = (
    paymentId: string,
    sendReceiptDTO: SendReceiptDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.post(
      `/payment/${paymentId}/receipt`,
      sendReceiptDTO,options
    );
  }



export const getPaymentControllerSendReceiptMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof paymentControllerSendReceipt>>, TError,{paymentId: string;data: SendReceiptDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof paymentControllerSendReceipt>>, TError,{paymentId: string;data: SendReceiptDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof paymentControllerSendReceipt>>, {paymentId: string;data: SendReceiptDTO}> = (props) => {
          const {paymentId,data} = props ?? {};

          return  paymentControllerSendReceipt(paymentId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PaymentControllerSendReceiptMutationResult = NonNullable<Awaited<ReturnType<typeof paymentControllerSendReceipt>>>
    export type PaymentControllerSendReceiptMutationBody = SendReceiptDTO
    export type PaymentControllerSendReceiptMutationError = AxiosError<unknown>

    export const usePaymentControllerSendReceipt = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof paymentControllerSendReceipt>>, TError,{paymentId: string;data: SendReceiptDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPaymentControllerSendReceiptMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const paymentControllerCreateIntentByParticipant = (
    participantNumber: number,
    createIntentDTO: CreateIntentDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<IntentDTO>> => {
    return axios.post(
      `/payment/participant/${participantNumber}`,
      createIntentDTO,options
    );
  }



export const getPaymentControllerCreateIntentByParticipantMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof paymentControllerCreateIntentByParticipant>>, TError,{participantNumber: number;data: CreateIntentDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof paymentControllerCreateIntentByParticipant>>, TError,{participantNumber: number;data: CreateIntentDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof paymentControllerCreateIntentByParticipant>>, {participantNumber: number;data: CreateIntentDTO}> = (props) => {
          const {participantNumber,data} = props ?? {};

          return  paymentControllerCreateIntentByParticipant(participantNumber,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PaymentControllerCreateIntentByParticipantMutationResult = NonNullable<Awaited<ReturnType<typeof paymentControllerCreateIntentByParticipant>>>
    export type PaymentControllerCreateIntentByParticipantMutationBody = CreateIntentDTO
    export type PaymentControllerCreateIntentByParticipantMutationError = AxiosError<unknown>

    export const usePaymentControllerCreateIntentByParticipant = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof paymentControllerCreateIntentByParticipant>>, TError,{participantNumber: number;data: CreateIntentDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPaymentControllerCreateIntentByParticipantMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const paymentControllerCreateIntentByQr = (
    qrNumber: number,
    createIntentDTO: CreateIntentDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<IntentDTO>> => {
    return axios.post(
      `/payment/qr/${qrNumber}`,
      createIntentDTO,options
    );
  }



export const getPaymentControllerCreateIntentByQrMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof paymentControllerCreateIntentByQr>>, TError,{qrNumber: number;data: CreateIntentDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof paymentControllerCreateIntentByQr>>, TError,{qrNumber: number;data: CreateIntentDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof paymentControllerCreateIntentByQr>>, {qrNumber: number;data: CreateIntentDTO}> = (props) => {
          const {qrNumber,data} = props ?? {};

          return  paymentControllerCreateIntentByQr(qrNumber,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PaymentControllerCreateIntentByQrMutationResult = NonNullable<Awaited<ReturnType<typeof paymentControllerCreateIntentByQr>>>
    export type PaymentControllerCreateIntentByQrMutationBody = CreateIntentDTO
    export type PaymentControllerCreateIntentByQrMutationError = AxiosError<unknown>

    export const usePaymentControllerCreateIntentByQr = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof paymentControllerCreateIntentByQr>>, TError,{qrNumber: number;data: CreateIntentDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPaymentControllerCreateIntentByQrMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const paymentControllerCreateIntentByOrganization = (
    orgNumber: number,
    createIntentDTO: CreateIntentDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<IntentDTO>> => {
    return axios.post(
      `/payment/organization/${orgNumber}`,
      createIntentDTO,options
    );
  }



export const getPaymentControllerCreateIntentByOrganizationMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof paymentControllerCreateIntentByOrganization>>, TError,{orgNumber: number;data: CreateIntentDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof paymentControllerCreateIntentByOrganization>>, TError,{orgNumber: number;data: CreateIntentDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof paymentControllerCreateIntentByOrganization>>, {orgNumber: number;data: CreateIntentDTO}> = (props) => {
          const {orgNumber,data} = props ?? {};

          return  paymentControllerCreateIntentByOrganization(orgNumber,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PaymentControllerCreateIntentByOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof paymentControllerCreateIntentByOrganization>>>
    export type PaymentControllerCreateIntentByOrganizationMutationBody = CreateIntentDTO
    export type PaymentControllerCreateIntentByOrganizationMutationError = AxiosError<unknown>

    export const usePaymentControllerCreateIntentByOrganization = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof paymentControllerCreateIntentByOrganization>>, TError,{orgNumber: number;data: CreateIntentDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPaymentControllerCreateIntentByOrganizationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const feedbackControllerCreateFeedback = (
    createFeedbackDTO: CreateFeedbackDTO, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FeedbackDTO>> => {
    return axios.post(
      `/feedback`,
      createFeedbackDTO,options
    );
  }



export const getFeedbackControllerCreateFeedbackMutationOptions = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof feedbackControllerCreateFeedback>>, TError,{data: CreateFeedbackDTO}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof feedbackControllerCreateFeedback>>, TError,{data: CreateFeedbackDTO}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof feedbackControllerCreateFeedback>>, {data: CreateFeedbackDTO}> = (props) => {
          const {data} = props ?? {};

          return  feedbackControllerCreateFeedback(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type FeedbackControllerCreateFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof feedbackControllerCreateFeedback>>>
    export type FeedbackControllerCreateFeedbackMutationBody = CreateFeedbackDTO
    export type FeedbackControllerCreateFeedbackMutationError = AxiosError<unknown>

    export const useFeedbackControllerCreateFeedback = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof feedbackControllerCreateFeedback>>, TError,{data: CreateFeedbackDTO}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getFeedbackControllerCreateFeedbackMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
