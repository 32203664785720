import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React from "react";

export function RuleChangeAlert({
  type,
  disclosure,
  onConsent,
}: {
  type: "discard" | "save";
  disclosure: UseDisclosureReturn;
  onConsent: () => void;
}) {
  const { isOpen, onClose } = disclosure;
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");

  return (
    <AlertDialog
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg={modalBg}>
          <AlertDialogHeader>
            {type === "discard" ? "Discard changes?" : "Save changes?"}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {type === "discard" && (
              <>Are you sure you want to discard changes?</>
            )}
            {type === "save" && (
              <>
                Are you sure you want to save changes?
                <br />
                New rules will be used for tips distribution immediately
              </>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              variant="no-hover"
              bg={bgButton}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onConsent();
                onClose();
              }}
              colorScheme={type === "discard" ? "red" : "blue"}
              me="14px"
            >
              {type === "discard" ? "Discard" : "Save"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
