// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
// Custom Components
// Assets
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";
import { useUserContext } from "utils/userContext";
import { UserDTO } from "api";
import { getName } from "utils/name";
import { Link as RouterLink, useLocation } from "react-router-dom";

function Greeting({ user }: { user: UserDTO | undefined }) {
  if (!user) {
    return <>👋&nbsp; Hey!</>;
  }

  return <>👋&nbsp; Hey, {getName(user)}</>;
}

export default function HeaderLinks(props: {
  unstyled?: boolean;
  secondary: boolean;
}) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");

  const user = useUserContext();
  const location = useLocation();
  const isAdmin = location.pathname.includes("/admin");

  async function onLogout() {
    await signOut();
    window.location.href = "/";
  }

  return (
    <Flex w="auto" alignItems="center" flexDirection="row">
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === "light" ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={getName(user)}
            src={user?.avatarImage ?? ""}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              <Greeting user={user} />
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              as={RouterLink}
              bg="none"
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
              to={isAdmin ? "/admin/profile" : "/waiter/profile"}
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem>
            <MenuItem
              onClick={onLogout}
              bg="none"
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
