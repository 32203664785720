/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

// Custom components
import Banner from "./components/Banner";
import { HSeparator } from "components/separator/Separator";

import { MdPeople, MdPayments, MdQrCode } from "react-icons/md";
import { useOrganizationControllerFindOne } from "api";
import { useHistory, useParams } from "react-router-dom";
import { DetailsTab } from "./components/DetailsTab";
import { ParticipantList } from "./participants";
import { OrganizationQrCode } from "./qr";
import { PaymentRules } from "./rules";
import { useSearchParams } from "utils/useSearchParams";

export default function OrganizationDetails() {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data: response } = useOrganizationControllerFindOne(organizationId);
  const searchParams = useSearchParams();
  const history = useHistory();
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const [tabIndex, setTabIndex] = useState(
    parseInt(searchParams.get("tab") || "0")
  );

  const onChangeTab = (tabIndex: number) => {
    setTabIndex(tabIndex);
    searchParams.set("tab", tabIndex.toString());
    history.push({
      search: searchParams.toString(),
    });
  }

  return (
    <Flex direction="column" width="100%">
      {/* Main Fields */}
      <Box mb="5px" display={{ base: "block", lg: "grid" }}>
        <Flex flexDirection="column">
          <Banner
            entityId={response?.data.id ?? ""}
            image={response?.data.coverImage}
            profile={response?.data.avatarImage ?? ""}
            address={response?.data.address ?? ""}
            name={response?.data.name ?? ""}
          />
        </Flex>
      </Box>
      <Tabs
        onChange={onChangeTab}
        defaultIndex={tabIndex}
        isFitted
        variant="soft-rounded"
        colorScheme="brandTabs"
        isLazy
      >
        <TabList
          mx={{ base: "10px", lg: "30px" }}
          // overflowX={{ sm: "scroll", lg: "unset" }}
        >
          <Flex justify="center" w="100%" flexWrap="wrap">
            <DetailsTab
              title={"Team"}
              isActive={tabIndex === 0}
              icon={MdPeople}
            />
            <DetailsTab
              title={"QR code"}
              isActive={tabIndex === 1}
              icon={MdQrCode}
            />
            <DetailsTab
              title={"Tips sharing"}
              isActive={tabIndex === 2}
              icon={MdPayments}
            />
          </Flex>
        </TabList>
        <HSeparator mb="10px" bg={paleGray} mt="0px" />
        <TabPanels>
          <TabPanel px="0px">
            {response?.data && (
              <ParticipantList
                organization={{ id: organizationId, name: response?.data.name }}
              />
            )}
          </TabPanel>
          <TabPanel px="0px">
            {response?.data && (
              <OrganizationQrCode organization={response.data} />
            )}
          </TabPanel>
          <TabPanel px="0px">
            {response?.data && <PaymentRules organization={response.data} />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
