import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Text,
  PropsOf,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Icon,
  useColorModeValue,
  Portal,
  useToast,
} from "@chakra-ui/react";
import {
  getUserControllerMeQueryKey,
  UserDTO,
  userKYCControllerChallengeKyc,
  useUserKYCControllerChallengeKyc,
} from "api";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useUserContext } from "utils/userContext";
import Card from "components/card/Card";
import { GiEuropeanFlag, GiWorld } from "react-icons/gi";
import { useSearchParams } from "utils/useSearchParams";
import { ImageUploaderModal } from "views/admin/main/organizations/details/components/ImageUploaderModal";
import { useQueryClient } from "@tanstack/react-query";

export function AdminStatus(props: PropsOf<typeof Alert>) {
  const navigate = useHistory();

  return (
    <Alert
      status="info"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="justify"
      {...props}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        You're almost set
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <Flex direction="column" gap={4} align="center">
          <Text>
            Welcome to GoTips. Let's start by creating a new restaurant team.
          </Text>
          <Button
            variant="brand"
            onClick={() => navigate.push("/admin/organizations")}
          >
            Go to restaurants
          </Button>
        </Flex>
      </AlertDescription>
    </Alert>
  );
}

export function WaiterStatus(props: PropsOf<typeof Alert>) {
  const user = useUserContext();

  return (
    <Alert
      status="info"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="justify"
      {...props}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        You're almost set
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <Text>
          Please ask your manager to invite you to restaurant's team using
          direct link or your email <b>{user?.email}</b>.
        </Text>
      </AlertDescription>
    </Alert>
  );
}

export function PayoutInformationDocumentRequired({
  user,
  ...props
}: PropsOf<typeof Alert> & {
  user: UserDTO;
}) {
  const [openedUploader, openUploader] = useState(false);
  const queryClient = useQueryClient();

  const invalidateUser = () => {
    queryClient.invalidateQueries(getUserControllerMeQueryKey());
  };

  return (
    <>
      <Alert
        status={user.beneficiary?.status === "DECLINED" ? "error" : "warning"}
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="justify"
        {...props}
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Bank account details update
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          <Flex direction="column" gap={4} align="center">
            {user.beneficiary?.status === "DECLINED" ? (
              <Text>
                Your document has been rejected, due to reason:{" "}
                {user.beneficiary.reason}. Please upload correct{" "}
                <b>IBAN certificate</b> or <b>Account confirmation</b> or
                another alternative document where account beneficiary and IBAN
                number can be identified. If you have questions or trouble with
                that, feel free to contact our support.
              </Text>
            ) : (
              <Text>
                We need to verify your bank account details, please upload{" "}
                <b>IBAN certificate</b> or <b>Account confirmation</b> or
                another alternative document where account beneficiary and IBAN
                number can be identified. If you have questions or trouble with
                that, feel free to contact our support.
              </Text>
            )}
            <Button variant="brand" onClick={() => openUploader(true)}>
              Upload document
            </Button>
          </Flex>
        </AlertDescription>
      </Alert>
      <ImageUploaderModal
        endpoint={`${process.env.REACT_APP_API_BASE_URL}/user/beneficiary/upload`}
        uppyUniqueToken="ibanDocument"
        open={openedUploader}
        onRequestClose={() => openUploader(false)}
        restrictions={{
          allowedFileTypes: [
            ".jpg",
            ".jpeg",
            ".png",
            ".pdf",
            "application/pdf",
          ],
        }}
        onComplete={invalidateUser}
      />
    </>
  );
}

export function DashboardWarning(props: PropsOf<typeof Alert>) {
  const navigate = useHistory();
  const location = useLocation();
  const isAdmin = location.pathname.includes("/admin");

  return (
    <Alert
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="justify"
      {...props}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        You have incomplete profile
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <Flex direction="column" gap={4} align="center">
          <Text>
            To start using our service, we just need you first and the last
            name.
          </Text>
          <Button
            variant="brand"
            onClick={() =>
              navigate.push(isAdmin ? "/admin/profile" : "/waiter/profile")
            }
          >
            Complete registration
          </Button>
        </Flex>
      </AlertDescription>
    </Alert>
  );
}

function KYCChallengeButton({ user }: { user: UserDTO }) {
  const textColorSecondary = useColorModeValue("secondaryGray.700", "white");
  const [popoverOpened, setPopoverOpened] = useState(false);
  const { mutate, isSuccess, data, isLoading } =
    useUserKYCControllerChallengeKyc();
  const toast = useToast();
  const params = useSearchParams();

  useEffect(() => {
    if (isSuccess && data.data.url) {
      window.location.href = data.data.url;
    }
  }, [isSuccess, data]);

  const euChallenge = user.kyc?.find(
    (c) => c.type === "ONBOARDING_EU" && c.status !== "PASSED"
  );

  const nonEuChallenge = user.kyc?.find(
    (c) => c.type === "ONBOARDING_NON_EU" && c.status !== "PASSED"
  );

  const euLink = euChallenge?.url;
  const nonEuLink = nonEuChallenge?.url;

  const isVerifying = params.has("from_kyc");

  const onError = () => {
    toast({
      title: `Something went wrong. Please reload page and try again.`,
      position: "top",
      status: "error",
      isClosable: true,
    });
  };

  const onEUClick = () => {
    if (euChallenge && !isLoading) {
      mutate({ challengeId: euChallenge.id }, { onError });
    }
  };

  const onNonEUClick = () => {
    if (nonEuChallenge && !isLoading) {
      mutate({ challengeId: nonEuChallenge.id }, { onError });
    }
  };

  return (
    <Popover
      isOpen={popoverOpened}
      onClose={() => setPopoverOpened(false)}
      placement="top"
    >
      <PopoverTrigger>
        <Button
          variant="brand"
          isDisabled={isLoading || isVerifying}
          onClick={() => {
            if (!user.kyc?.length) {
              return;
            }
            setPopoverOpened(true);
          }}
        >
          {isVerifying ? "Verifying..." : "Verify"}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">
            Are you EU-national?
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Flex direction="row" gap={2}>
              <Link
                {...(euLink ? { href: euLink } : undefined)}
                onClick={euLink ? undefined : onEUClick}
                flexBasis={0}
                flexGrow={1}
              >
                <Card>
                  <Icon
                    color={textColorSecondary}
                    alignSelf="center"
                    as={GiEuropeanFlag}
                    w="40px"
                    h="40px"
                  />
                  <Text align="center">
                    EU
                    <br />
                    national
                  </Text>
                </Card>
              </Link>
              <Link
                {...(nonEuLink ? { href: nonEuLink } : undefined)}
                onClick={nonEuLink ? undefined : onNonEUClick}
                flexBasis={0}
                flexGrow={1}
              >
                <Card>
                  <Icon
                    color={textColorSecondary}
                    alignSelf="center"
                    as={GiWorld}
                    w="35px"
                    h="35px"
                    mt="5px"
                  />
                  <Text align="center">
                    NON-EU
                    <br />
                    national
                  </Text>
                </Card>
              </Link>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export function AccountSuspendedWarning(
  props: PropsOf<typeof Alert> & {
    user: UserDTO;
  }
) {
  const { user, ...rest } = props;

  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="justify"
      {...rest}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Your account has been suspended
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <Flex direction="column" gap={4} align="center">
          {user.kyc?.find(
            (c) => c.status === "CREATED" || c.status === "PENDING"
          ) ? (
            <>
              <Text>
                You are required to verify your account with documents. Please
                proceed to verification clicking the button.
              </Text>
              <KYCChallengeButton user={user} />
            </>
          ) : (
            <>
              <Text>
                We have temporarily suspended your account, because of
                suspicious activity or violation of the rules. Please contact
                support by chat or by email{" "}
                <b>
                  <Link href="mailto:contact@gotips.co">contact@gotips.co</Link>
                </b>
              </Text>
            </>
          )}
        </Flex>
      </AlertDescription>
    </Alert>
  );
}

export function KYCRequiredWarning(
  props: PropsOf<typeof Alert> & {
    user: UserDTO;
  }
) {
  const { user, ...rest } = props;
  const navigate = useHistory();
  const location = useLocation();
  const isAdmin = location.pathname.includes("/admin");

  return (
    <Alert
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="justify"
      {...rest}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Verification required
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <Flex direction="column" gap={4} align="center">
          <Text>
            Congratulations on receiving first tips with us. To make payouts and
            tipping to work seamlessly, we need to verify your profile. Please
            proceed to verification process by clicking a button.
          </Text>
          <KYCChallengeButton user={user} />
        </Flex>
      </AlertDescription>
    </Alert>
  );
}
