import {
  UseDisclosureReturn,
  Modal,
  ModalOverlay,
  Slide,
  Box,
  ModalContent,
  useColorModeValue,
  Text,
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  Tab,
  Fade,
  SlideFade,
} from "@chakra-ui/react";
import { WalletDTO } from "api";
import { useRef, useState } from "react";
import { PayoutPage1 } from "./PayoutPage1";
import { PayoutPage2 } from "./PayoutPage2";

export function PayoutFundsModal({
  wallet,
  disclosure,
}: {
  wallet: WalletDTO;
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = useRef<HTMLInputElement>(null);
  const [payoutValue, setPayoutValue] = useState<number | undefined>();
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const [activePage, setActivePage] = useState<"AMOUNT" | "CONFIRMATION">(
    "AMOUNT"
  );

  const handleContinue = (amount: number) => {
    setPayoutValue(amount);
    setActivePage("CONFIRMATION");
  };

  const handleBack = () => {
    setActivePage("AMOUNT");
  };

  const handleClose = () => {
    onClose();
    setActivePage("AMOUNT");
  };

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        {isOpen && activePage === "AMOUNT" && (
          <PayoutPage1
            wallet={wallet}
            onClose={handleClose}
            onContinue={handleContinue}
          />
        )}
        {isOpen && activePage === "CONFIRMATION" && (
          <PayoutPage2
            wallet={wallet}
            amount={payoutValue ?? 0}
            onClose={handleClose}
            onBack={handleBack}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
