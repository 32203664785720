/* eslint-disable */

import {
  Avatar,
  Box,
  Flex,
  HStack,
  Stack,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
// Custom components
import * as React from "react";
// Assets

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { ParticipantDTO } from "api";
import { getName } from "utils/name";
import { ParticipantActionMenu } from "./ParticipantActionMenu";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { uniqueTags } from "utils/uniqueTags";

export function ParticipantsTable(props: {
  tableData: ParticipantDTO[];
  organization: {
    id: string;
    name: string;
  };
}) {
  const { tableData, organization } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const tagBg = useColorModeValue("brand.500", "brand.400");
  const session = useSessionContext();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");
  const columnHelper = createColumnHelper<ParticipantDTO>();
  const [data, setData] = React.useState(() => [...tableData]);

  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const actionColumn = React.useMemo(
    () =>
      columnHelper.display({
        size: 100,
        id: "actions",
        cell: (info) => (
          <Flex justifyContent="flex-end">
            <ParticipantActionMenu
              myParticipant={data.find(
                (p) =>
                  p.userId ===
                  (!session.loading && session.accessTokenPayload.mainUserId)
              )}
              participant={info.row.original}
              organization={{
                id: info.row.original.organizationId,
                name: organization.name,
              }}
            />
          </Flex>
        ),
      }),
    [data]
  );

  const columns = [
    columnHelper.display({
      id: "user",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          NAME
        </Text>
      ),
      cell: (info) => {
        const value = info.row.original;
        const name = getName(value.user);

        return (
          <Flex direction="column" gap={2}>
            <Flex align="center">
              <Avatar
                src={value.user.avatarImage ?? ""}
                name={name}
                h={{ base: "40px", md: "60px" }}
                w={{ base: "40px", md: "60px" }}
                me="10px"
              />
              <VStack align="flex-start">
                <Text color={textColor} fontSize="md" fontWeight="500">
                  {name}
                </Text>
                <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                  {value.role}
                </Text>
              </VStack>
            </Flex>

            <Flex
              paddingLeft={{ base: "0px", md: "67px" }}
              direction="row"
              wrap="wrap"
              gap={2}
              align="flex-start"
            >
              {uniqueTags(value.publicTags, value.privateTags).map((tag) => (
                <Tag
                  h="25px"
                  borderRadius="12px"
                  variant="solid"
                  key={tag}
                  bgColor={tagBg}
                  marginInlineStart={0}
                >
                  <TagLabel w="100%">{tag}</TagLabel>
                </Tag>
              ))}
            </Flex>
          </Flex>
        );
      },
    }),
    actionColumn,
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    manualPagination: true,
  });

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Table
        variant="simple"
        style={{ tableLayout: "fixed" }}
        color="gray.500"
        mb="24px"
      >
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    pe="10px"
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width:
                        header.getSize() !== 150 ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr px="20px" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor={borderColor}
                      style={{
                        width:
                          cell.column.getSize() !== 150
                            ? cell.column.getSize()
                            : undefined,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
        {table.getPageCount() === 0 && (
          <Text>No teammates yet. Invite first using links above.</Text>
        )}
      </Flex>
    </Flex>
  );
}
