import { useColorMode } from "@chakra-ui/react";
import { DashboardModal } from "@uppy/react";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";
import { DashboardModalProps } from "@uppy/react/src/DashboardModal";
import { createUppy, UppyInstanceOptions } from "utils/uppy";
import { useEffect, useState } from "react";

export function ImageUploaderModal(
  props: Pick<DashboardModalProps, "onRequestClose" | "open"> & {
    endpoint: string;
    uppyUniqueToken: string;
    meta?: Record<string, unknown>;
    restrictions?: UppyInstanceOptions['restrictions'];
    onComplete?: VoidFunction;
  }
) {
  const { colorMode } = useColorMode();
  const [uppy] = useState(() =>
    createUppy({
      id: props.uppyUniqueToken,
      endpoint: props.endpoint,
      restrictions: props.restrictions,
      meta: {
        uppyId: props.uppyUniqueToken,
        ...props.meta,
      }
    })
  );

  useEffect(() => {
    const completeCallback = () => {
      return props.onComplete?.();
    };

    uppy.once("complete", completeCallback);
    return () => {
      uppy.off("complete", completeCallback);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uppy]);

  return (
    <DashboardModal
      centerSingleFile={true}
      animateOpenClose={true}
      autoOpenFileEditor={true}
      closeAfterFinish={true}
      closeModalOnClickOutside={true}
      open={props.open}
      onRequestClose={props.onRequestClose}
      uppy={uppy}
      theme={colorMode}
      proudlyDisplayPoweredByUppy={false}
      plugins={["ImageEditor"]}
    />
  );
}
