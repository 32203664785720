import {
  Button,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useOrganizationControllerCreate,
  getOrganizationControllerListQueryKey,
  useOrganizationControllerUpdate,
  getOrganizationControllerFindOneQueryKey,
  getOrganizationParticipantsControllerFindAllQueryKey,
  getOrgPaymentRuleControllerFindOneQueryKey,
  getOrganizationTagsControllerGetTagsQueryKey,
} from "api";
import InputField from "components/fields/InputField";
import { useFormik } from "formik";
import React from "react";

export function CreateOrganizationModal({
  disclosure,
}: {
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLInputElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError } = useOrganizationControllerCreate();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values, helpers) => {
      mutate({
        data: values,
      });
    },
    validate: (values) => {
      if (!values.name) {
        return { name: "Restaurant name cannot be empty" };
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(getOrganizationControllerListQueryKey());
      formik.resetForm();
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Create restaurant</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              isRequired
              isInvalid={Boolean(formik.errors.name && formik.touched.name)}
            >
              <InputField
                label="Name"
                name="name"
                innerRef={initialRef}
                placeholder="Restaurant name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" mr={3} variant="brand" me="14px">
              Create
            </Button>
            <Button onClick={onClose} variant="no-hover" bg={bgButton}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export function EditOrganizationModal({
  organization,
  disclosure,
}: {
  organization: {
    id: string;
    name?: string;
    address?: string;
  };
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLInputElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError } = useOrganizationControllerUpdate();

  const formik = useFormik({
    initialValues: {
      name: organization.name,
      address: organization.address,
    },
    onSubmit: (values, helpers) => {
      mutate({
        organizationId: organization.id,
        data: values,
      });
    },
    validate: (values) => {
      if (!values.name) {
        return { name: "Restaurant name cannot be empty" };
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(getOrganizationControllerListQueryKey());
      queryClient.invalidateQueries(
        getOrganizationParticipantsControllerFindAllQueryKey(organization.id)
      );
      queryClient.invalidateQueries(
        getOrgPaymentRuleControllerFindOneQueryKey(organization.id)
      );
      queryClient.invalidateQueries(
        getOrganizationTagsControllerGetTagsQueryKey(organization.id)
      );
      queryClient.invalidateQueries(
        getOrganizationControllerFindOneQueryKey(organization.id)
      );
      formik.resetForm();
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError, queryClient, organization.id, onClose]);

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Edit restaurant</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              isRequired
              isInvalid={Boolean(formik.errors.name && formik.touched.name)}
            >
              <InputField
                label="Name"
                name="name"
                innerRef={initialRef}
                placeholder="Restaurant name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={Boolean(
                formik.errors.address && formik.touched.address
              )}
            >
              <InputField
                label="Address"
                name="address"
                placeholder="Restaurant address"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
              <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" mr={3} variant="brand" me="14px">
              Save
            </Button>
            <Button onClick={onClose} variant="no-hover" bg={bgButton}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
