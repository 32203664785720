import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  getOrganizationControllerListQueryKey,
  useOrganizationControllerDelete,
} from "api";
import React from "react";

export function OrganizationDeleteAlert({
  organization,
  disclosure,
}: {
  organization: {
    id: string;
    name: string;
  };
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError } = useOrganizationControllerDelete();

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(getOrganizationControllerListQueryKey());
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError, queryClient, organization.id, onClose]);

  return (
    <AlertDialog
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg={modalBg}>
          <AlertDialogHeader>Delete {organization.name}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to delete organization?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              variant="no-hover"
              bg={bgButton}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              onClick={() => mutate({ organizationId: organization.id })}
              colorScheme="red"
              me="14px"
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
