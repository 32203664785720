import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgComponent = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 68 68"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.6 34.4c1.7-1.1 3.8-1.2 5.6-.4-.1-.6-.1-1.2-.1-1.8 0-4.5 1.8-8.5 4.7-11.3 2.9-3 6.9-4.8 11.2-4.8 4.3 0 8.4 1.8 11.3 4.8 2.9 2.9 4.7 6.8 4.7 11.3 0 .5 0 1-.1 1.5 1.6-.4 3.3-.2 4.7.7.6.4 1 .8 1.5 1.3.2-1.2.3-2.4.3-3.6 0-6.2-2.5-11.8-6.5-15.9-4.1-4.1-9.6-6.6-15.8-6.6-6.2 0-11.8 2.5-15.9 6.6-4 4.1-6.5 9.7-6.5 15.9 0 1 .1 1.9.2 2.8.2-.2.5-.4.7-.5z" />
    <path
      fill="#4A22FE"
      d="M51.5 58.4c-.4 0-.8-.1-1.2-.3l-16.6-7.9-16.6 7.9c-.9.4-1.9.3-2.6-.2-.8-.5-1.3-1.4-1.3-2.3V38.9c0-.9.5-1.8 1.3-2.3.8-.5 1.8-.6 2.6-.2l16.6 7.9 16.6-7.9c.9-.4 1.9-.3 2.6.2.8.5 1.3 1.4 1.3 2.3v16.8c0 .9-.5 1.8-1.3 2.3-.4.3-.9.4-1.4.4zM40.1 47.3l8.6 4.1v-8.1l-8.6 4zm-21.5-4.1v8.1l8.6-4.1-8.6-4z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export default ForwardRef;
