import {
  Button,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  WalletDTO,
  useOrgWalletControllerCreate,
  useOrgWalletControllerUpdate,
  getOrgWalletControllerListQueryKey,
} from "api";
import InputField from "components/fields/InputField";
import { useFormik } from "formik";
import React from "react";

export function CreateOrgWalletModal({
  disclosure,
  organizationId,
}: {
  disclosure: UseDisclosureReturn;
  organizationId: string;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLInputElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError } = useOrgWalletControllerCreate();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values, helpers) => {
      mutate({
        data: values,
        organizationId,
      });
    },
    validate: (values) => {
      if (!values.name) {
        return { name: "Wallet name cannot be empty" };
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        getOrgWalletControllerListQueryKey(organizationId)
      );
      formik.resetForm();
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Create wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              isRequired
              isInvalid={Boolean(formik.errors.name && formik.touched.name)}
            >
              <InputField
                label="Name"
                name="name"
                innerRef={initialRef}
                placeholder="Wallet name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" mr={3} variant="brand" me="14px">
              Create
            </Button>
            <Button onClick={onClose} variant="no-hover" bg={bgButton}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export function EditOrgWalletModal({
  disclosure,
  wallet,
  organizationId,
}: {
  disclosure: UseDisclosureReturn;
  wallet: WalletDTO;
  organizationId: string;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLInputElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError } = useOrgWalletControllerUpdate();

  const formik = useFormik({
    initialValues: {
      name: wallet.name ?? "",
    },
    onSubmit: (values, helpers) => {
      mutate({
        organizationId,
        walletId: wallet.id,
        data: values,
      });
    },
    validate: (values) => {
      if (!values.name) {
        return { name: "Wallet name cannot be empty" };
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        getOrgWalletControllerListQueryKey(organizationId)
      );
      formik.resetForm();
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError, queryClient, organizationId, onClose]);

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Edit wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              isRequired
              isInvalid={Boolean(formik.errors.name && formik.touched.name)}
            >
              <InputField
                label="Name"
                name="name"
                innerRef={initialRef}
                placeholder="Wallet name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" mr={3} variant="brand" me="14px">
              Save
            </Button>
            <Button onClick={onClose} variant="no-hover" bg={bgButton}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
