import {
  Button,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  OrganizationDTO,
  useOrgQRControllerUpdate,
  OrganizationQRDTO,
  getOrgQRControllerListQueryKey,
  useOrgQRControllerCreate,
} from "api";
import InputField from "components/fields/InputField";
import { useFormik } from "formik";
import React from "react";

export function QREditModal({
  organization,
  qr,
  disclosure,
}: {
  organization: Pick<OrganizationDTO, "id">;
  qr: OrganizationQRDTO;
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLInputElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const tagBg = useColorModeValue("brand.500", "brand.400");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError, isLoading } = useOrgQRControllerUpdate();

  const formik = useFormik({
    initialValues: {
      internalCode: qr.internalCode,
      displayName: qr.displayName,
      collectingGoal: qr.collectingGoal,
    },
    onSubmit: (values, helpers) => {
      mutate({
        organizationId: organization.id,
        qrId: qr.id,
        data: values,
      });
    },
    validate: (values) => {
      if (!values.internalCode) {
        return { name: "Internal code must not be empty" };
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        getOrgQRControllerListQueryKey(organization.id)
      );
      formik.resetForm();
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError, queryClient, organization.id, onClose]);

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Edit QR-code {qr.internalCode}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.internalCode && formik.touched.internalCode
              )}
            >
              <InputField
                label="Internal code"
                name="internalCode"
                innerRef={initialRef}
                placeholder="Any code to help you with identification"
                onChange={formik.handleChange}
                value={formik.values.internalCode}
              />
              <FormErrorMessage>{formik.errors.internalCode}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <InputField
                label="Display name"
                name="displayName"
                placeholder="Display name"
                onChange={formik.handleChange}
                value={formik.values.displayName}
              />
            </FormControl>
            <FormControl>
              <InputField
                label="Goal"
                name="collectingGoal"
                placeholder="Goal of collecting money"
                onChange={formik.handleChange}
                value={formik.values.collectingGoal}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={isLoading}
              type="submit"
              mr={3}
              variant="brand"
              me="14px"
            >
              Save
            </Button>
            <Button onClick={onClose} variant="no-hover" bg={bgButton}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export function QRCreateModal({
  organization,
  disclosure,
}: {
  organization: Pick<OrganizationDTO, "id">;
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLInputElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const tagBg = useColorModeValue("brand.500", "brand.400");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError, isLoading } = useOrgQRControllerCreate();

  const formik = useFormik({
    initialValues: {
      internalCode: "",
      displayName: undefined,
      collectingGoal: undefined,
    },
    onSubmit: (values, helpers) => {
      mutate({
        organizationId: organization.id,
        data: {
          ...values,
        },
      });
    },
    validate: (values) => {
      if (!values.internalCode) {
        return { name: "Internal code must not be empty" };
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        getOrgQRControllerListQueryKey(organization.id)
      );
      formik.resetForm();
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError, queryClient, organization.id, onClose]);

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Create dynamic QR-code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.internalCode && formik.touched.internalCode
              )}
            >
              <InputField
                label="Internal code"
                name="internalCode"
                innerRef={initialRef}
                placeholder="Any code to help you with identification"
                onChange={formik.handleChange}
                value={formik.values.internalCode}
              />
              <FormErrorMessage>{formik.errors.internalCode}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <InputField
                label="Display name"
                name="displayName"
                placeholder="Display name"
                onChange={formik.handleChange}
                value={formik.values.displayName}
              />
            </FormControl>
            <FormControl>
              <InputField
                label="Goal"
                name="collectingGoal"
                placeholder="Goal of collecting money"
                onChange={formik.handleChange}
                value={formik.values.collectingGoal}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={isLoading}
              type="submit"
              mr={3}
              variant="brand"
              me="14px"
            >
              Create
            </Button>
            <Button onClick={onClose} variant="no-hover" bg={bgButton}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
