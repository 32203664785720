import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
} from "@chakra-ui/react";

export function InvitationExpiredAlert() {
  return (
    <Alert
      borderRadius="16px"
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="justify"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Invitation has expired
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <Text>
          Unfortunately, your invitation link has expired. Please ask
          restaurant manager to generate new link and send it to you.
        </Text>
      </AlertDescription>
    </Alert>
  );
}
