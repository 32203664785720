import {
  Flex,
  Button,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
  PropsOf,
  Box,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  MdAddCircle,
  MdDragHandle,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import {
  getOrgPaymentRuleControllerFindOneQueryKey,
  OrganizationDTO,
  OrganizationQRDTO,
  ParticipantDTO,
  PaymentRuletDTODataDistribution,
  TagDTO,
  useOrganizationParticipantsControllerFindAll,
  useOrganizationTagsControllerGetTags,
  useOrgPaymentRuleControllerFindOne,
  useOrgPaymentRuleControllerUpdate,
  useOrgQRControllerList,
  useOrgWalletControllerList,
  WalletDTO,
} from "api";
import { CreateEditRuleModal, getValue } from "./CreateEditRuleModal";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import { RuleFromTo } from "./components/RuleFromTo";
import { RuleActionMenu } from "./RuleActionMenu";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { RuleChangeAlert } from "./RuleChangeAlert";
import { useQueryClient } from "@tanstack/react-query";

const getReactId = (
  r: NonNullable<
    PaymentRuletDTODataDistribution[keyof PaymentRuletDTODataDistribution]
  >[number],
  timestamp = new Date().getTime()
) => `${getValue(r.from)}-${r.percent}-${getValue(r.to)}-${timestamp}`;

type VersionedDistribution = {
  [k in keyof PaymentRuletDTODataDistribution]: {
    data: Array<
      PaymentRuletDTODataDistribution[k][number] & {
        id: string;
      }
    >;
    version: number;
  };
};

export function OrgRulesSettings({
  organization,
}: {
  organization: Pick<OrganizationDTO, "id" | "name" | "avatarImage">;
}) {
  // Chakra Color Mode
  const titleTextColor = useColorModeValue("secondaryGray.900", "white");

  const { data: ruleListData } = useOrgPaymentRuleControllerFindOne(
    organization.id
  );

  const { data: tagsResponse } = useOrganizationTagsControllerGetTags(
    organization.id
  );

  const { data: walletListData } = useOrgWalletControllerList(organization.id);

  const { data: participantsData } =
    useOrganizationParticipantsControllerFindAll(organization.id);

  const { data: qrsData } = useOrgQRControllerList(organization.id);

  const queryClient = useQueryClient();
  const { mutateAsync, isSuccess, isError } =
    useOrgPaymentRuleControllerUpdate();
  const toast = useToast();

  const defaultRuleList = useMemo<VersionedDistribution>(() => {
    const { distribution } = ruleListData?.data.data ?? {
      version: 1,
      distribution: {
        byOrganization: [],
        byParticipant: [],
        byTag: [],
      },
    };

    return {
      byOrganization: {
        data: distribution.byOrganization.map((r) => ({
          id: getReactId(r),
          ...r,
        })),
        version: 0,
      },
      byParticipant: {
        data: distribution.byParticipant.map((r) => ({
          id: getReactId(r),
          ...r,
        })),
        version: 0,
      },
      byTag: {
        data: distribution.byTag.map((r) => ({ id: getReactId(r), ...r })),
        version: 0,
      },
    };
  }, [ruleListData?.data.data]);

  const [distribution, setDistribution] = useState(defaultRuleList);

  useEffect(() => {
    setDistribution(defaultRuleList);
  }, [defaultRuleList]);

  const hasChanges =
    distribution.byOrganization.version +
      distribution.byParticipant.version +
      distribution.byTag.version >
    0;

  const saveChangesDisclosure = useDisclosure();
  const discardChangesDisclosure = useDisclosure();

  const ChangesAlert = (
    <>
      <Alert
        borderRadius="8px"
        status="info"
        colorScheme="brand"
        variant="subtle"
        mb="20px"
      >
        <Flex w="100%">
          <AlertIcon />
          <Flex direction="column">
            <AlertTitle mr="12px">Do you want to save?</AlertTitle>
            <AlertDescription>
              You have unsaved changes. Click Save if you're done, or Discard if
              you want to discard changes you've made.
            </AlertDescription>
          </Flex>
          <Flex
            w="100%"
            gap={2}
            justify={{
              base: "center",
              md: "flex-end",
            }}
            align={{
              base: "flex-end",
              md: "center",
            }}
            direction={{
              base: "column",
              md: "row",
            }}
          >
            <Button onClick={saveChangesDisclosure.onOpen} variant="brand">
              Save
            </Button>
            <Button onClick={discardChangesDisclosure.onOpen}>Discard</Button>
          </Flex>
        </Flex>
      </Alert>
      <RuleChangeAlert
        type="save"
        disclosure={saveChangesDisclosure}
        onConsent={() => {
          mutateAsync({
            organizationId: organization.id,
            data: {
              version: 1,
              distribution: {
                byOrganization: distribution.byOrganization.data,
                byParticipant: distribution.byParticipant.data,
                byTag: distribution.byTag.data,
              },
            },
          })
            .then(() => {
              queryClient.invalidateQueries(
                getOrgPaymentRuleControllerFindOneQueryKey(organization.id)
              );
              toast({
                title: `You have successfully updated tips distribution`,
                position: "top",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: `Something went wrong`,
                position: "top",
                status: "error",
                isClosable: true,
              });
            });
        }}
      />
      <RuleChangeAlert
        type="discard"
        disclosure={discardChangesDisclosure}
        onConsent={() => {
          setDistribution(defaultRuleList);
        }}
      />
    </>
  );

  return (
    <Card px="26px" py="30px" w="100%">
      {hasChanges && ChangesAlert}
      <Text color={titleTextColor} fontSize="2xl" fontWeight="700" mb="10px">
        Tips to restaurant
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="10px">
        Add distribution rules for any tips, received from restaurant page
      </Text>

      <RuleList
        organization={organization}
        participants={participantsData?.data ?? []}
        qrs={qrsData?.data ?? []}
        wallets={walletListData?.data ?? []}
        tags={tagsResponse?.data ?? { public: [], private: [] }}
        from="organization"
        distribution={distribution}
        onChange={setDistribution}
      />

      <Text color={titleTextColor} fontSize="2xl" fontWeight="700" mb="10px">
        Tips to waiter
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="10px">
        Add distribution rules for any tips, received from waiters page
      </Text>

      <RuleList
        organization={organization}
        participants={participantsData?.data ?? []}
        wallets={walletListData?.data ?? []}
        qrs={qrsData?.data ?? []}
        tags={tagsResponse?.data ?? { public: [], private: [] }}
        from="participant"
        distribution={distribution}
        onChange={setDistribution}
      />

      <Text color={titleTextColor} fontSize="2xl" fontWeight="700" mb="10px">
        Tips to tag
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="10px">
        Add distribution rules for any tips, previously distributed to tag
      </Text>

      <RuleList
        organization={organization}
        participants={participantsData?.data ?? []}
        wallets={walletListData?.data ?? []}
        qrs={qrsData?.data ?? []}
        tags={tagsResponse?.data ?? { public: [], private: [] }}
        from="tag"
        distribution={distribution}
        onChange={setDistribution}
      />
    </Card>
  );
}

function SortableRule({
  tags,
  wallets,
  organization,
  participants,
  qrs,
  from,
  data: r,
  ...rest
}: {
  data: PaymentRuletDTODataDistribution[keyof PaymentRuletDTODataDistribution][number] & {
    id: string;
  };
} & Omit<PropsOf<typeof RuleActionMenu>, "rule">) {
  const walletBg = useColorModeValue("white", "whiteAlpha.100");
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: r.id });

  const Arrows = (
    <Box
      transform={{
        base: "rotate(90deg)",
        md: "unset",
      }}
    >
      <MdKeyboardDoubleArrowRight size="24px" />
    </Box>
  );

  return (
    <Card
      flexDirection="row"
      w="100%"
      p="10px"
      gap={4}
      bgColor={walletBg}
      alignContent="center"
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
      }}
    >
      <Flex
        alignSelf="center"
        justify="center"
        ref={setNodeRef}
        w="40px"
        h="40px"
        {...attributes}
        {...listeners}
      >
        <Icon alignSelf="center" as={MdDragHandle} w="24px" h="24px" />
      </Flex>

      <Flex
        w="100%"
        gap={2}
        direction={{
          base: "column",
          md: "row",
        }}
      >
        <RuleFromTo
          organization={organization}
          participants={participants}
          qrs={qrs}
          wallets={wallets}
          fromTo={r.from}
          tags={tags}
          minWidth={{
            base: "unset",
            md: "40%",
          }}
          maxWidth={{
            base: "unset",
            md: "40%",
          }}
        />
        <Flex
          align="center"
          justify="flex-start"
          gap={2}
          minWidth={{
            base: "unset",
            md: "20%",
          }}
          maxWidth={{
            base: "unset",
            md: "20%",
          }}
          ml={{
            base: "43px",
            md: "unset",
          }}
        >
          {Arrows}
          <Text textAlign="center" minW="40px">
            {r.percent}%
          </Text>
          {Arrows}
        </Flex>
        <RuleFromTo
          organization={organization}
          participants={participants}
          wallets={wallets}
          qrs={qrs}
          tags={tags}
          fromTo={r.to}
          minWidth={{
            base: "unset",
            md: "40%",
          }}
          maxWidth={{
            base: "unset",
            md: "40%",
          }}
        />
      </Flex>
      <Flex mr="10px" ml="40px" justifyContent="flex-end" align="center">
        <RuleActionMenu
          rule={r}
          from={from}
          organization={organization}
          wallets={wallets}
          qrs={qrs}
          participants={participants}
          tags={tags}
          {...rest}
        />
      </Flex>
    </Card>
  );
}

function RuleList({
  tags,
  wallets,
  organization,
  participants,
  qrs,
  from,
  distribution,
  onChange,
}: {
  tags: TagDTO;
  wallets: WalletDTO[];
  organization: Pick<OrganizationDTO, "id" | "name" | "avatarImage">;
  participants: ParticipantDTO[];
  qrs: OrganizationQRDTO[];
  from: PropsOf<typeof CreateButton>["from"];
  distribution: VersionedDistribution;
  onChange: (arg: SetStateAction<VersionedDistribution>) => void;
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  let key: keyof VersionedDistribution;

  switch (from) {
    case "organization":
      key = "byOrganization";
      break;
    case "participant":
      key = "byParticipant";
      break;
    case "tag":
      key = "byTag";
      break;
  }

  const data = distribution[key];
  const onChangeSimple = (
    newValue: VersionedDistribution[typeof key]["data"]
  ) => {
    onChange((distribution) => {
      return {
        ...distribution,
        [key]: {
          data: newValue,
          version: distribution[key].version + 1,
        },
      };
    });
  };

  return (
    <Flex direction="column" w="100%" gap={4}>
      <Flex direction="column" gap={4} w="100%">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(ev) => {
            const { active, over } = ev;

            if (active && over && active.id !== over.id) {
              onChange((distribution) => {
                const { version, data: items } = distribution[key];
                if (!items) {
                  return distribution;
                }

                const oldIndex = items.findIndex((i) => i.id === active.id);
                const newIndex = items.findIndex((i) => i.id === over.id);

                return {
                  ...distribution,
                  [key]: {
                    version: version + 1,
                    data: arrayMove(items as any, oldIndex, newIndex),
                  },
                };
              });
            }
          }}
        >
          <SortableContext
            items={data.data ?? []}
            strategy={verticalListSortingStrategy}
          >
            {data.data.map((r, index) => (
              <SortableRule
                key={r.id}
                from={from}
                organization={organization}
                wallets={wallets}
                qrs={qrs}
                participants={participants}
                tags={tags}
                data={r}
                onDelete={() => {
                  const result = [...data.data];
                  result.splice(index, 1);
                  onChangeSimple(result as any);
                }}
                onChange={(change) => {
                  const result = [...data.data];
                  result[index] = {
                    ...change,
                    id: getReactId(change),
                  };

                  onChangeSimple(result as any);
                }}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Flex>
      <Flex w="100%" justify="space-between" mb="50px">
        <CreateButton
          from={from}
          organization={organization}
          wallets={wallets}
          qrs={qrs}
          participants={participants}
          tags={tags}
          onChange={(change) => {
            const result = data ? [...data.data] : [];
            result.push({
              ...change,
              id: getReactId(change),
            });

            onChangeSimple(result as any);
          }}
        />
      </Flex>
    </Flex>
  );
}

function CreateButton(
  props: Omit<PropsOf<typeof CreateEditRuleModal>, "disclosure">
) {
  const disclosure = useDisclosure();
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <>
      <Button
        alignItems="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        // w="37px"
        h="37px"
        lineHeight="100%"
        borderRadius="10px"
        rightIcon={
          <Icon as={MdAddCircle} color={iconColor} w="24px" h="24px" />
        }
        onClick={disclosure.onOpen}
      >
        Add rule
      </Button>

      <CreateEditRuleModal disclosure={disclosure} {...props} />
    </>
  );
}
