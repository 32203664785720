import { Box, Button, Flex, Spacer, useColorModeValue } from "@chakra-ui/react";
import { ReactNode } from "react";

export function RuleBox(props: {
  children?: ReactNode;
  onChange?: () => void;
}) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("transparent", "navy.800");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  return (
    <Box
      // h="64px"
      w="100%"
      bg={bgColor}
      border="1px solid"
      color={textColorPrimary}
      borderColor={borderColor}
      borderRadius="16px"
      fontSize="sm"
      _placeholder={{ color: "secondaryGray.400" }}
    >
      <Flex m="10px" align="center">
        {props.children}
        <Spacer />
        {props.onChange && (
          <Button size="sm" variant="action" onClick={props.onChange}>
            Change
          </Button>
        )}
      </Flex>
    </Box>
  );
}
