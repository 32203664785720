import { Box, Flex, Text } from "@chakra-ui/react";
import { useOrganizationInviteControllerGenerateMagicLink } from "api";
import { InvitationExpiredAlert } from "components/invitation/InvitationExpiredAlert";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

export function Invitation() {
  const { token } = useParams<{ token: string }>();
  const session = useSessionContext();

  const {
    data: linkResponse,
    isError,
    error,
  } = useOrganizationInviteControllerGenerateMagicLink(token, {
    query: {
      retry: (attempt, error) => {
        if (error.isAxiosError && error.response?.status === 400) {
          return false;
        }

        return attempt <= 3;
      },
    },
  });
  const magicLink = linkResponse?.data.magicLink;

  useEffect(() => {
    if (magicLink) {
      localStorage.setItem("gotips-invitation-token", token);
      window.location.href = magicLink;
    }
  }, [magicLink, token]);

  useEffect(() => {
    if (error?.isAxiosError && !session.loading && session.doesSessionExist) {
      localStorage.setItem("gotips-invitation-token", "__expired__");
      window.location.href = "/waiter";
    }
  }, [error, session.loading]);

  return isError ? (
    <Flex w="100%" h="100%" align="center" justify="center">
      <Box>
        <InvitationExpiredAlert />
      </Box>
    </Flex>
  ) : (
    <Text>Please wait...</Text>
  );
}
