/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0
 */

export type UserDTOPayoutFrequency = typeof UserDTOPayoutFrequency[keyof typeof UserDTOPayoutFrequency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDTOPayoutFrequency = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
} as const;
