import { Flex } from "@chakra-ui/react";
import { OrganizationDTO } from "api";
import { OrgRulesSettings } from "./rules";
import { OrgRulesWallets } from "./wallets";

export function PaymentRules(props: {
  organization: Pick<OrganizationDTO, "id" | "name">;
}) {
  return (
    <Flex direction="column" gap="20px">
      <OrgRulesWallets organization={props.organization} />
      <OrgRulesSettings organization={props.organization} />
    </Flex>
  );
}
