export function getCurrency(ledgerCurrency: string) {
  const components = ledgerCurrency.split('/');

  return {
    code: components[0],
    precision: parseInt(components[1] ?? 0)
  }
}

export function applyPrecision(number: number, precision: number) {
  return number / Math.pow(10, precision);
}

export function restorePrecision(number: number, precision: number) {
  return number * Math.pow(10, precision);
}