// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { OrganizationDTO, ParticipantDTO, ParticipantDTORole } from "api";
import React from "react";
// Assets
import {
  MdOutlineMoreHoriz,
  MdDeleteOutline,
  MdModeEditOutline,
  MdQrCode,
} from "react-icons/md";
import { ParticipantDeleteAlert } from "./ParticipantDeleteAlert";
import { ParticipantEditModal } from "./ParticipantEditModal";
import { ParticipantQrModal } from "./ParticipantQrModal";

export function ParticipantActionMenu(props: {
  organization: Pick<OrganizationDTO, "id" | "name">;
  participant: Pick<
    ParticipantDTO,
    "id" | "role" | "user" | "publicTags" | "privateTags" | "number"
  >;
  myParticipant?: Pick<
    ParticipantDTO,
    "id" | "role" | "user" | "publicTags" | "privateTags"
  >;
  [x: string]: any;
}) {
  const { organization, participant, myParticipant, ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const alertDisclosure = useDisclosure();
  const editDisclosure = useDisclosure();
  const qrDisclosure = useDisclosure();

  return (
    <>
      <Menu isOpen={isOpen1} onClose={onClose1}>
        <MenuButton
          alignItems="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          onClick={onOpen1}
          borderRadius="10px"
          {...rest}
        >
          <Icon
            as={MdOutlineMoreHoriz}
            color={iconColor}
            w="24px"
            h="24px"
            mt="4px"
          />
        </MenuButton>
        <MenuList
          w="150px"
          minW="unset"
          maxW="150px !important"
          border="transparent"
          backdropFilter="blur(63px)"
          bg={bgList}
          boxShadow={bgShadow}
          borderRadius="20px"
          p="15px"
        >
          <MenuItem
            transition="0.2s linear"
            color={textColor}
            _hover={textHover}
            p="0px"
            borderRadius="8px"
            bg="transparent"
            _active={{
              bg: "transparent",
            }}
            _focus={{
              bg: "transparent",
            }}
            mb="10px"
            onClick={qrDisclosure.onOpen}
          >
            <Flex align="center">
              <Icon as={MdQrCode} h="16px" w="16px" me="8px" />
              <Text fontSize="sm" fontWeight="400">
                Get QR code
              </Text>
            </Flex>
          </MenuItem>
          <MenuItem
            transition="0.2s linear"
            bg="transparent"
            color={textColor}
            _hover={textHover}
            p="0px"
            borderRadius="8px"
            _active={{
              bg: "transparent",
            }}
            _focus={{
              bg: "transparent",
            }}
            mb="10px"
            onClick={editDisclosure.onOpen}
          >
            <Flex align="center">
              <Icon as={MdModeEditOutline} h="16px" w="16px" me="8px" />
              <Text fontSize="sm" fontWeight="400">
                Edit
              </Text>
            </Flex>
          </MenuItem>
          <MenuItem
            bg="transparent"
            transition="0.2s linear"
            color={textColor}
            _hover={textHover}
            p="0px"
            borderRadius="8px"
            _active={{
              bg: "transparent",
            }}
            _focus={{
              bg: "transparent",
            }}
            mb="10px"
            onClick={alertDisclosure.onOpen}
            isDisabled={
              myParticipant?.role === "OWNER" &&
              myParticipant?.id === participant.id
            }
          >
            <Flex align="center">
              <Icon as={MdDeleteOutline} h="16px" w="16px" me="8px" />
              <Text fontSize="sm" fontWeight="400">
                Delete
              </Text>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
      <ParticipantDeleteAlert
        disclosure={alertDisclosure}
        organization={organization}
        participant={participant}
      />
      <ParticipantEditModal
        disclosure={editDisclosure}
        organization={organization}
        participant={participant}
        myParticipant={myParticipant}
      />
      <ParticipantQrModal
        disclosure={qrDisclosure}
        organization={organization}
        participant={participant}
        myParticipant={myParticipant}
      />
    </>
  );
}
