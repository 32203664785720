// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  getUserControllerMeQueryKey,
  UserDTOPayoutFrequency,
  useUserControllerUpdate,
} from "api";
import Card from "components/card/Card";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useUserContext } from "utils/userContext";

export function PayoutFrequency(props: { [x: string]: any }) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  const queryClient = useQueryClient();
  const user = useUserContext();
  const toast = useToast();

  const { mutate, isLoading, isSuccess } = useUserControllerUpdate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      collectingGoal: user?.collectingGoal,
      displayName: user?.displayName,
      payoutFrequency: user?.payoutFrequency ?? UserDTOPayoutFrequency.WEEKLY,
    },
    onSubmit: (values, helpers) => {
      mutate({
        data: values,
      });
    },
    validate: (values) => {
      if (!values.payoutFrequency) {
        return { payoutFrequency: "Payout frequency cannot be empty" };
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(getUserControllerMeQueryKey());

      toast({
        title: `Payout frequency has been updated!`,
        position: "top",
        status: "success",
        isClosable: true,
      });
    }
  }, [isSuccess]);

  // Chakra Color Mode
  return (
    <form onSubmit={formik.handleSubmit}>
      <Card {...rest}>
        <Flex direction="column" mb="30px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Payout Frequency
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            How often you receive tips on your bank account
          </Text>
        </Flex>
        <FormControl>
          <Flex flexDirection="column">
            <FormLabel
              ms="10px"
              htmlFor="color"
              fontSize="sm"
              color={textColorPrimary}
              fontWeight="bold"
              _hover={{ cursor: "pointer" }}
            >
              Frequency
            </FormLabel>
            <Select
              fontSize="sm"
              id="payoutFrequency"
              variant="main"
              h="44px"
              maxH="44px"
              fontWeight="400"
              me="20px"
              value={formik.values.payoutFrequency}
              onChange={formik.handleChange}
            >
              <option value={UserDTOPayoutFrequency.DAILY}>Daily</option>
              <option value={UserDTOPayoutFrequency.WEEKLY}>Weekly</option>
              <option value={UserDTOPayoutFrequency.MONTHLY}>Monthly</option>
            </Select>
            <Text fontSize="sm" mt="8px" ms="10px">
              Note that automatic payouts happens only if amount is greater than
              or equal 50 EUR. Less amounts might still be paid out but
              manually.
            </Text>
          </Flex>
        </FormControl>
        <Button
          borderRadius="16px"
          minW="183px"
          h="44px"
          ms="auto"
          mt="33px"
          variant="brand"
          color="white"
          fontSize="sm"
          fontWeight="500"
          _hover={{ bg: "brand.600" }}
          _active={{ bg: "brand.500" }}
          _focus={{ bg: "brand.500" }}
          isDisabled={isLoading}
          type="submit"
        >
          Save
        </Button>
      </Card>
    </form>
  );
}
