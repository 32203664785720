/* eslint-disable */

import {
  Avatar,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  useDisclosure,
  LinkBox,
  LinkOverlay,
  Box,
} from "@chakra-ui/react";
// Custom components
import { MdAddCircle } from "react-icons/md";
import * as React from "react";
// Assets
import {
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { OrganizationDTO } from "api";
import { useHistory, Link } from "react-router-dom";
import { CreateOrganizationModal } from "../../components/CreateEditModal";
import { OrganizationActionMenu } from "./OrganizationActionMenu";

function CreateButton() {
  const disclosure = useDisclosure();
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <>
      <Button
        alignItems="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        // w="37px"
        h="37px"
        lineHeight="100%"
        borderRadius="10px"
        rightIcon={
          <Icon as={MdAddCircle} color={iconColor} w="24px" h="24px" />
        }
        onClick={disclosure.onOpen}
      >
        Create
      </Button>

      <CreateOrganizationModal disclosure={disclosure} />
    </>
  );
}
export default function OrganizationsTable(props: {
  tableData: OrganizationDTO[];
}) {
  const { tableData } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");
  const columnHelper = createColumnHelper<OrganizationDTO>();
  const [data, setData] = React.useState(() => [...tableData]);

  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const actionColumn = React.useMemo(
    () =>
      columnHelper.display({
        size: 100,
        enableResizing: false,
        id: "actions",
        cell: (info) => (
          <Flex justifyContent="flex-end">
            <OrganizationActionMenu organization={info.row.original} />
          </Flex>
        ),
      }),
    [data]
  );

  const columns = [
    columnHelper.display({
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          NAME
        </Text>
      ),
      cell: (ctx) => (
        <Link to={"/admin/organizations/" + ctx.cell.row.original.id}>
          <Flex align="center">
            <Avatar
              src={ctx.row.original.avatarImage ?? undefined}
              name={ctx.row.original.name}
              h="60px"
              w="60px"
              me="10px"
            />
            <Text color={textColor} fontSize="md" fontWeight="500">
              {ctx.row.original.name}
            </Text>
          </Flex>
        </Link>
      ),
    }),
    actionColumn,
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    manualPagination: true,
  });

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <div></div>
        <CreateButton />
      </Flex>
      <Table
        variant="simple"
        color="gray.500"
        mb="24px"
        style={{ tableLayout: "fixed" }}
      >
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    pe="10px"
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width:
                        header.getSize() !== 150 ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr px="20px" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor={borderColor}
                      style={{
                        width:
                          cell.column.getSize() !== 150
                            ? cell.column.getSize()
                            : undefined,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
        {table.getPageCount() === 0 && <Text>No restaurants yet</Text>}
        {/* SET ROW NUMBER */}
        {/* <Text
          fontSize="sm"
          color="gray.500"
          fontWeight="normal"
          mb={{ sm: "24px", md: "0px" }}
        >
          Showing {pageSize * pageIndex + 1} to{" "}
          {pageSize * (pageIndex + 1) <= tableData.length
            ? pageSize * (pageIndex + 1)
            : tableData.length}{" "}
          of {tableData.length} entries
        </Text> */}
        <div></div>
        {/* PAGINATION BUTTONS */}
        {/* <div className="flex items-center gap-2">
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-effects"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue("gray.200", "white")}
              display={
                pageSize === 5
                  ? "none"
                  : table.getCanPreviousPage()
                  ? "flex"
                  : "none"
              }
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}
            >
              <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
            </Button>
            {createPages(table.getPageCount()).map((pageNumber, index) => {
              return (
                <Button
                  variant="no-effects"
                  transition="all .5s ease"
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bg={pageNumber === pageIndex + 1 ? brandColor : "transparent"}
                  border={
                    pageNumber === pageIndex + 1
                      ? "none"
                      : "1px solid lightgray"
                  }
                  _hover={
                    pageNumber === pageIndex + 1
                      ? {
                          opacity: "0.7",
                        }
                      : {
                          bg: "whiteAlpha.100",
                        }
                  }
                  key={index}
                >
                  <Text
                    fontSize="sm"
                    color={pageNumber === pageIndex + 1 ? "#fff" : textColor}
                  >
                    {pageNumber}
                  </Text>
                </Button>
              );
            })}
            <Button
              variant="no-effects"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue("gray.200", "white")}
              display={
                pageSize === 5
                  ? "none"
                  : table.getCanNextPage()
                  ? "flex"
                  : "none"
              }
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}
            >
              <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
            </Button>
          </Stack>
        </div> */}
      </Flex>
    </Flex>
  );
}
