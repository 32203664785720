// Chakra imports
import {
  Flex,
  Icon,
  Text,
  useToast,
  useColorModeValue,
  Button,
  Switch,
  Tooltip,
  SwitchProps,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteTag,
  AutoCompleteList,
  AutoCompleteCreatable,
  AutoCompleteRefMethods,
} from "@choc-ui/chakra-autocomplete";
import { useQueryClient } from "@tanstack/react-query";
import {
  getOrganizationControllerFindOneQueryKey,
  useOrganizationLinksControllerGetInvitationLink,
  useOrganizationLinksControllerSendInvitations,
} from "api";
// Custom components
import Card from "components/card/Card";
import { useEffect, useRef, useState } from "react";

// Assets
import { IoPaperPlane } from "react-icons/io5";
import { MdInfoOutline } from "react-icons/md";

function PublicSwitch(props: SwitchProps) {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Flex direction="row" gap={1} align="center" mb="10px">
      <Switch size="md" mr="5px" {...props} />
      <Text fontSize="md">Assign public tag</Text>
      <Tooltip
        label="If enabled, after accepting invitation, your teammate(s) will become visible for tip givers on the restaurant's page"
        fontSize="sm"
        borderRadius="20px"
        px="15px"
        py="10px"
        isOpen={isTooltipOpen}
      >
        <span tabIndex={0} style={{ verticalAlign: "middle" }}>
          <Icon
            as={MdInfoOutline}
            fontSize="md"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            onClick={() => setTooltipOpen(true)}
          />
        </span>
      </Tooltip>
    </Flex>
  );
}

export function OrganizationParticipants(props: {
  organization: {
    id: string;
  };
  [x: string]: any;
}) {
  const { ...rest } = props;
  const toast = useToast();

  const autocompleteRef = useRef<AutoCompleteRefMethods>(null);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const tagBg = useColorModeValue("brand.500", "brand.400");

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );

  const queryClient = useQueryClient();

  const isValid = (value: any) => {
    return (value as string).includes("@");
  };

  const [emails, setEmails] = useState<string[]>([]);
  const [isLinkPublic, setLinkPublic] = useState(false);
  const [isEmailPublic, setEmailPublic] = useState(false);

  const { data: linkResponse } =
    useOrganizationLinksControllerGetInvitationLink(props.organization.id, {
      public: isLinkPublic,
    });

  const { mutate, isSuccess, isError, isLoading } =
    useOrganizationLinksControllerSendInvitations();

  const onClickSendInvitations = () =>
    mutate({
      organizationId: props.organization.id,
      data: {
        emails,
        isPublic: isEmailPublic,
      },
    });

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        getOrganizationControllerFindOneQueryKey(props.organization.id)
      );

      toast({
        title: `Invitations sent!`,
        position: "top",
        status: "success",
        isClosable: true,
      });

      autocompleteRef.current?.resetItems(false);
    }

    if (isError) {
      toast({
        title: `Error! Please check the list of emails...`,
        position: "top",
        status: "error",
        isClosable: true,
      });
    }
  }, [isSuccess, isError, queryClient, props.organization.id]);

  return (
    <Card px="26px" py="30px" w="100%" {...rest}>
      <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">
        Invite your teammates
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="30px">
        Put emails of your teammates here. We'll send them invitations.
      </Text>
      <Flex
        direction="column"
        position="relative"
        mb="50px"
        align="flex-start"
        w="100%"
        gap="10px"
      >
        <AutoComplete
          multiple
          creatable
          closeOnSelect
          closeOnBlur
          ref={autocompleteRef}
          onChange={(vals) => setEmails(vals)}
          shouldRenderSuggestions={(val) => val.trim().length > 0}
          values={emails}
        >
          <AutoCompleteInput
            variant="main"
            borderRadius="16px"
            placeholder="Email addresses..."
            _placeholder={{ color: "secondaryGray.600", fontWeight: "400" }}
            color={textColor}
            minH="40px"
            fontWeight="500"
            fontSize="sm"
          >
            {({ tags }) =>
              tags.map((tag, tid) => {
                const valid = isValid(tag.label);
                const element = (
                  <AutoCompleteTag
                    key={tid}
                    label={tag.label}
                    onRemove={tag.onRemove}
                    bgColor={valid ? tagBg : "red.400"}
                    variant="solid"
                    borderRadius="12px"
                    textTransform="lowercase"
                  />
                );

                return element;
              })
            }
          </AutoCompleteInput>
          <AutoCompleteList>
            <AutoCompleteCreatable>
              {({ value }) => <span>Add {value} to list</span>}
            </AutoCompleteCreatable>
          </AutoCompleteList>
        </AutoComplete>
        {emails.length > 0 && (
          <>
            <PublicSwitch
              isChecked={isEmailPublic}
              onChange={(ev) => setEmailPublic(ev.target.checked)}
            />
            <Button
              alignItems="center"
              justifyContent="center"
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              h="37px"
              lineHeight="100%"
              onClick={onClickSendInvitations}
              borderRadius="10px"
              disabled={isLoading}
              rightIcon={
                <Icon as={IoPaperPlane} color={iconColor} w="14px" h="14px" />
              }
            >
              Send invitations
            </Button>
          </>
        )}
      </Flex>
      <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">
        Share join link
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="10px">
        You can also share this link by copying. The link is valid for 24 hours
        from now.
      </Text>
      <PublicSwitch
        isChecked={isLinkPublic}
        onChange={(ev) => setLinkPublic(ev.target.checked)}
      />
      <Flex
        w="100%"
        h="40px"
        px="18px"
        align="center"
        borderRadius="16px"
        cursor="pointer"
        onClick={function () {
          if (linkResponse?.data.link) {
            navigator.clipboard.writeText(linkResponse.data.link);
          }
          toast({
            title: `Invite link copied!`,
            position: "top",
            status: "success",
            isClosable: true,
          });
        }}
        bg={boxBg}
        me="6px"
      >
        <Text
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          noOfLines={1}
          w={{ base: "60%", md: "85%", "2xl": "68%", "3xl": "80%" }}
        >
          {linkResponse?.data.link}
        </Text>
        <Text
          minW="80px"
          align="right"
          ms="auto"
          color={iconColor}
          fontSize="sm"
          fontWeight="500"
        >
          Copy link
        </Text>
      </Flex>
    </Card>
  );
}
