import { Redirect, Route, Switch } from "react-router-dom";

import DashboardsDefault from "views/admin/dashboards/default/WaiterDashboard";
import { CommonLayout } from "layouts/common/CommonLayout";
import AccountSettings from "views/admin/main/account/settings";
import { WaiterQrCode } from "views/admin/main/organizations/details/qr";

// Custom Chakra theme
export function WaiterLayout(props: { [x: string]: any }) {
  return (
    <CommonLayout {...props}>
      <Switch>
        <Route path="/waiter" exact component={DashboardsDefault} />
        <Route path="/waiter/qr" component={WaiterQrCode} />
        <Route path="/waiter/profile" component={AccountSettings} />
      </Switch>
    </CommonLayout>
  );
}
