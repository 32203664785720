// Chakra imports
import {
  Flex,
  Avatar,
  Text,
  useColorModeValue,
  Box,
  IconButton,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { MdOutlineEdit, MdOutlineMoreHoriz } from "react-icons/md";
import { EditOrganizationModal } from "../../components/CreateEditModal";
import { UploadMenu } from "./UploadMenu";
// Assets

export default function Banner(props: {
  image?: string | null;
  address?: string;
  name: string;
  profile: string;
  entityId: string;
}) {
  const { image, address, name, profile, entityId } = props;

  const disclosure = useDisclosure();

  // Chakra Color Mode
  const borderColor = useColorModeValue(
    "white !important",
    "#0b1437 !important"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorLink = useColorModeValue("blue.500", "white");
  const coverBg = image
    ? {
        backgroundImage: "url('" + image + "')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }
    : { bg: "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)" };

  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <>
      <Flex justifyContent="center" align="center" direction="column" w="100%">
        <Box
          w={{ base: "100%", "3xl": "100%" }}
          h={{ base: "140px", md: "180px" }}
          borderRadius="20px"
          {...coverBg}
        >
          <Flex p="10px" justifyContent="flex-end" align="center">
            <UploadMenu />
          </Flex>
        </Box>

        <Avatar
          src={profile}
          h={{ base: "150px", md: "170px" }}
          w={{ base: "150px", md: "170px" }}
          border="10px solid"
          mt="-95px"
          mb="0px"
          name={name}
          borderColor={borderColor}
        />

        <Flex align="center" mb="10px">
          <Text
            color={textColor}
            fontSize={{ base: "40px", lg: "54px" }}
            fontWeight="700"
            lineHeight="100%"
            me="6px"
          >
            {name}
          </Text>
          <IconButton
            ml="10px"
            mt="5px"
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            icon={
              <Icon
                as={MdOutlineEdit}
                color={iconColor}
                w="24px"
                h="24px"
                mt="4px"
              />
            }
            aria-label="Edit"
            onClick={disclosure.onOpen}
          />
          {/* <Icon as={MdVerified} h='34px' w='34px' color='blue.500' mt='12px' /> */}
        </Flex>
        <Text
          color={textColorLink}
          fontSize="lg"
          fontWeight="500"
          mx="4px"
          mb="14px"
        >
          {address}
        </Text>
      </Flex>
      <EditOrganizationModal
        key={entityId + name + address}
        organization={{
          id: entityId,
          name,
          address,
        }}
        disclosure={disclosure}
      />
    </>
  );
}
