/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Flex, SimpleGrid } from "@chakra-ui/react";
import { useUserContext } from "utils/userContext";
import { PayoutInformationDocumentRequired } from "views/admin/dashboards/default/DashboardWarning";
import Information from "views/admin/main/account/settings/components/Information";
import Profile from "views/admin/main/account/settings/components/Profile";
import { Beneficiary } from "./components/Beneficiary";
import { PayoutFrequency } from "./components/PayoutFrequency";

export default function Settings() {
  const user = useUserContext();
  // Chakra Color Mode
  return (
    <Flex direction="column" width="100%">
      {user &&
        (user.beneficiary?.status === "PENDING" ||
          user.beneficiary?.status === "DECLINED") && (
          <PayoutInformationDocumentRequired
            user={user}
            mb="20px"
            borderRadius="16px"
          />
        )}
      {/* Main Fields */}
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1, lg: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {/* Column Left */}
        <Flex direction="column">
          <Profile />
          <Information />
        </Flex>
        {/* Column Right */}
        <Flex direction="column">
          <Beneficiary />
          <PayoutFrequency />
        </Flex>
      </SimpleGrid>
    </Flex>
  );
}
