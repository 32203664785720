// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Icon,
  ButtonProps,
  Button,
  useDisclosure,
  UseDisclosureReturn,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Link,
  Tooltip,
} from "@chakra-ui/react";

import { Link as RouterLink, useLocation } from "react-router-dom";

// Custom components
import Card from "components/card/Card";
import { useMemo, useState } from "react";
import { MdAccountBalanceWallet, MdInfoOutline, MdSend } from "react-icons/md";
import { getI18n } from "react-i18next";
import { applyPrecision, getCurrency } from "utils/currency";
import IconBox from "components/icons/IconBox";
import { WalletDTO } from "api";
import { TransferFundsModal } from "./TransferFundsModal";
import { useUserContext } from "utils/userContext";
import { PayoutFundsModal } from "./payout/PayoutFundsModal";

function PayoutsRestrictedModal({
  disclosure: { isOpen, onClose },
}: {
  disclosure: UseDisclosureReturn;
}) {
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Unavailable</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Sorry, but payouts is temporarily unavailable, please contact support
          by chat or by email{" "}
          <b>
            <Link href="mailto:contact@gotips.co">contact@gotips.co</Link>
          </b>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} variant="no-hover" bg={bgButton}>
            Okay
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function KYCRequiredModal({
  disclosure: { isOpen, onClose },
}: {
  disclosure: UseDisclosureReturn;
}) {
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Verification required</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          To make payouts work seamlessly, we need to verify your profile.
          Please proceed with verification first. If you feel something is
          wrong, please contact support by chat or by email{" "}
          <b>
            <Link href="mailto:contact@gotips.co">contact@gotips.co</Link>
          </b>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} variant="no-hover" bg={bgButton}>
            Okay
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function BeneficiaryRequiredModal({
  disclosure: { isOpen, onClose },
}: {
  disclosure: UseDisclosureReturn;
}) {
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const location = useLocation();
  const isAdmin = location.pathname.includes("/admin");

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Bank details missing</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Please add your bank account details in your{" "}
          <b>
            <Link
              as={RouterLink}
              to={isAdmin ? `/admin/profile` : `/waiter/profile`}
            >
              profile settings
            </Link>
          </b>
          .
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} variant="no-hover" bg={bgButton}>
            Okay
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function WalletButton2(props: { title: string; buttonProps?: ButtonProps }) {
  // const { isOpen, isClose, onClose, onOpen } = useDisclosure();
  // const [isTooltipOpen, setTooltipOpen] = useState(false);
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );
  const modalBg = useColorModeValue("white", "blackAlpha.800");

  return (
    <Button
      alignItems="center"
      justifyContent="center"
      bg={bgButton}
      _hover={bgHover}
      _focus={bgFocus}
      _active={bgFocus}
      h="37px"
      lineHeight="100%"
      borderRadius="10px"
      rightIcon={<Icon as={MdSend} color={iconColor} w="24px" h="24px" />}
      {...props.buttonProps}
    >
      {props.title}
    </Button>
  );
}

function WalletAccount(props: {
  title: string;
  value: string;
  type: "hold" | "main";
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Flex direction="column" justify="center" align="center" py="5px">
      <Text fontSize="xs" color="secondaryGray.600" fontWeight="700" mb="5px">
        {props.title}
        {props.type === "hold" && (
          <Tooltip
            label="Funds are in processing by payment gateway, usually it takes 24h. Availability of payout for these funds is not guaranteed."
            fontSize="sm"
            borderRadius="20px"
            px="15px"
            py="10px"
            isOpen={isTooltipOpen}
          >
            <span tabIndex={0} style={{ verticalAlign: "middle" }}>
              <Icon
                as={MdInfoOutline}
                mx="5px"
                fontSize="md"
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                onClick={() => setTooltipOpen(true)}
              />
            </span>
          </Tooltip>
        )}
      </Text>
      {/* <Badge
        colorScheme={props.type === "hold" ? "yellow" : "green"}
        color={props.type === "hold" ? "yellow.500" : "green.500"}
        fontWeight="700"
        fontSize="lg"
      >
        {props.value}
      </Badge> */}
      <Text fontSize="lg" color={textColor} fontWeight="700">
        {props.value}
      </Text>
    </Flex>
  );
}

export function CreateWalletButton() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const border = useColorModeValue("23E0E5F2FF", "23FFFFFF1A");
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  return (
    <Card
      p="20px"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      w="100%"
      minH="250px"
      bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%${border}' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e")`}
    >
      <Flex direction="column" justify="center" align="center">
        <IconBox
          mx="auto"
          h="100px"
          w="100px"
          icon={
            <Icon
              as={MdAccountBalanceWallet}
              color={brandColor}
              h="46px"
              w="46px"
            />
          }
          bg={box}
        />
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
        >
          Create wallet
        </Text>
        <Text
          align="center"
          color={textColorSecondary}
          fontSize="md"
          maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
          mx="auto"
        >
          Use wallet to collect and distribute funds across the waiters
        </Text>
      </Flex>
    </Card>
  );
}

export function Wallet(props: {
  wallet: WalletDTO;
  organizationId?: string;
  [x: string]: any;
}) {
  const {
    wallet: { currency, balance, holdBalance, name },
    organizationId,
    ...rest
  } = props;
  const locale = getI18n().language;
  const { code: currencyCode, precision } = getCurrency(currency);
  const transferFundsDisclosure = useDisclosure();
  const payoutFundsDisclosure = useDisclosure();
  const user = useUserContext();
  const payoutsRestrictedDisclosure = useDisclosure();
  const payoutsKYCRequiredDisclosure = useDisclosure();
  const payoutsBeneficiaryRequiredDisclosure = useDisclosure();

  const [sumFormatter, currencySymbol] = useMemo(() => {
    const format = new Intl.NumberFormat(locale, {
      currency: currencyCode,
      style: "currency",
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });

    const symbol = format
      .formatToParts(1)
      .find((p) => p.type === "currency")?.value;

    return [format, symbol ?? currency];
  }, [currencyCode, precision, locale]);

  const withPrecision = (number: number) => {
    return sumFormatter.format(applyPrecision(number, precision));
  };

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const cardColor = useColorModeValue("white", "navy.700");

  const onClick = () => {
    if (organizationId) {
      return transferFundsDisclosure.onOpen();
    }

    if (!user) {
      return;
    }

    if (user.payoutsRestricted) {
      return payoutsRestrictedDisclosure.onOpen();
    }

    if (
      user.kyc?.find((c) => c.status === "CREATED" || c.status === "PENDING")
    ) {
      return payoutsKYCRequiredDisclosure.onOpen();
    }

    if (!user.beneficiary?.iban) {
      return payoutsBeneficiaryRequiredDisclosure.onOpen();
    }

    return payoutFundsDisclosure.onOpen();
  };

  return (
    <Card
      p="20px"
      alignItems="center"
      flexDirection="column"
      w="100%"
      {...rest}
    >
      <Flex justify="space-between" pt="5px" mb="20px" align="center" w="100%">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          {name ? name : <>{currencySymbol}&nbsp;&nbsp;Wallet</>}
        </Text>
        <div></div>
      </Flex>
      <Flex direction="row" justify="center" align="center" gap={4}>
        <Text
          color={brandColor}
          fontSize="5xl"
          fontWeight="700"
          mb="10px"
          mx="auto"
        >
          {withPrecision(balance + holdBalance)}
        </Text>
      </Flex>
      <WalletButton2
        buttonProps={{
          mb: "20px",
          isDisabled: balance + holdBalance <= 0,
          onClick,
        }}
        title={organizationId ? "Transfer funds" : "Request payout"}
      />
      <PayoutsRestrictedModal disclosure={payoutsRestrictedDisclosure} />
      <KYCRequiredModal disclosure={payoutsKYCRequiredDisclosure} />
      <BeneficiaryRequiredModal
        disclosure={payoutsBeneficiaryRequiredDisclosure}
      />
      {organizationId ? (
        <TransferFundsModal
          disclosure={transferFundsDisclosure}
          wallet={props.wallet}
          organizationId={organizationId}
        />
      ) : (
        <PayoutFundsModal
          disclosure={payoutFundsDisclosure}
          wallet={props.wallet}
        />
      )}

      {!organizationId && holdBalance > 0 && (
        <Card
          bg={cardColor}
          justifyContent="space-evenly"
          flexDirection="row"
          p="15px"
          px="20px"
          mt="auto"
          mb="20px"
        >
          <WalletAccount
            title="Under processing"
            value={withPrecision(holdBalance + Math.min(balance, 0))}
            type="hold"
          />
          <Text color={textColor} fontSize="5xl" fontWeight="700" mb="10px">
            +
          </Text>
          <WalletAccount
            title="Ready to payout"
            value={withPrecision(Math.max(balance, 0))}
            type="main"
          />
        </Card>
      )}
    </Card>
  );
}
