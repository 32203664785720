/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Flex, Grid } from "@chakra-ui/react";
// Custom components
import { Wallet } from "./components/Wallet";
import {
  useUserWalletControllerGetWallet,
  userWalletControllerGetTransactions,
  useUserPartControllerMyParticipants,
  useUserKYCControllerGetChallenges,
  getUserControllerMeQueryKey,
} from "api";
import { TrasactionList } from "./components/TransactionList";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  AccountSuspendedWarning,
  DashboardWarning,
  KYCRequiredWarning,
  PayoutInformationDocumentRequired,
  WaiterStatus,
} from "./DashboardWarning";
import { useUserContext } from "utils/userContext";
import { userHasName } from "utils/name";
import { useSearchParams } from "utils/useSearchParams";

const TRANSACTIONS_LIMIT = 15;

export default function AdminDashboard() {
  const { data: walletData } = useUserWalletControllerGetWallet();
  const { data: pData } = useUserPartControllerMyParticipants();
  const user = useUserContext();
  const params = useSearchParams();
  const queryClient = useQueryClient();
  const { refetch: checkKYCChallenges, dataUpdatedAt: challengesUpdatedAt } =
    useUserKYCControllerGetChallenges({
      query: {
        enabled: false,
      },
    });

  const [selectedRange, setSelectedRange] = useState<
    [Date, Date] | null | undefined
  >();

  const fromKYC = params.has("from_kyc");

  useEffect(() => {
    if (fromKYC) {
      const interval = setInterval(() => {
        checkKYCChallenges();
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [fromKYC]);

  useEffect(() => {
    queryClient.invalidateQueries(getUserControllerMeQueryKey());
  }, [challengesUpdatedAt]);

  const {
    fetchNextPage,
    data: transactionsData,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["waiter_transactions", selectedRange],
    ({ pageParam }: { pageParam?: string }) =>
      userWalletControllerGetTransactions({
        limit: TRANSACTIONS_LIMIT,
        position: pageParam ?? "",
        ...(selectedRange
          ? {
              startTime: selectedRange[0].toISOString(),
              endTime: selectedRange[1].toISOString(),
            }
          : undefined),
      }),
    {
      getNextPageParam: (lastPage) => lastPage.data.next,
      getPreviousPageParam: (lastPage) => lastPage.data.previous,
    }
  );

  const warningType = (() => {
    if (user?.tipsRestricted) {
      return "tipsRestricted";
    }
    if (
      user?.kyc?.find((c) => c.status === "CREATED" || c.status === "PENDING")
    ) {
      return "kycRequired";
    }
    if (
      user?.beneficiary?.status === "PENDING" ||
      user?.beneficiary?.status === "DECLINED"
    ) {
      return "beneficiaryRequired";
    }
  })();

  return (
    <Flex w="100%" direction="column" gap="20px" mb="20px">
      {user && !userHasName(user) ? (
        <DashboardWarning borderRadius="16px" />
      ) : pData && !pData.data?.length ? (
        <WaiterStatus borderRadius="16px" />
      ) : (
        <></>
      )}
      {user && warningType === "tipsRestricted" && (
        <AccountSuspendedWarning user={user} borderRadius="16px" />
      )}
      {user && warningType === "kycRequired" && (
        <KYCRequiredWarning user={user} borderRadius="16px" />
      )}
      {user && warningType === "beneficiaryRequired" && (
        <PayoutInformationDocumentRequired user={user} borderRadius="16px" />
      )}
      {!!walletData?.data?.length && (
        <Grid
          gap="20px"
          gridTemplateColumns={{
            md: "repeat(2, 1fr)",
            "2xl": "repeat(3, 1fr)",
          }}
        >
          {walletData.data.map((w) => (
            <Flex key={w.id}>
              <Wallet wallet={w} />
            </Flex>
          ))}
        </Grid>
      )}
      {transactionsData && transactionsData.pages.length > 0 && (
        <TrasactionList
          data={transactionsData.pages.flatMap((p) => p.data.data)}
          hasMore={hasNextPage ?? false}
          loadMore={fetchNextPage}
          isLoadingMore={isFetchingNextPage}
          selectedRange={selectedRange}
          onSelectedRangeChange={setSelectedRange}
          mode="participant"
        />
      )}
    </Flex>
  );
}
