import {
  Box,
  Button,
  Center,
  Flex,
  useColorModeValue,
  useToast,
  Text,
} from "@chakra-ui/react";
import { OrganizationDTO, OrganizationQRDTO, ParticipantDTO } from "api";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import { useMemo, useRef } from "react";
import { MdDownload } from "react-icons/md";
import { getName } from "utils/name";

export type QrCodeDownloaderOptions = {
  size?: number;
} & (
  | {
      participant: Pick<
        ParticipantDTO,
        "id" | "role" | "user" | "publicTags" | "privateTags" | "number"
      >;
    }
  | {
      organization: Pick<OrganizationDTO, "id" | "name" | "number">;
    }
  | {
      qr: Pick<OrganizationQRDTO, "id" | "number" | "internalCode">;
    }
);

function download(blobOrElement: Blob | HTMLCanvasElement, filename: string) {
  const objectUrl =
    blobOrElement instanceof Blob
      ? URL.createObjectURL(blobOrElement)
      : blobOrElement.toDataURL("image/png");

  const link = document.createElement("a");
  link.style.display = "none";
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(objectUrl);
}

export function QrCodeDownloader(props: QrCodeDownloaderOptions) {
  const svgRef = useRef<HTMLDivElement>(null);
  const pngRef = useRef<HTMLDivElement>(null);

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");

  const toast = useToast();

  const { filename, link } = useMemo(() => {
    if ("participant" in props) {
      return {
        filename: getName(props.participant.user),
        link:
          `${process.env.REACT_APP_QR_BASE_URL}/p/` + props.participant.number,
      };
    } else if ("qr" in props) {
      return {
        filename: props.qr.internalCode,
        link: `${process.env.REACT_APP_QR_BASE_URL}/q/` + props.qr.number,
      };
    } else {
      return {
        filename: props.organization.name,
        link:
          `${process.env.REACT_APP_QR_BASE_URL}/o/` + props.organization.number,
      };
    }
  }, [props]);

  const downloadSvg = () => {
    if (!svgRef.current) {
      return;
    }

    const blob = new Blob([svgRef.current.innerHTML], {
      type: "image/svg+xml",
    });
    download(blob, filename);
  };

  const downloadPng = () => {
    if (!pngRef.current) {
      return;
    }

    const canvas = pngRef.current.querySelector("canvas");
    if (canvas) {
      download(canvas, filename);
    }
  };

  return (
    <Flex direction="column" gap={4} w="100%">
      <Center>
        <Box p="15px" bg="white">
          <Box ref={svgRef}>
            <QRCodeSVG
              size={props.size ?? 128}
              level="H"
              value={link}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            />
          </Box>
        </Box>
      </Center>
      <Flex direction="row" gap={4} justify="center">
        <Button
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          leftIcon={<MdDownload />}
          onClick={downloadSvg}
        >
          SVG
        </Button>
        <Button
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          leftIcon={<MdDownload />}
          onClick={downloadPng}
        >
          PNG
        </Button>
      </Flex>
      <Flex
        w="100%"
        h="40px"
        px="18px"
        align="center"
        borderRadius="16px"
        cursor="pointer"
        onClick={function () {
          navigator.clipboard.writeText(link);
          toast({
            title: `Link copied!`,
            position: "top",
            status: "success",
            isClosable: true,
          });
        }}
        bg={boxBg}
        me="6px"
      >
        <Text
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          noOfLines={1}
          w={{ base: "60%", md: "85%", "2xl": "68%", "3xl": "80%" }}
        >
          {link}
        </Text>
        <Text
          minW="80px"
          align="right"
          ms="auto"
          color={iconColor}
          fontSize="sm"
          fontWeight="500"
        >
          Copy link
        </Text>
      </Flex>
      <Box display="none" ref={pngRef}>
        <QRCodeCanvas size={512} level="H" value={link} />
      </Box>
    </Flex>
  );
}
