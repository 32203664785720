/*eslint-disable*/

import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { useSession } from "utils/useSession";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  const location = useLocation();
  const isAdmin = location.pathname.includes("/admin");
  const session = useSession();

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb="20px"
      >
        {" "}
        &copy; {new Date().getFullYear()}
        <Link
          mx="3px"
          color={textColor}
          href="https://gotips.co"
          target="_blank"
          fontWeight="700"
        >
          GoTips
        </Link>
      </Text>
      <List
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={{ base: "column", md: "row" }}
      >
        <ListItem me="20px">
          {isAdmin ? (
            <Link
              as={RouterLink}
              fontWeight="500"
              color={textColor}
              to="/waiter"
            >
              <b>Waiter's area</b>
            </Link>
          ) : session?.whitelisted ? (
            <Link
              as={RouterLink}
              fontWeight="500"
              color={textColor}
              to="/admin"
            >
              <b>Restaurant's area</b>
            </Link>
          ) : (
            <></>
          )}
        </ListItem>
        <ListItem me="20px">
          <Link
            target="_blank"
            fontWeight="500"
            color={textColor}
            href="https://gotips.co/privacy-policy"
          >
            Privacy Policy
          </Link>
        </ListItem>
        <ListItem me="20px">
          <Link
            target="_blank"
            fontWeight="500"
            color={textColor}
            href="https://gotips.co/client-terms"
          >
            Terms of Use
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
