import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { OrganizationDTO, OrganizationQRDTO } from "api";
import React from "react";
import { QrCodeDownloader } from "../participants/components/QrCodeDownloader";

export function QRGetQRModal({
  organization,
  qr,
  disclosure,
}: {
  organization: Pick<OrganizationDTO, "id">;
  qr: OrganizationQRDTO;
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLSelectElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>QR code for {qr.internalCode}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <QrCodeDownloader qr={qr} />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} variant="no-hover" bg={bgButton}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
