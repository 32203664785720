import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { ParticipantDTO, OrganizationDTO } from "api";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { getName } from "utils/name";
import { QrCodeDownloader } from "./QrCodeDownloader";

export function ParticipantQrModal({
  organization,
  participant,
  disclosure,
  myParticipant,
}: {
  organization: Pick<OrganizationDTO, "id">;
  participant: Pick<
    ParticipantDTO,
    "id" | "role" | "user" | "publicTags" | "privateTags" | "number"
  >;
  myParticipant?: Pick<
    ParticipantDTO,
    "id" | "role" | "user" | "publicTags" | "privateTags"
  >;
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = React.useRef<HTMLSelectElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>QR code for {getName(participant.user)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <QrCodeDownloader participant={participant} />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} variant="no-hover" bg={bgButton}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
