/* eslint-disable */

import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import { MdAddCircle } from "react-icons/md";
import * as React from "react";
// Assets
import {
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { OrganizationDTO, OrganizationQRDTO } from "api";
import { QRActionMenu } from "./QRActionMenu";
import IconBox from "components/icons/IconBox";
import { MdQrCode } from "react-icons/md";
import { QRCreateModal } from "./QREditModal";

function CreateButton(props: { organization: Pick<OrganizationDTO, "id"> }) {
  const disclosure = useDisclosure();
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <>
      <Button
        alignItems="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        // w="37px"
        h="37px"
        lineHeight="100%"
        borderRadius="10px"
        rightIcon={
          <Icon as={MdAddCircle} color={iconColor} w="24px" h="24px" />
        }
        onClick={disclosure.onOpen}
      >
        Create
      </Button>

      <QRCreateModal
        disclosure={disclosure}
        organization={props.organization}
      />
    </>
  );
}
export default function QRTable(props: {
  tableData: OrganizationQRDTO[];
  organization: { id: string };
}) {
  const { tableData, organization } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const iconBoxBg = useColorModeValue("secondaryGray.300", "navy.700");

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");
  const columnHelper = createColumnHelper<OrganizationQRDTO>();
  const [data, setData] = React.useState(() => [...tableData]);

  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const actionColumn = React.useMemo(
    () =>
      columnHelper.display({
        size: 100,
        enableResizing: false,
        id: "actions",
        cell: (info) => (
          <Flex justifyContent="flex-end">
            <QRActionMenu organization={organization} qr={info.row.original} />
          </Flex>
        ),
      }),
    [data]
  );

  const columns = [
    columnHelper.display({
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          INTERNAL CODE
        </Text>
      ),
      cell: (ctx) => (
        <Flex align="center">
          <IconBox
            h="42px"
            w="42px"
            minW="42px"
            minH="42px"
            bg={iconBoxBg}
            me="10px"
            icon={<Icon as={MdQrCode} color={textColor} />}
          />
          <Flex direction="column" gap={0}>
            <Text color={textColor} fontSize="md" fontWeight="500">
              {ctx.row.original.internalCode}
            </Text>
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
              {ctx.row.original.displayName}
            </Text>
          </Flex>
        </Flex>
      ),
    }),
    actionColumn,
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    manualPagination: true,
  });

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <div></div>
        <CreateButton organization={organization} />
      </Flex>
      <Table
        variant="simple"
        color="gray.500"
        mb="24px"
        style={{ tableLayout: "fixed" }}
      >
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    pe="10px"
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width:
                        header.getSize() !== 150 ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr px="20px" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor={borderColor}
                      style={{
                        width:
                          cell.column.getSize() !== 150
                            ? cell.column.getSize()
                            : undefined,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
        {table.getPageCount() === 0 && <Text>No QR-codes yet</Text>}
        <div></div>
      </Flex>
    </Flex>
  );
}
