// Chakra imports
import {
  Badge,
  Box,
  Flex,
  Icon,
  Link,
  SpaceProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { CreateFeedbackDTORate, TransactionResponseDTODataItem } from "api";

import { MdPeopleAlt } from "react-icons/md";

// Custom components
import IconBox from "components/icons/IconBox";
import { useTransactionInfo } from "./useTransactionInfo";

export function Rate({ rate }: { rate: CreateFeedbackDTORate }) {
  let colorScheme = "brand";
  let rateString = "Good";

  switch (rate) {
    case "BAD":
      colorScheme = "red";
      rateString = "Bad 👎";
      break;
    case "GOOD":
      colorScheme = "brand";
      rateString = "Good 👍";
      break;
    case "EXCELLENT":
      colorScheme = "green";
      rateString = "Excellent 🔥";
      break;
  }

  return (
    <Badge
      colorScheme={colorScheme}
      variant="solid"
      // color={"Completed" === "Completed" ? "green.500" : "red.500"}
      borderRadius="4px"
      padding="4px"
      paddingLeft="4px"
      paddingRight="4px"
    >
      {rateString}
    </Badge>
  );
}

export function Feedback(props: {
  content?: string;
  rate: CreateFeedbackDTORate;
}) {
  const { content, rate, ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const blockBg = useColorModeValue("secondaryGray.300", "navy.700");

  return (
    <Box
      borderRadius={"0px 20px 20px 20px"}
      bg={blockBg}
      justifyContent="center"
      alignItems="center"
      px="16px"
      py="8px"
      maxW={{ base: "100%", lg: "65%", xl: "52%" }}
      ml={{ base: "0px", md: "62px" }}
      {...rest}
    >
      <Rate rate={rate} />
      <Text
        color={content ? textColor : "secondaryGray.600"}
        fontSize={{ base: "md", "2xl": "md" }}
        me="6px"
        mb="8px"
        fontWeight="400"
      >
        {content || "(No written feedback)"}
      </Text>
    </Box>
  );
}

export function PooledAmount({
  pooled,
  amount,
  onClick,
  ...rest
}: SpaceProps & {
  pooled: boolean;
  amount: string;
  onClick: () => void;
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");

  if (!pooled) {
    return (
      <Text
        ms="auto"
        color={textColor}
        fontSize="md"
        me="6px"
        fontWeight="700"
        minW="fit-content"
        {...rest}
      >
        {amount}
      </Text>
    );
  }

  return (
    <Link
      as={Flex}
      direction="row"
      align="center"
      gap={1}
      color={brandColor}
      onClick={onClick}
      ms="auto"
    >
      <MdPeopleAlt size="15px" style={{ marginTop: "1px" }} />
      <Text
        ms="auto"
        color={brandColor}
        fontSize="md"
        me="6px"
        fontWeight="700"
        minW="fit-content"
        {...rest}
      >
        {amount}
      </Text>
    </Link>
  );
}

export function WalletTransaction(props: {
  transaction: TransactionResponseDTODataItem;
  onPoolClick: (poolTransaction: TransactionResponseDTODataItem) => void;
  onPayoutHelpClick: (poolTransaction: TransactionResponseDTODataItem) => void;
  [x: string]: any;
}) {
  const { transaction, onPoolClick, onPayoutHelpClick, ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconBoxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "white");
  const { withPrecision, getDetails, dateFormatter } =
    useTransactionInfo(transaction);
  const { name, icon, amount } = getDetails(transaction);
  const organizationName = transaction.metadata?.organization?.name;

  return (
    <Flex direction="column" gap={2} w="100%">
      <Flex
        justifyContent="start"
        alignItems="center"
        direction="row"
        w="100%"
        {...rest}
      >
        <IconBox
          h="42px"
          w="42px"
          minW="42px"
          minH="42px"
          bg={iconBoxBg}
          me="20px"
          icon={<Icon as={icon} />}
        />
        <Flex direction="column" align="start" w="100%" me="6px">
          <Text color={textColor} fontSize="md" me="6px" fontWeight="700">
            {name}
          </Text>
          <Flex gap="1px">
            {organizationName && (
              <Text
                color="secondaryGray.600"
                fontSize="sm"
                fontWeight="500"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                flexShrink={1}
              >
                {organizationName}
                {" •"}
              </Text>
            )}
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              flexShrink={0}
            >
              {dateFormatter.format(new Date(transaction.date as string))}
            </Text>
          </Flex>
          {transaction.category === "payout" && (
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              flexShrink={1}
            >
              <Link onClick={() => onPayoutHelpClick(transaction)}>
                When will I receive funds?
              </Link>
            </Text>
          )}
        </Flex>
        <PooledAmount
          pooled={
            transaction.category !== "holdtomain" &&
            Boolean(transaction.metadata?.pooled) &&
            transaction.metadata?.pooled !==
              (transaction.holdAmount || transaction.amount)
          }
          amount={withPrecision(amount)}
          onClick={() => onPoolClick(transaction)}
        />
      </Flex>
      {transaction.category !== "holdtomain" &&
        transaction.metadata?.feedback && (
          <Feedback
            rate={transaction.metadata.feedback.rate}
            content={transaction.metadata.feedback.comment || undefined}
          />
        )}
    </Flex>
  );
}
