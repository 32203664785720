// Chakra imports
import {
  Badge,
  Button,
  Flex,
  FormControl,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import SetUp from "components/actions/SetUp";
import { MdEdit } from "react-icons/md";
import { useUserContext } from "utils/userContext";
import { UpdateBeneficiaryDTO, UserDTOBeneficiary } from "api";
import { EditBeneficiaryModal } from "./CreateEditModal";
import { friendlyFormatIBAN } from "ibantools";

function EditButton() {
  // const disclosure = useDisclosure();
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <>
      <Button
        alignItems="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        // w="37px"
        h="37px"
        lineHeight="100%"
        borderRadius="10px"
        rightIcon={<Icon as={MdEdit} color={iconColor} w="24px" h="24px" />}
        // onClick={disclosure.onOpen}
      >
        Edit
      </Button>

      {/* <CreateOrganizationModal disclosure={disclosure} /> */}
    </>
  );
}

function Entry(props: { value?: string; name: string; [x: string]: any }) {
  const { value, name, action, actionName, ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  return (
    <Flex
      justifyContent="space-between"
      flexDirection="row"
      alignItems="center"
      w="100%"
      py="16px"
      borderBottom="1px solid"
      borderColor={borderColor}
      {...rest}
    >
      <Text color={textColor} fontSize="md" me="6px" fontWeight="500">
        {name}
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="500">
        {value ?? "No information"}
      </Text>
    </Flex>
  );
}

function getAddress(b?: UserDTOBeneficiary | null) {
  if (!b) {
    return undefined;
  }

  const components = [
    b.streetLine1,
    b.streetLine2,
    b.postCode,
    b.city,
    b.region,
    b.country,
  ];

  return components.filter((c) => !!c?.length).join(", ");
}

function getName(b?: UserDTOBeneficiary | null) {
  if (!b) {
    return undefined;
  }

  const components = [b.firstName, b.lastName].filter((c) => !!c.length);

  return components.length ? components.join(" ") : undefined;
}

export function Beneficiary(props: { [x: string]: any }) {
  const { ...rest } = props;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const editDisclosure = useDisclosure();

  const user = useUserContext();
  const beneficiary = user?.beneficiary;

  // Chakra Color Mode
  return (
    <>
      <FormControl>
        <Card px="30px" py="20px" mb="20px" {...rest}>
          <Flex justify="space-between" align="start">
            <Flex direction="column" align="start">
              <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                Bank account details
              </Text>
              {beneficiary?.status === "DECLINED" && beneficiary.reason && (
                <Text fontSize="md" color="red">
                  Reason: {beneficiary.reason}
                </Text>
              )}
            </Flex>
            {beneficiary?.status === "PENDING" && (
              <Badge
                colorScheme="yellow"
                color="yellow.500"
                fontWeight="bold"
                fontSize="sm"
              >
                WAITING DOCUMENT
              </Badge>
            )}
            {beneficiary?.status === "APPROVED" && (
              <Badge
                colorScheme="green"
                color="green.500"
                fontWeight="bold"
                fontSize="sm"
              >
                CONFIRMED
              </Badge>
            )}
            {beneficiary?.status === "DECLINED" && (
              <Badge
                colorScheme="red"
                color="red.500"
                fontWeight="bold"
                fontSize="sm"
              >
                DECLINED
              </Badge>
            )}
            {beneficiary?.status === "VERIFICATION" && (
              <Badge
                colorScheme="blue"
                color="blue.500"
                fontWeight="bold"
                fontSize="sm"
              >
                UNDER REVIEW
              </Badge>
            )}
          </Flex>
          <SimpleGrid columns={1} spacing="0px">
            <Entry name="IBAN" value={friendlyFormatIBAN(beneficiary?.iban) ?? undefined} />
            <Entry name="Beneficiary" value={getName(beneficiary)} />
            <Entry name="Address" value={getAddress(beneficiary)} />
          </SimpleGrid>
          <Button
            borderRadius="16px"
            minW="183px"
            h="44px"
            ms="auto"
            mt="33px"
            variant="brand"
            color="white"
            fontSize="sm"
            fontWeight="500"
            _hover={{ bg: "brand.600" }}
            _active={{ bg: "brand.500" }}
            _focus={{ bg: "brand.500" }}
            onClick={editDisclosure.onOpen}
          >
            Update information
          </Button>
        </Card>
      </FormControl>
      <EditBeneficiaryModal disclosure={editDisclosure} />
    </>
  );
}
