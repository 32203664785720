import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgComponent = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width="234px"
    height="64px"
    viewBox="0 0 234 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <defs>
      <path id="a" d="M0 0h234v64H0z" />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <g clipPath="url(#b)">
      <path d="M95.9 19.7h11.7v33.5h10.6V19.7h11.6V9.8H95.9v9.9zM19.3 38.8h16.4c-2.6 6.4-8.4 9-13.9 9-8.7 0-15.4-6.9-15.4-16.2s6.7-16.1 15.4-16.1c5.6 0 12.5 2.6 14.3 9.7h6.8C40.5 13.5 30.3 9.1 21.8 9.1 9.5 9.1 0 18.5 0 31.6 0 44.6 9.4 54 21.8 54c8.1 0 17.1-5.5 20.2-13.9v.1c.6-1.5.9-4 1.2-7.7H19.3v6.3zM48.7 33.8c1.7-1.1 3.8-1.2 5.6-.4-.1-.6-.1-1.2-.1-1.8 0-4.5 1.8-8.5 4.7-11.3 2.9-3 6.9-4.8 11.2-4.8 4.3 0 8.4 1.8 11.3 4.8 2.9 2.9 4.7 6.8 4.7 11.3 0 .5 0 1-.1 1.5 1.6-.4 3.3-.2 4.7.7.6.4 1 .8 1.5 1.3.2-1.2.3-2.4.3-3.6 0-6.2-2.5-11.8-6.5-15.9C81.9 11.5 76.4 9 70.2 9c-6.2 0-11.8 2.5-15.9 6.6-4 4.1-6.5 9.7-6.5 15.9 0 1 .1 1.9.2 2.8.2-.2.5-.4.7-.5zM134.3 19.5h7.2v24.2h-7.2v9.5h25v-9.5h-7.2V19.5h7.2V9.8h-25v9.7zM218.1 26.5c-1.4-.1-6.6-.5-6.6-3.7 0-2.4 2.3-3.8 5.6-3.8s5.6 1.5 5.6 4.5h10.7c0-10-8.4-14.3-16.1-14.3-9.2 0-17 5.7-16.6 15.2.8 10 9.1 11.4 16.1 12.1 1.6.2 6.6.4 6.6 3.5 0 2.5-2.8 3.5-6.3 3.5-2.4 0-6-.8-6-5.1h-10.7c0 11 9.6 15.4 16.7 15.4 8.2 0 17-4.3 17-14.3.1-9-7.1-11.8-16-13z" />
      <path d="M181 9.8h-16.6v43.4H175V40.3h6c20.2 0 20.3-30.5 0-30.5zm0 20.5h-6V20.1h6c6.5 0 6.5 10.2 0 10.2z" />
      <path
        fill="#4A22FE"
        d="M87.6 57.8c-.4 0-.8-.1-1.2-.3l-16.6-7.9-16.6 7.9c-.9.4-1.9.3-2.6-.2-.8-.5-1.3-1.4-1.3-2.3V38.3c0-.9.5-1.8 1.3-2.3.8-.5 1.8-.6 2.6-.2l16.6 7.9 16.6-7.9c.9-.4 1.9-.3 2.6.2.8.5 1.3 1.4 1.3 2.3v16.8c0 .9-.5 1.8-1.3 2.3-.4.3-.9.4-1.4.4zM76.2 46.7l8.6 4.1v-8.1l-8.6 4zm-21.5-4.1v8.1l8.6-4.1-8.6-4z"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export default ForwardRef;
