// Chakra imports
import { Flex, Grid, Select, Switch, Text } from "@chakra-ui/react";
// Custom components
import { Wallet } from "./components/Wallet";
import {
  useOrganizationControllerList,
  orgWalletControllerGetTransactions,
  useOrgWalletControllerList,
  getOrgWalletControllerGetTransactionsCSVQueryKey,
  WalletDTO,
} from "api";
import { TrasactionList } from "./components/TransactionList";
import { useInfiniteQuery } from "@tanstack/react-query";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import { AdminStatus, DashboardWarning } from "./DashboardWarning";
import { userHasName } from "utils/name";
import { useUserContext } from "utils/userContext";
import { useSearchParams } from "utils/useSearchParams";
import { useHistory } from "react-router-dom";

const TRANSACTIONS_LIMIT = 15;

const downloadURI = (uri: string, name: string) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function DashboardGrid({
  organizationId,
  onWalletsLoaded,
  showOnlyNonEmpty,
}: {
  organizationId: string;
  showOnlyNonEmpty: boolean;
  onWalletsLoaded: (wallets: WalletDTO[]) => void;
}) {
  const { data: walletData } = useOrgWalletControllerList(organizationId);
  const [selectedRange, setSelectedRange] = useState<
    [Date, Date] | null | undefined
  >();

  const {
    fetchNextPage,
    data: transactionsData,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["admin_transactions", selectedRange],
    ({ pageParam }: { pageParam?: string }) =>
      orgWalletControllerGetTransactions(organizationId, {
        limit: TRANSACTIONS_LIMIT,
        position: pageParam ?? "",
        ...(selectedRange
          ? {
              startTime: selectedRange[0].toISOString(),
              endTime: selectedRange[1].toISOString(),
            }
          : undefined),
      }),
    {
      getNextPageParam: (lastPage) => lastPage.data.next,
      getPreviousPageParam: (lastPage) => lastPage.data.previous,
    }
  );

  const [csvBaseUrl, params] = getOrgWalletControllerGetTransactionsCSVQueryKey(
    organizationId,
    selectedRange
      ? {
          startTime: selectedRange[0].toISOString(),
          endTime: selectedRange[1].toISOString(),
        }
      : undefined
  );

  const csvUrl = [
    csvBaseUrl,
    Object.entries(params ?? {})
      .map(([k, v]) => `${k}=${v}`)
      .join("&"),
  ].join("?");

  useEffect(() => {
    onWalletsLoaded(walletData?.data ?? []);
  }, [walletData?.data]);

  const filteredWallets =
    (showOnlyNonEmpty
      ? walletData?.data.filter((w) => w.balance + w.holdBalance > 0)
      : walletData?.data) ?? [];

  return (
    <Flex w="100%" direction="column" gap="20px" mb="20px">
      {!!filteredWallets.length && (
        <Grid
          gap="20px"
          gridTemplateColumns={{
            md: "repeat(2, 1fr)",
            "2xl": "repeat(3, 1fr)",
          }}
        >
          {filteredWallets.map((w) => (
            <Flex key={w.id}>
              <Wallet wallet={w} organizationId={organizationId} />
            </Flex>
          ))}
        </Grid>
      )}

      {transactionsData && transactionsData.pages.length > 0 && (
        <TrasactionList
          data={transactionsData.pages.flatMap((p) => p.data.data)}
          hasMore={hasNextPage ?? false}
          loadMore={fetchNextPage}
          isLoadingMore={isFetchingNextPage}
          selectedRange={selectedRange}
          onSelectedRangeChange={setSelectedRange}
          onCsvDownload={() => {
            downloadURI(
              `${process.env.REACT_APP_API_BASE_URL}${csvUrl}`,
              "transactions_report.csv"
            );
          }}
          mode="organization"
        />
      )}
    </Flex>
  );
}

export default function AdminDashboard() {
  const { data: orgListData } = useOrganizationControllerList();
  const searchParams = useSearchParams();
  const history = useHistory();
  const [orgId, setOrgId] = useState<string | undefined>(
    searchParams.get("id") || undefined
  );
  const [walletsCount, setWalletsCount] = useState(0);
  const [showOnlyNonEmpty, setShowOnlyNonEmpty] = useState(true);

  const onChangeOrg = (newOrgId: string) => {
    setOrgId(newOrgId);
    searchParams.set("id", newOrgId);
    history.push({
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    if (!orgId && orgListData?.data.length) {
      setOrgId(orgListData.data[0].id);
    }
  }, [orgId, orgListData]);

  const user = useUserContext();

  const longWalletList = walletsCount >= 10;

  return (
    <Flex direction="column" width="stretch">
      {user && !userHasName(user) ? (
        <DashboardWarning mb="20px" borderRadius="16px" />
      ) : orgListData && !orgListData.data?.length ? (
        <AdminStatus mb="20px" borderRadius="16px" />
      ) : (
        <></>
      )}
      <Card
        p="20px"
        mb="20px"
        alignItems="left"
        flexDirection="column"
        w="100%"
      >
        <Select
          value={orgId}
          placeholder={
            orgListData?.data?.length
              ? "Select restaurant"
              : "No restaurants available"
          }
          onChange={(e) => onChangeOrg(e.target.value)}
        >
          {orgListData?.data.map((o) => (
            <option key={o.id} value={o.id}>
              {o.name}
            </option>
          ))}
        </Select>
        {longWalletList && (
          <Flex direction="row" gap={1} align="center" mt="10px">
            <Switch
              size="md"
              mr="5px"
              isChecked={showOnlyNonEmpty}
              onChange={(e) => setShowOnlyNonEmpty(e.target.checked)}
            />
            <Text fontSize="md">Show only non-empty wallets</Text>
          </Flex>
        )}
      </Card>
      {orgId && (
        <DashboardGrid
          key={orgId}
          organizationId={orgId}
          showOnlyNonEmpty={longWalletList ? showOnlyNonEmpty : false}
          onWalletsLoaded={(list) => setWalletsCount(list.length)}
        />
      )}
    </Flex>
  );
}
