import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
  Text,
  Link,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  getOrganizationControllerListQueryKey,
  useOrganizationInviteControllerAcceptInvitation,
  useOrganizationInviteControllerFindOrganization,
} from "api";
import { InvitationExpiredAlert } from "components/invitation/InvitationExpiredAlert";
import { useEffect, useState } from "react";

function ErrorModalComponent() {
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Invitation has expired</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InvitationExpiredAlert />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} variant="no-hover" bg={bgButton}>
            Okay
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ModalComponent({ token }: { token: string }) {
  const { data: response } =
    useOrganizationInviteControllerFindOrganization(token);
  const { mutate, isSuccess, isLoading, isError } =
    useOrganizationInviteControllerAcceptInvitation();
  const toast = useToast();

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();

  const onJoin = () => {
    mutate({
      token,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(getOrganizationControllerListQueryKey());
      toast({
        title: `You've accepted an invitation!`,
        position: "top",
        status: "success",
        isClosable: true,
      });
      onClose();
    }

    if (isError) {
      onClose();
    }
  }, [isSuccess, isError]);

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Join restaurant's team</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            <b>{response?.data.name}</b> invites you to join you as a teammate.
            Would you like to join?
          </Text>
          <Text mt="5px">
            By clicking "Join" you accept{" "}
            <b>
              <Link href="https://gotips.co/client-terms/" target="_blank">
                Terms of Use
              </Link>
            </b>
            , and agree with tips sharing scheme in {response?.data.name}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={isLoading}
            onClick={onJoin}
            mr={3}
            variant="brand"
            me="14px"
          >
            Join
          </Button>
          <Button onClick={onClose} variant="no-hover" bg={bgButton}>
            Reject
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function InvitationModal() {
  const [token, setToken] = useState<string>();

  useEffect(() => {
    const newToken = localStorage.getItem("gotips-invitation-token");
    if (newToken) {
      localStorage.removeItem("gotips-invitation-token");

      setToken(newToken);
    }
  }, []);

  if (!token) {
    return <></>;
  }

  if (token === "__expired__") {
    return <ErrorModalComponent />;
  }

  return <ModalComponent token={token} />;
}
