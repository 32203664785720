import {
  UseDisclosureReturn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormErrorMessage,
  ModalFooter,
  Button,
  Flex,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  InputGroup,
  InputRightElement,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteList,
  AutoCompleteItem,
  AutoCompleteGroup,
  AutoCompleteGroupTitle,
  AutoCompleteRefMethods,
  AutoCompleteProps,
  AutoCompleteInputProps,
} from "@choc-ui/chakra-autocomplete";
import {
  PaymentRuletDTODataDistribution,
  WalletDTO,
  OrganizationDTO,
  ParticipantDTO,
  TagDTO,
  OrganizationQRDTO,
} from "api";
import { useFormik } from "formik";
import { ReactNode, useEffect, useRef, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { getName } from "utils/name";
import { RuleBox } from "./components/RuleBox";
import { getFromToRuleText, RuleFromTo } from "./components/RuleFromTo";

type Rule =
  | PaymentRuletDTODataDistribution["byOrganization"][number]
  | PaymentRuletDTODataDistribution["byParticipant"][number]
  | PaymentRuletDTODataDistribution["byTag"][number];

type RuleInput<K extends Rule["from"]["type"]> = Extract<
  Rule,
  { from: { type: K } }
>;

type FormikValues<K extends Rule["from"]["type"]> = {
  fromType: K;
  fromValue?: string;
  percent: string;
  toType?: Rule["to"]["type"];
  toValue?: string;
};

export const getValue = (input?: Rule["from"] | Rule["to"]) => {
  switch (input?.type) {
    case "organization":
      return input.qrId ?? "";
    case "participant":
      return input.id;
    case "tag":
      return input.tag;
    case "wallet":
      return input.id;
  }
};

export function CreateEditRuleModal<K extends Rule["from"]["type"]>({
  disclosure,
  from,
  rule,
  onChange,
  organization,
  qrs,
  wallets,
  participants,
  tags,
}: {
  from: K;
  disclosure: UseDisclosureReturn;
  rule?: RuleInput<K>;
  onChange: (rule: RuleInput<K>) => void;
  wallets: WalletDTO[];
  organization: Pick<OrganizationDTO, "id" | "name" | "avatarImage">;
  qrs: OrganizationQRDTO[];
  participants: ParticipantDTO[];
  tags: TagDTO;
}) {
  const { isOpen, onClose } = disclosure;
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");

  const toValue = (
    type: Rule["from"]["type"] | Rule["to"]["type"],
    value: string | undefined
  ) => ({
    type,
    ...(() => {
      switch (type) {
        case "organization":
          return { qrId: value };
        case "participant":
        case "wallet":
          return { id: value! };
        case "tag":
          return { tag: value! };
      }
    })(),
  });

  const handleClose = () => {
    setFromChanged(0);
    setToChanged(0);
    onClose();
  };

  const formik = useFormik<FormikValues<K>>({
    enableReinitialize: false,
    initialValues: {
      fromType: rule?.from.type ?? from,
      fromValue: getValue(rule?.from),
      percent: (rule?.percent ?? 0).toString(),
      toType: rule?.to.type,
      toValue: getValue(rule?.to),
    },
    onSubmit: (values, helpers) => {
      if (!values.fromType || !values.toType) {
        return;
      }

      onChange({
        from: toValue(values.fromType, values.fromValue),
        percent: parseInt(values.percent),
        to: toValue(values.toType, values.toValue),
      } as RuleInput<K>);

      formik.resetForm();
      handleClose();
    },
    validate: (values) => {
      if (!values.fromType) {
        return { name: "Source type should be selected" };
      }
      if (!values.fromValue && values.fromType !== "organization") {
        return { name: "Source value should be selected" };
      }
      if (!values.toType) {
        return { name: "Destination type should be selected" };
      }
      if (!values.toValue) {
        return { name: "Destination value should be selected" };
      }
    },
  });

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const formatThumbNumber = (v: number | string) => {
    const res = Number(v);

    if (Number.isNaN(res)) {
      return 0;
    }

    return Math.max(0, Math.min(res, 100));
  };

  const allTags = [...new Set([...tags.private, ...tags.public])];
  const [fromChanged, setFromChanged] = useState(0);
  const [toChanged, setToChanged] = useState(0);

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>{rule ? "Edit rule" : "Add rule"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.fromType && formik.touched.fromType
              )}
            >
              <Flex direction="column" mb="30px">
                <FormLabel
                  display="flex"
                  ms="10px"
                  htmlFor="fromType"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  From
                </FormLabel>
                {formik.values.fromType &&
                (formik.values.fromValue !== undefined ||
                  (formik.values.fromType === "organization" &&
                    qrs.length === 0)) ? (
                  <RuleBox
                    {...(formik.values.fromType !== "organization" ||
                    qrs.length > 0
                      ? {
                          onChange: () => {
                            setFromChanged(fromChanged + 1);
                            formik.setFieldValue("fromValue", undefined, true);
                          },
                        }
                      : undefined)}
                  >
                    <RuleFromTo
                      organization={organization}
                      wallets={wallets}
                      qrs={qrs}
                      tags={tags}
                      participants={participants}
                      fromTo={
                        toValue(
                          formik.values.fromType,
                          formik.values.fromValue
                        ) as any
                      }
                    />
                  </RuleBox>
                ) : (
                  <FromToAutocomplete
                    formik={formik}
                    typeProp="fromType"
                    valueProp="fromValue"
                    shouldFocus={fromChanged > 0}
                    autoCompleteInputProps={{
                      placeholder: "Select source",
                    }}
                  >
                    {formik.values.fromType === "organization" && (
                      <AutoCompleteGroup showDivider>
                        <AutoCompleteGroupTitle>
                          QR-codes
                        </AutoCompleteGroupTitle>
                        <AutoCompleteItem label="Main QR-code" value="" fixed>
                          <b>Main QR-code</b>
                        </AutoCompleteItem>
                        {qrs.map((qr) => (
                          <AutoCompleteItem
                            key={qr.id}
                            value={qr}
                            label={qr.internalCode}
                            getValue={(v) => v.id}
                          >
                            {[qr.internalCode, qr.displayName]
                              .filter((s) => !!s)
                              .join(" - ")}
                          </AutoCompleteItem>
                        ))}
                      </AutoCompleteGroup>
                    )}
                    {formik.values.fromType === "participant" && (
                      <AutoCompleteGroup showDivider>
                        <AutoCompleteGroupTitle>Waiters</AutoCompleteGroupTitle>
                        <AutoCompleteItem
                          label="Any waiter"
                          value="__any__"
                          fixed
                        >
                          <b>Any waiter</b>
                        </AutoCompleteItem>
                        {participants.map((p) => (
                          <AutoCompleteItem
                            label={getName(p.user)}
                            key={p.id}
                            value={p}
                            getValue={(v) => v.id}
                          >
                            {getName(p.user)}
                          </AutoCompleteItem>
                        ))}
                      </AutoCompleteGroup>
                    )}
                    {formik.values.fromType === "tag" && allTags.length && (
                      <AutoCompleteGroup showDivider>
                        <AutoCompleteGroupTitle>Tags</AutoCompleteGroupTitle>
                        {allTags.map((t) => (
                          <AutoCompleteItem label={t} key={t} value={t}>
                            {t}
                          </AutoCompleteItem>
                        ))}
                      </AutoCompleteGroup>
                    )}
                  </FromToAutocomplete>
                )}
              </Flex>
              <FormErrorMessage>{formik.errors.fromType}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={Boolean(
                formik.errors.percent && formik.touched.percent
              )}
            >
              <Flex direction="column" mb="30px">
                <FormLabel
                  display="flex"
                  ms="10px"
                  htmlFor="percent"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Percentage
                </FormLabel>
                <Flex>
                  <NumberInput
                    precision={0}
                    variant="main"
                    name="percent"
                    maxW="100px"
                    mr="2rem"
                    value={formik.values.percent}
                    onChange={(v) => formik.setFieldValue("percent", v, true)}
                    min={0}
                    max={100}
                  >
                    <NumberInputField id="percent" name="percent" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Slider
                    flex="1"
                    focusThumbOnChange={false}
                    value={parseInt(formik.values.percent)}
                    onChange={(v) => formik.setFieldValue("percent", v, true)}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb
                      fontSize="sm"
                      boxSize="32px"
                      children={`${formatThumbNumber(formik.values.percent)}%`}
                    />
                  </Slider>
                </Flex>
              </Flex>
              <FormErrorMessage>{formik.errors.percent}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={Boolean(
                (formik.errors.toType && formik.touched.toType) ||
                  (formik.errors.toValue && formik.touched.toValue)
              )}
            >
              <Flex direction="column" mb="30px">
                <FormLabel
                  display="flex"
                  ms="10px"
                  htmlFor="toType"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  To
                </FormLabel>
                <input
                  type="hidden"
                  name="toType"
                  value={formik.values.toType || ""}
                  onChange={formik.handleChange}
                />
                {formik.values.toValue && formik.values.toType ? (
                  <RuleBox
                    onChange={() => {
                      setToChanged(toChanged + 1);
                      formik.setValues(
                        {
                          ...formik.values,
                          toType: undefined,
                          toValue: undefined,
                        },
                        true
                      );
                    }}
                  >
                    <RuleFromTo
                      organization={organization}
                      wallets={wallets}
                      qrs={qrs}
                      tags={tags}
                      participants={participants}
                      fromTo={
                        toValue(
                          formik.values.toType,
                          formik.values.toValue
                        ) as any
                      }
                    />
                  </RuleBox>
                ) : (
                  <FromToAutocomplete
                    formik={formik}
                    typeProp="toType"
                    valueProp="toValue"
                    shouldFocus={toChanged > 0}
                    autoCompleteInputProps={{
                      placeholder: "Select destination",
                    }}
                    autoCompleteProps={{
                      onChange: (value, item) => {
                        if (!Array.isArray(item) && item) {
                          if (item.originalValue) {
                            const ov = item.originalValue as
                              | ParticipantDTO
                              | WalletDTO
                              | string
                              | undefined;

                            if (ov) {
                              if (typeof ov === "string") {
                                formik.setFieldValue("toType", "tag", true);
                              } else if ("role" in ov) {
                                formik.setFieldValue(
                                  "toType",
                                  "participant",
                                  true
                                );
                              } else if ("currency" in ov) {
                                formik.setFieldValue("toType", "wallet", true);
                              }
                            }
                          }
                        }
                        formik.setFieldValue("toValue", value, true);
                      },
                    }}
                  >
                    {participants.length && (
                      <AutoCompleteGroup showDivider>
                        <AutoCompleteGroupTitle>Waiters</AutoCompleteGroupTitle>
                        {participants.map((p) => (
                          <AutoCompleteItem
                            key={p.id}
                            value={p}
                            label={getName(p.user)}
                            getValue={(v) => v.id}
                          >
                            {getName(p.user)}
                          </AutoCompleteItem>
                        ))}
                      </AutoCompleteGroup>
                    )}
                    {formik.values.fromType !== "tag" && allTags.length && (
                      <AutoCompleteGroup showDivider>
                        <AutoCompleteGroupTitle>Tags</AutoCompleteGroupTitle>
                        {allTags.map((t) => (
                          <AutoCompleteItem label={t} key={t} value={t}>
                            {t}
                          </AutoCompleteItem>
                        ))}
                      </AutoCompleteGroup>
                    )}
                    {wallets.length && (
                      <AutoCompleteGroup showDivider>
                        <AutoCompleteGroupTitle>Wallets</AutoCompleteGroupTitle>
                        {wallets.map((w) => (
                          <AutoCompleteItem
                            label={w.name ?? `${w.currency} wallet`}
                            key={w.id}
                            value={w}
                          >
                            {w.name ?? `${w.currency} wallet`}
                          </AutoCompleteItem>
                        ))}
                      </AutoCompleteGroup>
                    )}
                  </FromToAutocomplete>
                )}
              </Flex>
              <FormErrorMessage>{formik.errors.fromType}</FormErrorMessage>
            </FormControl>
            {(formik.values.fromValue ||
              formik.values.fromType === "organization") &&
              formik.values.toType &&
              formik.values.toValue && (
                <Text fontSize="sm" color="secondaryGray.600">
                  {formik.values.fromType === "tag" && (
                    <>
                      Whenever tips is getting distributed to tag{" "}
                      <b>{formik.values.fromValue}</b>,{" "}
                      <b>{formatThumbNumber(formik.values.percent)}%</b> of them
                      is going to{" "}
                      <b>
                        {getFromToRuleText({
                          organization,
                          wallets,
                          tags,
                          qrs,
                          participants,
                          fromTo: toValue(
                            formik.values.toType,
                            formik.values.toValue
                          ) as any,
                        })}
                      </b>
                    </>
                  )}
                  {formik.values.fromType !== "tag" && (
                    <>
                      Whenever{" "}
                      <b>
                        {getFromToRuleText({
                          organization,
                          wallets,
                          tags,
                          qrs,
                          participants,
                          fromTo: toValue(
                            formik.values.fromType,
                            formik.values.fromValue
                          ) as any,
                        })}
                      </b>{" "}
                      receives tips,{" "}
                      <b>{formatThumbNumber(formik.values.percent)}%</b> of them
                      is going to{" "}
                      <b>
                        {getFromToRuleText({
                          organization,
                          wallets,
                          tags,
                          qrs,
                          participants,
                          fromTo: toValue(
                            formik.values.toType,
                            formik.values.toValue
                          ) as any,
                        })}
                      </b>
                    </>
                  )}
                </Text>
              )}
          </ModalBody>

          <ModalFooter>
            <Button type="submit" mr={3} variant="brand" me="14px">
              {rule ? "Save" : "Add"}
            </Button>
            <Button onClick={handleClose} variant="no-hover" bg={bgButton}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

function FromToAutocomplete<K extends Rule["from"]["type"]>({
  autoCompleteProps,
  autoCompleteInputProps,
  valueProp,
  children,
  formik: formikProp,
  shouldFocus,
}: {
  formik: ReturnType<typeof useFormik<FormikValues<K>>>;
  autoCompleteProps?: Partial<AutoCompleteProps>;
  autoCompleteInputProps?: Partial<AutoCompleteInputProps>;
  typeProp: keyof FormikValues<K>;
  valueProp: keyof FormikValues<K>;
  shouldFocus?: boolean;
  children?: ReactNode[];
}) {
  const [formik, setFormik] = useState(formikProp);
  const autocompleteRef = useRef<AutoCompleteRefMethods>(null);
  const autocompleteInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFormik(formikProp);
  }, [formikProp]);

  useEffect(() => {
    if (shouldFocus) {
      autocompleteRef.current?.resetItems(true);
    }
  }, [shouldFocus]);

  return (
    <AutoComplete
      ref={autocompleteRef}
      openOnFocus
      closeOnSelect
      closeOnBlur
      suggestWhenEmpty
      value={formik.values[valueProp]}
      onChange={(value, item) => {
        formik.setFieldValue(valueProp, value, true);
      }}
      {...autoCompleteProps}
    >
      {({ isOpen }) => (
        <>
          <InputGroup>
            <AutoCompleteInput
              name={valueProp}
              ref={autocompleteInputRef}
              variant="main"
              borderRadius="16px"
              placeholder="Select"
              _placeholder={{
                color: "secondaryGray.600",
                fontWeight: "400",
              }}
              minH="40px"
              fontWeight="500"
              fontSize="sm"
              onBlur={formik.handleBlur}
              {...autoCompleteInputProps}
            />
            <InputRightElement
              children={<Icon as={MdChevronRight} />}
              transform={isOpen ? "unset" : "rotate(90deg)"}
              onClick={() => autocompleteInputRef.current?.focus()}
            />
          </InputGroup>
          <AutoCompleteList>{children}</AutoCompleteList>
        </>
      )}
    </AutoComplete>
  );
}
