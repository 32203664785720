import {
  Flex,
  Button,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import { MdAccountBalanceWallet, MdAddCircle } from "react-icons/md";
import { OrganizationDTO, useOrgWalletControllerList } from "api";
import { CreateOrgWalletModal } from "./CreateEditModal";
import { OrgWalletActionMenu } from "./OrgWalletActionMenu";
import { getCurrency } from "utils/currency";

export function OrgRulesWallets({
  organization,
}: {
  organization: Pick<OrganizationDTO, "id" | "name">;
}) {
  // Chakra Color Mode
  const titleTextColor = useColorModeValue("secondaryGray.900", "white");

  const brandColor = useColorModeValue("brand.500", "white");
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const walletBg = useColorModeValue("white", "whiteAlpha.100");

  const textColor = useColorModeValue("navy.700", "white");

  const { data: walletListData } = useOrgWalletControllerList(organization.id);

  const data = walletListData?.data ?? [];

  return (
    <Card px="26px" py="30px" w="100%">
      <Text color={titleTextColor} fontSize="2xl" fontWeight="700" mb="10px">
        Restaurant's wallets
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="10px">
        If you want to collect tips in one place and then distribute it across
        your teammates, then you might want to create any number of wallets for
        that
      </Text>

      <Flex direction="column" w="100%" gap={4}>
        <Flex direction="row" wrap="wrap" gap={4}>
          {data.map((w) => (
            <Card
              flexDirection="row"
              gap={4}
              key={w.id + w.name + w.currency}
              w="unset"
              bgColor={walletBg}
            >
              <IconBox
                mx="auto"
                h="40px"
                w="40px"
                icon={
                  <Icon
                    as={MdAccountBalanceWallet}
                    color={brandColor}
                    h="18px"
                    w="18px"
                  />
                }
                bg={box}
              />
              <Flex direction="column" align="flex-start">
                <Text color={textColor} fontSize="md" fontWeight="500">
                  {w.name ?? "Wallet"}
                </Text>
                <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                  {getCurrency(w.currency).code}
                </Text>
              </Flex>
              <Flex ml="40px" justifyContent="flex-end">
                <OrgWalletActionMenu
                  organizationId={organization.id}
                  wallet={w}
                />
              </Flex>
            </Card>
          ))}
        </Flex>
        <Flex w="100%" justify="space-between">
          <CreateButton organizationId={organization.id} />
        </Flex>
      </Flex>
    </Card>
  );
}

function CreateButton(props: { organizationId: string }) {
  const disclosure = useDisclosure();
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <>
      <Button
        alignItems="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        // w="37px"
        h="37px"
        lineHeight="100%"
        borderRadius="10px"
        rightIcon={
          <Icon as={MdAddCircle} color={iconColor} w="24px" h="24px" />
        }
        onClick={disclosure.onOpen}
      >
        Create Wallet
      </Button>

      <CreateOrgWalletModal
        disclosure={disclosure}
        organizationId={props.organizationId}
      />
    </>
  );
}
