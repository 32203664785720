import {
  UseDisclosureReturn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Avatar,
  Text,
  Spacer,
  NumberInput,
  NumberInputField,
  InputGroup,
  InputLeftAddon,
  useToast,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { useQueryClient } from "@tanstack/react-query";
import {
  WalletDTO,
  useOrgWalletControllerTransfer,
  getOrgWalletControllerListQueryKey,
  useOrganizationParticipantsControllerFindAll,
  ParticipantDTO,
} from "api";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import Dinero from "dinero.js";
import React, { useRef, useEffect, useState, useMemo } from "react";
import { getI18n } from "react-i18next";
import { getCurrency, restorePrecision } from "utils/currency";
import { getName } from "utils/name";
import { generateUid } from "utils/uid";

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 200,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <SearchBar
      {...props}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      h="44px"
      w="100%"
      // w={{ lg: "390px" }}
      borderRadius="16px"
    />
  );
}

export function TransferFundsModal({
  wallet,
  disclosure,
  organizationId,
}: {
  wallet: WalletDTO;
  organizationId: string;
  disclosure: UseDisclosureReturn;
}) {
  const { isOpen, onClose } = disclosure;
  const initialRef = useRef<HTMLInputElement>(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const modalBg = useColorModeValue("white", "blackAlpha.800");
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isError } = useOrgWalletControllerTransfer();
  const { data: participantListData } =
    useOrganizationParticipantsControllerFindAll(organizationId);

  const [idempotencyKey, setIdempotencyKey] = useState(generateUid());

  const [globalFilter, setGlobalFilter] = useState("");
  const [participantList, setParticipantList] = useState<
    Array<{
      participant: ParticipantDTO;
      name: string;
      value: Dinero.Dinero;
      valueString: string;
    }>
  >([]);

  const toast = useToast();

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const gfLowercased = globalFilter.toLowerCase();
  const filteredList = gfLowercased
    ? participantList.filter(
        (p) =>
          p.name.toLowerCase().includes(gfLowercased) ||
          p.participant.user.email?.includes(gfLowercased)
      )
    : participantList;

  const { code: currencyCode, precision } = getCurrency(wallet.currency);
  const maxValue = Dinero({ amount: wallet.balance, precision }).add(
    Dinero({ amount: wallet.holdBalance, precision })
  );
  const currentValue = participantList.reduce(
    (acc, p) => (p.value ? acc.add(p.value) : acc),
    Dinero({ amount: 0, precision })
  );

  const locale = getI18n().language;
  const { format: sumFormatter, currencySymbol } = useMemo(() => {
    const format = new Intl.NumberFormat(locale, {
      currency: currencyCode,
      style: "currency",
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
    const currencySymbol =
      format.formatToParts(0).find((p) => p.type === "currency")?.value ??
      currencyCode;

    return { format, currencySymbol };
  }, [currencyCode, precision, locale]);

  const isValid = currentValue.lessThanOrEqual(maxValue);

  const getDefaultList = () =>
    participantListData?.data.map((p) => ({
      participant: p,
      name: getName(p.user),
      value: Dinero({ amount: 0, precision }),
      valueString: "0",
    })) ?? [];

  useEffect(() => {
    if (participantListData && !participantList.length) {
      setParticipantList(getDefaultList());
    }
  }, [participantList.length, participantListData]);

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        getOrgWalletControllerListQueryKey(organizationId)
      );
      queryClient.invalidateQueries(["admin_transactions"]);
      setParticipantList(getDefaultList());
      setGlobalFilter("");
      setIdempotencyKey(generateUid());
      onClose();
    }

    if (isError) {
      toast({
        title: `Something went wrong. Please reload page and try again.`,
        position: "top",
        status: "error",
        isClosable: true,
      });
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      blockScrollOnMount={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Transfer funds</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={4}>
            <Text>
              You have {sumFormatter.format(maxValue.toUnit())} to distribute
            </Text>
            <DebouncedInput
              value={globalFilter ?? ""}
              onChange={(value) => setGlobalFilter(String(value))}
              placeholder="Search..."
            />
            <Flex direction="column" gap={2}>
              {filteredList.map(
                ({ participant: p, value, valueString, name }) => {
                  const subtitle = [
                    p.user.email,
                    ...p.publicTags,
                    ...p.privateTags,
                  ]
                    .filter((t) => !!t)
                    .join(", ");

                  return (
                    <Flex
                      key={p.id}
                      w="auto"
                      align="center"
                      justify="flex-start"
                      gap={2}
                    >
                      <Avatar
                        mx="auto"
                        h="40px"
                        w="40px"
                        m="0px"
                        name={name}
                        src={p.user.avatarImage ?? undefined}
                      />
                      <Flex direction="column" align="start">
                        <Text
                          color={textColorPrimary}
                          fontSize="md"
                          me="6px"
                          fontWeight="700"
                        >
                          {name}
                        </Text>
                        <Text
                          color="secondaryGray.600"
                          fontSize="sm"
                          fontWeight="500"
                        >
                          {subtitle}
                        </Text>
                      </Flex>
                      <Spacer />
                      <InputGroup size="sm" minW="25%" maxW="25%">
                        <InputLeftAddon
                          borderLeftRadius="10px"
                          children={currencySymbol}
                          px="5px"
                        />
                        <NumberInput
                          value={valueString}
                          isValidCharacter={(c) => /^[Ee0-9+\-.,]$/.test(c)}
                          parse={(s) => s.replaceAll(",", ".")}
                          onChange={(evString, ev) => {
                            const result = [...participantList];
                            const opIndex = result.findIndex(
                              (op) => op.participant.id === p.id
                            );
                            if (opIndex !== -1) {
                              result.splice(opIndex, 1, {
                                ...result[opIndex],
                                value: Dinero({
                                  amount: !isNaN(ev)
                                    ? Math.ceil(restorePrecision(ev, precision))
                                    : 0,
                                  precision,
                                }),
                                valueString: evString,
                              });

                              setParticipantList(result);
                            }
                          }}
                          clampValueOnBlur={true}
                          step={0.01}
                          precision={precision}
                          min={0}
                          max={maxValue.toUnit()}
                        >
                          <NumberInputField
                            color={textColorPrimary}
                            pl="5px"
                            pr="5px"
                            borderRightRadius="10px"
                          />
                        </NumberInput>
                      </InputGroup>
                    </Flex>
                  );
                }
              )}
            </Flex>
            {!isValid && (
              <Text color="red">
                Please check that your distribution is not more than maximum{" "}
                {sumFormatter.format(maxValue.toUnit())} is allowed
              </Text>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={!isValid}
            onClick={() => {
              mutate({
                organizationId,
                data: {
                  currency: wallet.currency,
                  fromWallet: wallet.id,
                  to: participantList
                    .map((p) => ({
                      userAccount: p.participant.user.id,
                      amount: p.value.getAmount(),
                    }))
                    .filter((p) => p.amount > 0),
                  ref: idempotencyKey,
                },
              });
            }}
            mr={3}
            variant="brand"
            me="14px"
          >
            Transfer
          </Button>
          <Button onClick={onClose} variant="no-hover" bg={bgButton}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
