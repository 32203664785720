import { NavLink, useLocation, Link as RouterLink } from "react-router-dom";

// Chakra imports
import {
  Flex,
  Icon,
  Link,
  Stack,
  useColorModeValue,
  Show,
} from "@chakra-ui/react";

// Custom components

// Assets
import Logo from "components/logo/logo";
import LogoIcon from "components/logo/logo.icon";
import Profile from "./NavbarLinksCommon";
import { MdHome, MdQrCode, MdRestaurant } from "react-icons/md";
import { IconType } from "react-icons";

function NavbarLink(props: { icon: IconType; linkText: string; to: string }) {
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );

  return (
    <Link
      as={NavLink}
      maxW="max-content"
      fontWeight="500"
      fontSize="sm"
      color={inactiveColor}
      _activeLink={{
        color: activeColor,
      }}
      to={props.to}
      exact
    >
      <Flex direction="column" align="center">
        <Icon as={props.icon} />
        {props.linkText}
      </Flex>
    </Link>
  );
}

export function CommonNavbar() {
  const logoColor = useColorModeValue("navy.700", "white");

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const menuBg = useColorModeValue("white", "navy.800");
  const mainText = "#fff";

  const location = useLocation();
  const isAdmin = location.pathname.includes("/admin");

  const brand = (
    <Link
      as={RouterLink}
      to={isAdmin ? "/admin" : "/waiter"}
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Stack
        direction="row"
        spacing="12px"
        alignItems="center"
        justify="center"
      >
        <Show below="md">
          <LogoIcon width="40" height="40" fill={logoColor} />
        </Show>

        <Show above="md">
          <Logo width="140" height="38" fill={logoColor} />
        </Show>
      </Stack>
    </Link>
  );

  return (
    <Flex
      mx="auto"
      width="100%"
      maxW="1044px"
      alignItems="center"
      px="16px"
    >
      <Flex
        w="100%"
        justifyContent="space-between"
        bg={menuBg}
        p="20px"
        borderBottomLeftRadius="20px"
        borderBottomRightRadius="20px"
        boxShadow={shadow}
      >
        {brand}
        <Flex w="auto" alignItems="center" flexDirection="row" gap={3}>
          {isAdmin ? (
            <>
              <NavbarLink linkText="Dashboard" to="/admin" icon={MdHome} />
              <NavbarLink
                linkText="Restaurants"
                to="/admin/organizations"
                icon={MdRestaurant}
              />
            </>
          ) : (
            <>
              <NavbarLink linkText="Wallets" to="/waiter" icon={MdHome} />
              <NavbarLink linkText="QR code" to="/waiter/qr" icon={MdQrCode} />
            </>
          )}
        </Flex>
        <Profile secondary={false} />
      </Flex>
    </Flex>
  );
}
