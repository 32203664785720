// Chakra imports
import { Flex } from "@chakra-ui/react";
import { useUserControllerMe } from "api";
import Footer from "components/footer/FooterAdmin";
// Layout components
import { CommonNavbar } from "components/navbar/NavbarCommon";
import { useEffect } from "react";
import { useUserContext } from "supertokens-auth-react";
import {
  getUserId,
  useSessionContext,
} from "supertokens-auth-react/recipe/session";
import { getName } from "utils/name";
import { UserContext } from "utils/userContext";

export function CommonLayout(props: {
  children: string | JSX.Element | JSX.Element[];
  [x: string]: any;
}) {
  const { children, ...rest } = props;

  const { data: userResponse } = useUserControllerMe();
  const session = useSessionContext();
  const chatwoot = (window as any)?.$chatwoot;

  useEffect(() => {
    if (userResponse?.data && chatwoot) {
      chatwoot.setUser(userResponse.data.id, {
        email: userResponse.data.email,
        name: getName(userResponse.data),
        avatar_url: userResponse.data.avatarImage,
        description: !session.loading ? session.userId : undefined,
      });
    }
  }, [chatwoot, userResponse?.data, session.loading]);

  useEffect(() => {
    if (userResponse?.data && window.umami) {
      window.umami.track((props) => ({
        ...props,
        name: "user",
        data: {
          userId: userResponse.data.id,
          authId: !session.loading ? session.userId : undefined,
          email: userResponse.data.email,
        },
      }));
    }
  }, [userResponse?.data, session.loading, window.umami]);

  return (
    <UserContext.Provider value={userResponse?.data}>
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
        mx={{ base: "10px", lg: "0px" }}
        minH="100vh"
      >
        <CommonNavbar />
        <Flex p="16px" h="max-content" mx="auto" width="100%" maxW="1044px">
          {children}
        </Flex>
        <Footer />
      </Flex>
    </UserContext.Provider>
  );
}
