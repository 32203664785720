export function uniqueTags(...tags: string[][]) {
  const set = new Set<string>();

  for (const array of tags) {
    for (const tag of array) {
      set.add(tag);
    }
  }

  return [...set];
}
