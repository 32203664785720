import { TransactionResponseDTODataItem } from "api";
import { useMemo } from "react";
import { getI18n } from "react-i18next";
import { IconType } from "react-icons";
import {
  MdEuro,
  MdCheckCircle,
  MdOutlineFeedback,
  MdSend,
} from "react-icons/md";

import { applyPrecision, getCurrency } from "utils/currency";

export type UseTransactionInfoProps = Pick<
  TransactionResponseDTODataItem,
  "currency"
>;

export function useTransactionInfo(data: UseTransactionInfoProps) {
  const locale = getI18n().language;
  const { code: currencyCode, precision } = getCurrency(data.currency);

  const sumFormatter = useMemo(() => {
    const format = new Intl.NumberFormat(locale, {
      currency: currencyCode,
      style: "currency",
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });

    return format;
  }, [currencyCode, precision, locale]);

  // Removed locale dependency since language does not contain locale
  const dateFormatter = useMemo(() => {
    return new Intl.DateTimeFormat(undefined, {
      dateStyle: "short",
      timeStyle: "short",
    });
  }, []);

  const withPrecision = (number: number) => {
    return sumFormatter.format(applyPrecision(number, precision));
  };

  const getDetails = (
    additionalData: Pick<
      TransactionResponseDTODataItem,
      | "amount"
      | "category"
      | "holdAmount"
      | "metadata"
      | "holdOverdraft"
      | "overdraft"
    >
  ) => {
    let icon: IconType = MdEuro;
    let name: string = additionalData.category;
    let amount: number = additionalData.amount;
    let holdAmount: number = additionalData.holdAmount;

    if (additionalData.holdOverdraft > 0) {
      holdAmount -= additionalData.holdOverdraft;
    }

    if (additionalData.overdraft > 0) {
      amount -= additionalData.overdraft;
    }

    let totalAmount = amount;

    switch (additionalData.category) {
      case "other":
        icon = MdEuro;
        name = `Operation. Balance change: ${withPrecision(
          amount
        )}; Hold change: ${withPrecision(holdAmount)}`;
        totalAmount = amount + holdAmount;
        break;
      case "holdtomain":
        icon = MdCheckCircle;
        name = `${withPrecision(amount)} is available for payout`;
        totalAmount = 0;
        break;
      case "holdtopup":
        icon = MdEuro;
        name = additionalData.metadata?.isTip
          ? holdAmount === 0
            ? `Incoming tips`
            : `Incoming tips. Funds are being processed`
          : `Top-up. Funds are being processed`;
        totalAmount = holdAmount;
        break;
      case "maintopup":
        icon = MdEuro;
        name = additionalData.metadata?.isTip
          ? `Incoming tips. Funds are processed and ready for payout`
          : `Top-up. Funds are processed and ready for payout`;
        totalAmount = amount;
        break;
      case "payout":
        icon = MdSend;
        name = `Payout requested`;
        totalAmount = amount + holdAmount;
        break;
      case "feedback":
        icon = MdOutlineFeedback;
        name = `Feedback without tips`;
        totalAmount = amount;
        break;
    }

    return {
      icon,
      name,
      amount: totalAmount,
    };
  };

  return {
    withPrecision,
    getDetails,
    dateFormatter,
    sumFormatter,
  };
}

export type UseTransactionInfoResult = ReturnType<typeof useTransactionInfo>;
