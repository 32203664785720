// Chakra imports
import {
  Icon,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  getOrganizationControllerFindOneQueryKey,
  getOrganizationControllerListQueryKey,
} from "api";
import { useState } from "react";
// Assets
import { MdOutlineFileUpload } from "react-icons/md";
import { useParams } from "react-router-dom";
import { ImageUploaderModal } from "./ImageUploaderModal";

export function UploadMenu(props: { [x: string]: any }) {
  const { ...rest } = props;
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  // const bgList = useColorModeValue("white", "whiteAlpha.100");
  // const bgShadow = useColorModeValue(
  //   "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
  //   "unset"
  // );
  // const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  // const bgHover = useColorModeValue(
  //   { bg: "secondaryGray.400" },
  //   { bg: "whiteAlpha.50" }
  // );
  // const bgFocus = useColorModeValue(
  //   { bg: "secondaryGray.300" },
  //   { bg: "whiteAlpha.100" }
  // );

  const bgList = useColorModeValue("white", "navy.800");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("white", "navy.800");
  const bgHover = useColorModeValue({ bg: "white.400" }, { bg: "navy.800" });
  const bgFocus = useColorModeValue({ bg: "white.300" }, { bg: "navy.700" });

  // Ellipsis modals
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openedUploader, openUploader] = useState<"avatar" | "background">();

  const invalidateOrganizations = () => {
    queryClient.invalidateQueries(
      getOrganizationControllerFindOneQueryKey(organizationId)
    );
    queryClient.invalidateQueries(getOrganizationControllerListQueryKey());
  };

  const endpoint = (type: 'background' | 'avatar') => `${
    process.env.REACT_APP_API_BASE_URL
  }/organization/${organizationId}/upload/${type}`;

  return (
    <>
      <ImageUploaderModal
        endpoint={endpoint('avatar')}
        uppyUniqueToken="avatar"
        meta={{ uploadType: "avatar", organizationId }}
        open={openedUploader === "avatar"}
        onRequestClose={() => openUploader(undefined)}
        onComplete={invalidateOrganizations}
      />
      <ImageUploaderModal
        endpoint={endpoint('background')}
        uppyUniqueToken="background"
        meta={{ uploadType: "background", organizationId }}
        open={openedUploader === "background"}
        onRequestClose={() => openUploader(undefined)}
        onComplete={invalidateOrganizations}
      />
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          as={Button}
          alignItems="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          // w="37px"
          h="37px"
          lineHeight="100%"
          onClick={onOpen}
          borderRadius="10px"
          leftIcon={
            <Icon
              as={MdOutlineFileUpload}
              color={iconColor}
              w="24px"
              h="24px"
            />
          }
          {...rest}
        >
          Upload images
        </MenuButton>
        <MenuList
          w="150px"
          minW="unset"
          maxW="150px !important"
          border="transparent"
          backdropFilter="blur(63px)"
          bg={bgList}
          boxShadow={bgShadow}
          borderRadius="20px"
          p="15px"
        >
          <MenuItem
            transition="0.2s linear"
            color={textColor}
            _hover={textHover}
            p="0px"
            borderRadius="8px"
            bg="transparent"
            _active={{
              bg: "transparent",
            }}
            _focus={{
              bg: "transparent",
            }}
            mb="10px"
            onClick={() => openUploader("avatar")}
          >
            <Text fontSize="sm" fontWeight="400">
              Avatar
            </Text>
          </MenuItem>
          <MenuItem
            transition="0.2s linear"
            p="0px"
            borderRadius="8px"
            color={textColor}
            _hover={textHover}
            bg="transparent"
            _active={{
              bg: "transparent",
            }}
            _focus={{
              bg: "transparent",
            }}
            mb="10px"
            onClick={() => openUploader("background")}
          >
            <Text fontSize="sm" fontWeight="400">
              Background
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
